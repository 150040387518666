import HomeTwo from "./HomeTwo";
import HomeThree from "./HomeThree";
import Markets from "./Markets";
import Logout from "./Logout";
import Deposit from "./Deposit";
import DepositStatement from "./DepositStatement";
import Wallet from "./Wallet";
import UserProfile from "./UserProfile";
import Login from "./Login";
import Register from "./Register";
import Contact from "./Contact";
import Faq from "./Faq";
import Trade from "./trade";
import Withdrawal from "./Withdrawal";
import WithdrawalStatement from "./WithdrawalStatement";
import Refferal from "./Refferal";
import Coinlisting from "./Coinlisting";
import CoinPricing from "./CoinPricing";
import PartnerAuthenticity from "./PartnerAuthenticity";
import ListingForm from "./ListingForm";
import Launchpad from "./Launchpad";
import Airdrop from "./Airdrop";
import Staking from "./Staking";
import About from "./about";
import Fee from "./fee";
import Privacy from "./Privacy";
import Forget from "./forget";
import TopGainers from "../components/marketlist/topgainers";
import TopLosers from "../components/marketlist/toplosers";
import NewlyListed from "../components/marketlist/newlylisted";
import Blockchain from "../components/marketlist/blockchain";
import Defi from "../components/marketlist/defi";
import Memecoin from "../components/marketlist/memecoin";
import LaunchPad2 from "../components/launchpad/Launchpad2";
import Softnote from "../components/softnote/softnote";
import Liquidity from "../components/liquidity/liquidity";




const routes = [
  { path: '/', component: <HomeTwo />},
  { path: '/home-v2', component: <HomeTwo />},
  { path: '/home-v3', component: <HomeThree />},
  { path: '/markets', component: <Markets />},
  { path: '/crypto-deposit', component: <Deposit />},
  { path: '/deposit-history', component: <DepositStatement />},
  { path: '/wallet', component: <Wallet />},
  { path: '/user-profile', component: <UserProfile />},
  { path: '/login', component: <Login />},
  { path: '/create-account/:id?', component: <Register />},
  { path: '/contact', component: <Contact />},
  { path: '/faq', component: <Faq />},
  { path: '/trade/:id', component: <Trade />},
  { path: '/crypto-withdrawal', component: <Withdrawal />},
  { path: '/withdrawal-statement', component: <WithdrawalStatement />},
  { path: '/logout', component: <Logout/>},
  { path: '/referral-program', component: <Refferal/>},
  { path: '/coin-listing', component: <Coinlisting/>},
  { path: '/listing-price', component: <CoinPricing/>},
  { path: '/offical-authenticity', component: <PartnerAuthenticity/>},
  { path: '/listing-form', component: <ListingForm/>},
  { path: '/launchpad', component: <Launchpad/>},
  { path: '/airdrop', component: <Airdrop/>},
  { path: '/staking', component: <Staking/>},
  { path: '/about-cetoex', component: <About/>},
  { path: '/fee-schedule', component: <Fee/>},
  { path: '/privacy', component: <Privacy/>},
  { path: '/forgot-password', component: <Forget/>},
  { path: '/top-gainers', component: <TopGainers/>},
  { path: '/top-losers', component: <TopLosers/>},
  { path: '/newly-listed', component: <NewlyListed/>},
  { path: '/blockchain', component: <Blockchain/>},
  { path: '/de-fi', component: <Defi/>},
  { path: '/memecoin', component: <Memecoin/>},
  { path: '/softnote-trade', component: <Softnote/>},
  { path: '/launchpad/:id', component: <LaunchPad2/>},
 


]

export default routes;