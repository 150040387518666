import React , {useState} from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import axios from 'axios';
import dataBlog from '../../assets/fake-data/data-blog';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss' ;
import {Link} from 'react-router-dom';
import Sale01 from '../sale/Sale01';
import './launchpad.scss';
import { useEffect } from 'react';
import { Bars } from  'react-loader-spinner'



function LaunchPad(props) {

    const [isOpen, setOpen] = useState(false);
    const [userList, setUserList] = useState({});

    const [dataCrytoTab] = useState([
        {
            id: 1,
            title: 'All IEO',
        },

        {
            id: 2,
            title: 'On Sale',
        },
        {
            id: 3,
            title: 'Success',
        },
       


        
        

    ])


    function coinStatus(status)
    {
       if(status === "Upcoming")
       {

        return(
            <>
            <button className="btn btn-warning"><span className="fa fa-circle" style={{fontSize:"13px"}}></span> Upcoming</button>
            </>
        )
       }
       else if(status === "On Sale")
       {
        return(
            <>
            <button  className="btn btn-success"><span className="fa fa-circle" style={{fontSize:"13px"}}></span> On Sale</button>
            </>
        )
       }
       else{
        return(
            <>
            <button  className="btn btn-danger"><span className="fa fa-circle" style={{fontSize:"13px"}}></span> Ended</button>
            </>
        )
       }
    }


    useEffect(()=>{
        fetchMyTable()
    },[]);


       async function fetchMyTable()
                {

                    var config = {
                        method: 'get',
                        url: 'https://test.cetoex.org/api/launchpadCoin',
                        headers: { 
                          'Content-Type': 'application/json'
                        },
                      };
                      
                      axios(config)
                      .then(function (response) {
                        setUserList(response.data.data);
                      })
                      .catch(function (error) {
                        console.log(error);
                      });
                      

                }


    return (
        <div>

           

            <section className="blog-grid">
                <div className="container">
                    <div className="row">
                    <div className="col-md-12">
                        <Tabs>
                            <TabList>
                                {
                                    dataCrytoTab.map(idx => (
                                        <Tab key={idx.id}>{idx.title}</Tab>
                                    ))
                                }

                            </TabList>
                            <div className="search">
                                <form action="#">
                                <div className="form-group">
                                    <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search IEO"
                                    />
                                    <button type="submit" className="search">
                                    <i className="fa-solid fa-magnifying-glass"></i>
                                    </button>
                                </div>
                                </form>
                            </div>

                            {
                                dataCrytoTab.map(idx =>(
                                    <TabPanel key={idx.id}>
                                      
                                        <div className="content-inner row">



                                                                            {
                                                    userList && userList.length>0 ?
                                                    userList.map((idx, i) =>
                                           <>

                                                        <div  className="col-md-4 p-md-4 p-xl-4 p-lg-4">
                                                        <div className="blog-box">
                                                        <div className="box-image">
                                                            <img src={idx.coin_banner} alt="cetoex" />
                                                           
                                                        </div>

                                                        <div className="box-content launchpad-content p-3">

                                                           <h6><img src="https://cetoex.com/coin-logo/paisapad.png" width="20px"/> {idx.coin_name} ({idx.coin_symbol})</h6>
                                                              {coinStatus(idx.coin_status)}
                                                            
                                                             <div className="row mt-2">
                                                                 <div className="col-6">
                                                                    {`${idx.coin_sold} ${idx.coin_symbol}`}

                                                                    </div>
                                                                
                                                                    <div className="col-6">
                                                                     <p style={{textAlign:"right"}}>0%</p>
                                                                    </div>
                                                                    <div className="col-12 mt-2">
                                                                    <ProgressBar now="0.5" striped animated/>
                                                                    </div>

                                                                    <div className="col-6 mt-3">
                                                                         <p>Token Price</p>
                                                                    </div>

                                                                    <div className="col-6 mt-3" style={{textAlign:"right",fontWeight:"700"}}>
                                                                         <p>{`$ ${idx.coin_price}`}</p>
                                                                    </div>

                                                                    <div className="col-6 mt-2">
                                                                         <p>Tokens Amount</p>
                                                                    </div>

                                                                    <div className="col-6 mt-2" style={{textAlign:"right",fontWeight:"700"}}>
                                                                         <p>{`${idx.total_allocation}`}</p>
                                                                    </div>
                                                                    
                                                                    <hr className="mt-3"/>
                                                                  <center>  <div className="col-12">
                                                                    <a href={`launchpad/${idx.coin_symbol}`} className="btn btn-primary btn-block" style={{color:"white",fontWeight:"bold"}}>View More</a>
                                                                    </div></center>
                                                             </div>
                

                                                           
                                                        
                                                           
                                                        </div>
                                                        </div>
                                                    </div>



                                           </>             

                                                
                                                ):(
                                                    <>
                                                 
                                                    <center>   <div style={{marginLeft:"45%",paddingTop:"50px",paddingBottom:"50px"}}>
                                                <Bars
                                                height = "60"
                                                width = "60"
                                                radius = "9"
                                                color = '#3375fe'
                                                ariaLabel = 'three-dots-loading'  
                                                marginLeft='200'   
                                                wrapperStyle
                                                wrapperClass
                                                />
                                                </div></center>
                                               
                                                    </>
                                                )
                                                }

  

                                        </div>
                                      
                                        
                                    </TabPanel>
                                ))
                            }

                            
                            

                        </Tabs> 

                    </div>
                    </div>
                </div>

                <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="i7EMACWuErA" onClose={() => setOpen(false)} />
            </section>

            <Sale01 />
            
        </div>
    );
}

export default LaunchPad;