import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Bars } from 'react-loader-spinner';

function Assets() {
  const [userList, setUserList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [asset, setAsset] = useState(true);

  const filteredData = userList.filter((item) =>
    item.coin_symbol.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.coin_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const priceColor = (price) => {
    if (price === 0) {
      return <td style={{ fontWeight: 600 }}>0.00%</td>;
    }

    const numberAsString = price.toString();
    if (numberAsString.substring(0, 1) === '-') {
      return (
        <td className="down" style={{ fontWeight: 600 }}>
          {price.toFixed(2)}%
        </td>
      );
    } else {
      return (
        <td className="up" style={{ fontWeight: 600 }}>
          {price.toFixed(2)}%
        </td>
      );
    }
  };

  const handleSearchChange = (event) => {
    const realVal = event.target.value;
    setSearchQuery(realVal.toUpperCase());
  };

  const scientificToDecimal = (num) => {
    const str = num.toString().toLowerCase();
    if (str.indexOf('e') === -1) {
      return str;
    }
    const parts = str.split('e');
    const coefficient = parseFloat(parts[0]);
    const exponent = parseInt(parts[1]);
    const decimalPart = coefficient.toFixed(4).replace('.', '');
    const exponentPart = Math.abs(exponent).toString();
    return (
      <>
        0.0<sub>{exponentPart-1}</sub>{decimalPart}
      </>
    );
  };

  useEffect(() => {
    const socket = new WebSocket(`wss://websocket.cetoex.com:8080/prices`);

    socket.onopen = () => {
      console.log('WebSocket connection established.');
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setAsset(false);
      setUserList(data);
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    socket.onclose = () => {
      console.log('WebSocket connection closed.');
    };

    return () => {
      socket.close();
    };
  }, []);

  const handleClick = (symbol) => {
    window.location.href = `/trade/${symbol}USDT`;
  };

  return (
    <>
      <div className="trade_pair" style={{ height: '522px' }}>
      <div style={{ textAlign: 'center' }}>
      <form className="form-search p-2">
        <div className="inputWithIcon inputIconBg">
          <input
            type="text"
            className="searchbox"
            placeholder="SEARCH TRADING PAIR"
            onChange={handleSearchChange}
            value={searchQuery}
          />
          <i className="fa fa-search fa-lg fa-fw" aria-hidden="true"></i>
        </div>
      </form>
    </div>
        <div className="flat-tabs">
          <Tabs defaultIndex={0} selectedTabClassName="tab-design">
            <TabList className="my-1" id="tab-basic">
              <Tab>USDT</Tab>
              <Tab>BTC</Tab>
              <Tab>ETH</Tab>
              <Tab>BNB</Tab>
            </TabList>

            <TabPanel>
              <div className="table-main">
                <table className="table-scroll">
                  <thead>
                    <tr>
                      <th style={{ paddingLeft: '10px' }}>&nbsp;&nbsp;Pairs</th>
                      <th>Price</th>
                      <th>Change</th>
                    </tr>
                  </thead>
                  <tbody className="body-half-screen">
                    {filteredData && filteredData.length > 0 ? (
                      filteredData.map((idx) => (
                        <tr
                          key={idx.coin_symbol}
                          onClick={() => handleClick(idx.coin_symbol)}
                        >
                          <td
                            style={{
                              width: '380px',
                              color: 'var(--tablemain)',
                            }}
                          >
                            &nbsp;
                            <img
                              src={idx.coin_logo}
                              alt="coin Logo"
                              style={{ width: '14px', borderRadius: '50%' }}
                            />
                            {` ${idx.coin_symbol}/USDT`}
                          </td>
                          <td style={{ color: 'var(--tablemain)' }}>
                            ${scientificToDecimal(idx.coin_price)}
                          </td>
                          {priceColor(idx.coin_pricechange)}
                        </tr>
                      ))
                    ) : filteredData && filteredData.length === 0 ? (
                      asset ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '152px',
                          }}
                        >
                          <Bars
                            height="40"
                            width="40"
                            radius="9"
                            color="#3375fe"
                            ariaLabel="three-dots-loading"
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingTop: '132px',
                          }}
                        >
                          <center>
                            <p
                              className="fa fa-bar-chart"
                              style={{
                                fontSize: '35px',
                                color: '#3375fe',
                                paddingBottom: '10px',
                              }}
                            ></p>
                            <h6 className="no-datatext">Asset Not Found</h6>
                          </center>
                        </div>
                      )
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          padding: '180px',
                        }}
                      >
                        <Bars
                          height="40"
                          width="40"
                          radius="9"
                          color="#3375fe"
                          ariaLabel="three-dots-loading"
                        />
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </TabPanel>

            <TabPanel>
              <div
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingTop: '162px',
                }}
              >
                <center>
                  <p
                    className="fa fa-search"
                    style={{
                      fontSize: '35px',
                      color: '#3375fe',
                      paddingBottom: '10px',
                    }}
                  ></p>
                  <h6 className="no-datatext">BTC Pair Unavailable</h6>
                </center>
              </div>
            </TabPanel>

            <TabPanel>
              <div
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingTop: '162px',
                }}
              >
                <center>
                  <p
                    className="fa fa-search"
                    style={{
                      fontSize: '35px',
                      color: '#3375fe',
                      paddingBottom: '10px',
                    }}
                  ></p>
                  <h6 className="no-datatext">ETH Pair Unavailable</h6>
                </center>
              </div>
            </TabPanel>

            <TabPanel>
              <div
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingTop: '162px',
                }}
              >
                <center>
                  <p
                    className="fa fa-search"
                    style={{
                      fontSize: '35px',
                      color: '#3375fe',
                      paddingBottom: '10px',
                    }}
                  ></p>
                  <h6 className="no-datatext">BNB Pair Unavailable</h6>
                </center>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default Assets;
