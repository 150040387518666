import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import './pie.scss';
import axios from 'axios';
import Cookies1 from 'js-cookie';
import ContentLoader, { Facebook } from "react-content-loader";

const MyLoader = () => (
    <ContentLoader
    viewBox="0 0 200 200"
    backgroundColor="#f0f0f0"
    foregroundColor="#e0e0e0"
    style={{ width: '300px', height: '300px' }}
  >
   <rect x="30%" y="16%" rx="50%" ry="50%" width="150" height="150" />
   
  </ContentLoader>
  );

const CryptoPieChart = (props) => {



  const data = props.data
  const [userList, setUserList] = useState({
    balance:0,
    price:0
  });


  useEffect(()=>{
    fetchMyTable()
  })


async function fetchMyTable() {

    const accountUserId =  Cookies1.get('investorId');
  
  
    var data = '';
  
  var config = {
    method: 'get',
    url: `https://test.cetoex.org/api/buyDetails?investorId=${accountUserId}&APIKEY=494093&Coin=BTC`,
    headers: { },
    data : data
  };
  
  axios(config)
  .then(function (response) {
    setUserList({balance:JSON.stringify(response.data.Balance),price:JSON.stringify(response.data.last_price)});
   
  })
  .catch(function (error) {
    console.log(error);
  });
  
    
       
  }
  



  return (
      <>
      <h6>Crypto Portfolio</h6>
      <p>Unlock Your Crypto Potential: Diversify, Thrive, and Soar!</p>
     <div>
       
    <Chart
     width={'100%'} // Set the width to your desired size (e.g., '800px')
     height={'400px'} // Set the height to your desired size (e.g., '600px')
   
      chartType="PieChart"
      loader={<MyLoader/>}
      data={data}
      options={{
        is3D: true,
        pieSliceText: 'label',
        backgroundColor: 'transparent',
        tooltip: { isHtml: true },
        legend: {
            position: 'bottom', // You can change the legend position here
            alignment: 'center',
            textStyle: {
                fontSize: 12,
                color: 'gray',
             
              },
          },

      }}
    />
<center>
    <br/>
   <div style={{}}>
        <a href="/crypto-deposit" className="wallet-deposit"><span className="fa fa-arrow-down"></span> Deposit</a>
        <a href="/crypto-withdrawal" className="wallet-withdraw"><span className="fa fa-arrow-up"></span> Withdraw</a>
        </div> 
        </center>
    </div>
     </>
  );
 
};

export default CryptoPieChart;
