import React, { useState,useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Bars } from  'react-loader-spinner'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImageLoader from "../loader/loader";
import Cookies1 from 'js-cookie';
import Sale01 from '../sale/Sale01';
import PageTitle from '../pagetitle';
import './liquidity.scss';


function LiquidityPool() {
    const navigate = useNavigate();

    const [LiqudityList, setLiqudityList] = useState({
        coin:"...",
        usdt:"...",
        token:"...",
        liqudity:"..",

      });


    useEffect(() => {
    
      
        const token = Cookies1.get('LoginToken');
     
        if(token != null)
        {
            fetchMyTable();
                  
        } 
        else{
          navigate('/login');
        }    
      }, [])
  

      async function fetchMyTable() {

        const accountUserId = Cookies1.get('investorId');
      
    
          var data = JSON.stringify({
           "investorId": accountUserId
         });
         
         var config = {
           method: 'post',
           url: 'https://test.cetoex.org/api/liqudity',
           headers: { 
             'Content-Type': 'application/json'
           },
           data : data
         };
         
         axios(config)
         .then(function (response) {

           

            if(response.data.status === "success")
            {

             const PoolData =  JSON.stringify(response.data.data[0]);
 
             const TradeData = JSON.parse(PoolData).liqudity_trade;

             
             setLiqudityList({
                coin:TradeData.coin_symbol,
                usdt:TradeData.usdt,
                token:TradeData.token,
                liqudity:TradeData.volumeLimit,

             });



            }
            else{
                navigate('/wallet'); 
            }
    
              })
         .catch(function (error) {
           console.log(error);
         });
      
        }
  





    return (
        <div>
         
           
                <div className="container">
                    <div className="row">
                 
                           
                               

                                <div className="main policy-bannner" style={{padding:"30px",}}>
                                  <h4>Pool Policy</h4>
                                 <ul>
                                     <li className="policy-header fs-20">1. Liquidity Provision
                                     
                                     </li>
                                     <ol>

                                           <li>Coin owners have the option to provide liquidity by pairing their tokens with USDT on our exchange.</li>
                                           <li>The minimum liquidity provision for any token/USDT pair must be at least $200.</li>
                                       </ol>
                                       <br/>
                                     <li  className="policy-header fs-20">2. Automated Trading Bot</li>

                                     <ol>
                                           <li>CetoEX employs an advanced trading bot to manage liquidity orders.</li>
                                           <li>The trading bot automatically places buy and sell orders to enhance market activity and ensure that users can easily buy or sell their tokens.</li>
                                           <li>This system is designed to increase trading volume and improve market efficiency.</li>
                                       </ol>
                                       <br/>
                                     <li  className="policy-header fs-20">3. Trading Bot Accessibility</li>

                                     <ol>
                                           <li> The use of our trading bot is completely free for all liquidity providers.</li>
                                           <li> By leveraging the trading bot, liquidity providers can benefit from increased token visibility and higher trading volumes.</li>

                                         
                                       </ol>
                                       <br/>
                                     <li  className="policy-header fs-20">4. Impact of Liquidity on Trust Score</li>

                                     <ol>
                                           <li>Higher liquidity levels on a token/USDT pair will result in a higher trust score for that token.</li>
                                           <li>A higher trust score signifies a more reliable and stable trading environment, attracting more traders and investors.</li>
                                           <li>Enhanced liquidity and trust score contribute to greater trading volume and overall market confidence in the token.</li>

                                         
                                       </ol>
                                       <br/>
                                       <li>By providing liquidity on CetoEX and utilizing our free trading bot, coin owners can significantly enhance their token's market presence. The combination of automated trading and a higher trust score will drive greater trading volume and ensure a seamless trading experience for all users.</li>
                                 </ul>
                                 
                                  
                               

                                </div>
                           
                         

                    </div>
                </div>

           
            
        </div>
    );
}

export default LiquidityPool;
