import React, { useEffect, useState } from 'react';
import "./style.scss";
import ContentLoader, { Facebook } from "react-content-loader";

const MyLoader = () => (
    <ContentLoader  height={360}  width="100%" foregroundColor="#cae9ea">
      <rect x="2%" y="6" rx="50" ry="50" width="41" height="41" />

      <rect x="9%" y="11" rx="1" ry="4" width="8%" height="14" />
      <rect x="9%" y="33" rx="1" ry="3" width="6%" height="8" />
      <rect x="9%" y="42" rx="1" ry="3" width="6%" height="0.5" />

      <rect x="20%" y="11" rx="1" ry="3" width="11%" height="17" />
      <rect x="20%" y="33" rx="1" ry="3" width="10%" height="8" />

      <rect x="34%" y="8" rx="1" ry="3" width="1" height="38" />
      
      <rect x="38%" y="13" rx="1" ry="3" width="11%" height="13" />
      <rect x="38%" y="33" rx="1" ry="3" width="9%" height="11" />

      <rect x="52%" y="13" rx="1" ry="3" width="11%" height="13" />
      <rect x="52%" y="33" rx="1" ry="3" width="9%" height="11" />

      <rect x="66%" y="13" rx="1" ry="3" width="11%" height="13" />
      <rect x="66%" y="33" rx="1" ry="3" width="9%" height="11" />

      <rect x="80%" y="13" rx="1" ry="3" width="11%" height="13" />
      <rect x="80%" y="33" rx="1" ry="3" width="9%" height="11" />
    
    </ContentLoader>
  );
  
 

export default function ChartHeader(props1) {

    const [isLoading, setIsLoading] = useState(true);
    const [chartHeader,setChartHeader] = useState({
        logo:'',
        pair:'',
        price:'',
        name:'',
        change:'',
        high:'',
        low:'',
        volume:''
    });

    useEffect(() => {
      
        const socket = new WebSocket(`wss://websocket.cetoex.com:8080/chartHeader?coinName=${props1.data}`);

        socket.onopen = () => {
          console.log('WebSocket connection established.');
        };
    
        socket.onmessage = (event) => {
          const receivedData = JSON.parse(event.data);
          setIsLoading(false);

          document.title = `${receivedData[0]['coin_price']} ${receivedData[0]['coin_symbol']}/USDT | CetoEX Trade`;
          setChartHeader({logo:receivedData[0]['coin_logo'],pair:receivedData[0]['coin_symbol'],price:receivedData[0]['coin_price'],name:receivedData[0]['coin_name'],change:receivedData[0]['coin_pricechange'],high:receivedData[0]['coin_high'],low:receivedData[0]['coin_low'],volume:receivedData[0]['coin_baseVolume']});

        
        };
    
        socket.onerror = (error) => {
          console.error('WebSocket error:', error);
        };
    
        socket.onclose = () => {
          console.log('WebSocket connection closed.');
        };
    
        // Cleanup function
        return () => {
          socket.close();
        };
    
    }, [props1.data])


   const mainPrice = (price,change)=>
    {
        if(change === 0)
        {
            return(
                <>
                <h6 className="main_price">${scientificToDecimal(price)}<p style={{fontSize:"10px",color:"#999"}}>{chartHeader.name} Price</p></h6>
   
                </>
            )
        }
    
        const numberAsString = change.toString();
       if(numberAsString.substring(0, 1) === "-")
       {
        return(
            <>
              <h6 className="down main_price">${scientificToDecimal(price)}<p style={{fontSize:"10px",color:"#999"}}>{chartHeader.name} Price</p></h6>
   
            </>
        )
       }
       else{
        return(
            <>
             <h6 className="up main_price">${scientificToDecimal(price)}<p style={{fontSize:"10px",color:"#999"}}>{chartHeader.name} Price</p></h6>
    
            </>
        )
       }

    }


    function convertTextToHTML(text) {
        const div = document.createElement('div');
        div.innerHTML = text;
        return div.innerHTML;
      }


      function formatNumber(number) {

        if(number == 0)
        {
        return number;
        }
        else{
          const suffixes = ['', 'K', 'M', ' Billion', ' Trillion', ' Quadrillion'];
          const magnitude = Math.floor(Math.log10(Math.abs(number)) / 3);
        
          if (magnitude >= suffixes.length) {
            return number.toString(); // Return as-is if the number is too large for available suffixes
          }
        
          const scaledNumber = number / Math.pow(10, magnitude * 3);
          const formattedNumber = scaledNumber.toFixed(2);
        
          return formattedNumber + suffixes[magnitude];
        }

       
      }

    function scientificToDecimal(num) {
        const str = num.toString().toLowerCase();
        if (str.indexOf('e') === -1) {
          return str;
        }
        const parts = str.split('e');
        const coefficient = parseFloat(parts[0]);
        const exponent = parseInt(parts[1]);
        const decimalPart = coefficient.toFixed(4).replace('.', '');
        const exponentPart = Math.abs(exponent).toString();
        return (
            <>
            0.0<sub>{exponentPart-1}</sub>{decimalPart}
            </>
        )
      }
      

    const mainChange = (change)=>{
        if(change === 0)
        {
            return(
                <>
                  <p style={{fontWeight:"400",fontSize:"14px",textDecoration:"underline"}}>{change}%</p>
                </>
            )
        }
    
        const numberAsString = change.toString();
       if(numberAsString.substring(0, 1) === "-")
       {
        return(
            <>
              <p style={{fontWeight:"400",fontSize:"14px",textDecoration:"underline"}}  className="down">{change}%</p>
            </>
        )
       }
       else{
        return(
            <>
              <p style={{fontWeight:"400",fontSize:"14px",textDecoration:"underline"}}  className="up">{change}%</p>
            </>
        )
       }
    }


    return(
        <>




<div className="containertype11">

    {
        isLoading ? (
            <>

<div style={{height:"55px",width:"100%"}}>
<MyLoader/>
</div>
 




            </>
        ):(
            <>

<div className="assets">
  <img src={chartHeader.logo} alt="coin" style={{width:"36px",height:"36px",margin:"4px",borderRadius:"50%"}}/>
  <div className="assetbox">
          <p style={{color:"var(--onsurface)",fontWeight:"bold",fontFamily:"Arial, sans-serif"}}>{chartHeader.pair}/USDT</p>
          {mainChange(chartHeader.change)}


    </div> 
</div>
 
<div>
     {mainPrice(chartHeader.price,chartHeader.change)}
   
</div>
<div className="vl"></div>
  
    <div className="crypto-data"> 
      <p className="banner-up">24H High</p>
      <p className="banner-up dark">${scientificToDecimal(chartHeader.high)}</p>
    </div>

    <div  className="crypto-data"> 
      <p className="banner-up">24H Low</p>
      <p className="banner-up dark">${scientificToDecimal(chartHeader.low)}</p>
    </div>

    <div  className="crypto-data"> 
      <p className="banner-up">24H&nbsp;Coin Volume</p>
      <p className="banner-up dark">{formatNumber(chartHeader.volume)}</p>
    </div>

    <div  className="crypto-data"> 
      <p className="banner-up">24H&nbsp;Volume&nbsp;(USDT)</p>
      <p className="banner-up dark">{formatNumber(chartHeader.volume * chartHeader.price)}</p>
    </div>
  

            </>
        )
    }


</div>







        </>
    )

}