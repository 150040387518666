import React from 'react';
import Sale01 from '../components/sale/Sale01';

import { Helmet } from 'react-helmet';

function PartnerAuthenticity(props) {



    return (
        <div>

                                                      
<Helmet>
        <title>Privacy &amp; Policy | Your Data, Our Trust | Cetoex</title>
        <meta name="description" content="Learn How Cetoex Safeguards Your Data and Respects Your Privacy. Our Privacy & Policy Details Our Commitment to Keeping Your Information Secure."/>
        <meta name="keywords" content="crypto market, crypto deposit history, cetoex, exchange, top ranking coin, top volume, newly listed, top gainers, top losers, cetoex exchange, spot, trading, crypto"/>
        <meta name="author" content="Cetoex"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        
        {/* Open Graph (OG) tags for sharing on social media */}
        <meta property="og:title" content="Privacy &amp; Policy | Your Data, Our Trust | Cetoex"/>
        <meta property="og:description" content="Learn How Cetoex Safeguards Your Data and Respects Your Privacy. Our Privacy & Policy Details Our Commitment to Keeping Your Information Secure."/>
        
        {/* Twitter card tags for sharing on Twitter */}
        <meta name="twitter:card" content="cetoex"/>
        <meta name="twitter:title" content="Privacy &amp; Policy | Your Data, Our Trust | Cetoex"/>
        <meta name="twitter:description" content="Learn How Cetoex Safeguards Your Data and Respects Your Privacy. Our Privacy & Policy Details Our Commitment to Keeping Your Information Secure."/>
        
      </Helmet>


            {/* <PageTitle heading='FAQ' title='FAQ' /> */}

            <section className="faq">
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <div className="block-text center">
                    <h3 className="heading">CetoEX Privacy Policy</h3>
                   </div>
                </div>
                <div className="col-md-12">
               
                 <p> This Privacy Policy describes how CetoEX collects and processes your personal information through the CetoEX websites and applications that reference this Privacy Notice. CetoEX refers to an ecosystem comprising CetoEX websites (whose domain names include but are not limited to www.cetoex.com), mobile applications that are developed to offer CetoEX Services, and includes independently-operated platforms, websites and clients within the ecosystem. CetoEX Operators” refer to all parties that run CetoEX, including but not limited to legal persons, unincorporated organizations and teams that provide CetoEX Services and are responsible for such services.</p>
                 <p>At CetoEX, we are committed to safeguarding the privacy and security of our users' personal information. This Privacy Policy outlines how we collect, use, store, and protect your data when you use our platform and services. By using CetoEX, you agree to the terms described in this policy.</p>
                  <br/>
                     <ol>
                     <li ><h6>1. Information Collection:</h6>
                      <p>We collect various types of information from our users to provide and improve our services. This includes:</p>
                      <ul>
                          <li><b>A. Personal Information:</b> When you sign up for an account, we may collect personal information such as your name, email address, and contact details. We require this information for identity verification and account management purposes.</li>
                          <li><b>B. Financial Information:</b> To facilitate transactions on our platform, we may collect financial information, such as cryptocurrency wallet addresses.</li>
                          <li><b>C. Log Data:</b> We automatically collect certain information when you use our services, including IP addresses, device information, and browsing activity. This data is essential for system administration, security, and troubleshooting purposes.</li>
                          </ul>
                     </li>
                     <br/>
                     <li ><h6>2. Information Use:</h6>
                     <p>We use the collected information to:</p>
                      <ul>
                          <li><b>A. Provide and improve our services:</b> Your information enables us to process transactions, verify identities, and offer customer support.</li>
                          <li><b>B. Personalize user experience:</b> We may use your information to personalize your experience on our platform, recommend relevant services, and display targeted advertisements.</li>
                          <li><b>C. Comply with legal obligations:</b> We may use your data to comply with applicable laws, regulations, and legal processes.</li>
                          </ul>
                     </li>
                     <br/>
                     <li ><h6>3. Information Sharing:</h6>
                      <p>We prioritize the protection of your data and limit its sharing. We may share your information in the following circumstances:</p>
                      <ul>
                          <li><b>A. With service providers:</b> We may engage trusted third-party service providers who assist us in operating our platform and delivering services. These providers are bound by confidentiality agreements and are only granted access to the necessary information.</li>
                          <li><b>B. Legal requirements:</b> We may disclose your information if required by law enforcement agencies, regulatory bodies, or as necessary to protect our rights, safety, or the rights and safety of others.</li>
                        
                          </ul>
                     </li>
                     <br/>
                     <li ><h6>4. What About Cookies and Other Identifiers?:</h6>
                      <p>We use cookies and similar tools to enhance your user experience, provide our services, and understand how customers use our services so we can make improvements. To enable our systems to recognise your browser or device and to provide CetoEX Services to you, we use cookies.</p>
                      <p>We use operational cookies and similar tools (collectively, "cookies") to provide our services, for example:</p>
                      <ul>
                          <li>Recognising you when you sign-in to use our services.</li>
                          <li>Providing customised features and services.</li>
                          <li>Displaying features and services which might be of interest to you, including ads on our services.</li>
                          <li>Preventing fraudulent activity.</li>
                          <li>Improving security.</li>
                          <li>Keeping track of your preferences such as currency and language.</li>
                          <li>We also use cookies to understand how users use our services so we can make improvements.</li>
                         
                          </ul>
                          <p>The settings on your browser will tell you how to prevent your browser from accepting new cookies, how to have the browser notify you when you receive a new cookie, how to disable and remove cookies, and when cookies will expire.</p>
                          <p>Operational cookies allow you to take advantage of some of CetoEX's essential features. If you block or otherwise reject operational cookies through your browser settings some features and services may not work.</p>
                     </li>
                     <br/>
                     <li ><h6>5. Does CetoEX Share My Personal Information?:</h6>
                     <p>Information about our users is an important part of our business and we are not in the business of selling our users' personal information to others.   CetoEX shares users' personal information only as described below and with the subsidiaries or affiliates of CetoEX that are either subject to this Privacy Notice or follow practices at least as protective as those described in this Privacy Notice.</p>
                     <p>Third party service providers: We employ other companies and individuals to perform functions on our behalf.  Examples include analysing data, providing marketing assistance, processing payments, transmitting content, and assessing and managing credit risk.  These third-party service providers have access to personal information needed to perform their functions, but may not use it for other purposes. Further, they must process the personal information in accordance with this Privacy Notice and as only permitted by applicable data protection laws.</p>
                     </li>
                     <br/>

                     <li ><h6>6. Data Security:</h6>
                     <p>We employ industry-standard security measures to protect your information from unauthorized access, disclosure, alteration, or destruction. These measures include encryption, secure socket layer (SSL) technology, firewalls, and regular security audits.</p>
                     </li>
                     <br/>
                     <li ><h6>7. Data Retention:</h6>
                     <p>We retain your information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>
                     </li>
                     <br/>
                     <li ><h6>8. Third-Party Websites and Services::</h6>
                     <p>Our platform may contain links to third-party websites or services that have their own privacy policies. We are not responsible for the practices employed by these websites or services and recommend reviewing their privacy policies.</p>
                     </li>
                     <br/>
                     <li ><h6>9. Updates to the Privacy Policy:</h6>
                     <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant updates through appropriate channels.</p>
                     </li>
                     <br/> 
                   

                     <li ><h6>10. Contact Us:</h6>
                     <p>If you have any questions, concerns, or requests regarding this Privacy Policy or the handling of your data, please contact our support team for assistance.</p>
                     </li>
                     </ol>
                </div>
                </div>
            </div>
            </section>

            <Sale01 />
            
        </div>
    );
}

export default PartnerAuthenticity;