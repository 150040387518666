import React , {useState} from 'react';
import { useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Sell from '../Sell/sell';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies1 from 'js-cookie';
import ImageLoader from "../loader/loader";



function Orderbook(prop1) {
  
  const [userList, setUserList] = useState({
    balance:0,
    price:0
  });
 
  const[coinPrice,setCoinPrice]= useState('');
  const [isInputDisabled, setIsInputDisabled] = useState(true);
  const[loader,setLoader] = useState(false);
  const [login,setLogin] = useState(true);
  

  // Step 3: Handle the onChange event to update the selected value
  const handleSelectChange = (event) => {
    if(event.target.value === "market")
    {
      setIsInputDisabled(true)
      setCoinPrice(userList.price)

      const coinAmount =  document.getElementById('coin_amount').value;
    
      document.getElementById('coin_count').value = (coinAmount * userList.price).toFixed(3)


    }
    else{
      setIsInputDisabled(false)
    }
   
  };
   


  useEffect(() => {
    const token = Cookies1.get('LoginToken');
     
    if(token != null)
    {
      fetchMyTable()
    }
    else{
      setLogin(false)
    }
   
   
  }, [])

  

async function fetchMyTable() {
  

  const accountUserId = Cookies1.get('investorId');
  var data = '';

var config = {
  method: 'get',
  url: `https://test.cetoex.org/api/buyDetails?investorId=${accountUserId}&APIKEY=494093&Coin=${prop1.data}`,
  headers: { },
  data : data
};

axios(config)
.then(function (response) {
  setUserList({balance:JSON.stringify(response.data.Balance),price:JSON.stringify(response.data.last_price)});
  setCoinPrice(response.data.last_price);
})
.catch(function (error) {
  console.log(error);
});

  
     
}



const setCoin = (coinPer)=>{
   
     const usdtAmount =  userList.balance * coinPer/100
     document.getElementById('coin_amount').value = usdtAmount.toFixed(3)
     document.getElementById('coin_count').value = (usdtAmount / coinPrice).toFixed(6)
    }





    const buyCoin =  async ()=>{
      const accountUserId = Cookies1.get('investorId');
      const investVal = document.getElementById('coin_amount').value;
      const buyType = document.getElementById('buyType').value;

      setLoader(true)
      
      var data = JSON.stringify({
        "investorId": accountUserId,
        "APIKey": "392thfwfj9if",
        "Coin": prop1.data,
        "Value": investVal,
        "Order": buyType,
        "bidPrice":coinPrice
      });

      
      var config = {
        method: 'post',
        url: 'https://test.cetoex.org/api/buyCrypto',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
        if(response.data.status === "Success")
        {


          document.getElementById('coin_amount').value = ""
          document.getElementById('coin_count').value =""

          toast.success(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });


          fetchMyTable()
        }
        else{
          toast.error(response.data.status, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
        }

        setLoader(false)
       
      })
      .catch(function (error) {
        console.log(error);
      });


    }




     const updateUSDT = ()=>{
     const coinAmount =  document.getElementById('coin_amount').value;

     document.getElementById('coin_count').value = (coinAmount / coinPrice).toFixed(3)
     

      }

      const updateamount = ()=>{
        const coinAmount =  document.getElementById('coin_count').value;

        document.getElementById('coin_amount').value = (coinAmount * coinPrice).toFixed(3)
      }


      const handleInputChange = (event) => {
        setCoinPrice(event.target.value);
       
        const coinAmount =  document.getElementById('coin_amount').value;
        document.getElementById('coin_count').value = (coinAmount / event.target.value).toFixed(3)
      


      };
    
      

  return (
    <>

      
  
{
    loader && <ImageLoader/>
}

     
       
     <div className="row" style={{marginTop:"10px"}}>
       <div className="col-6" >


         
                       <div className="flat-tabs">
                      
  <div className="table-main"  style={{border:"1px solid #eee"}}>   
  <Tabs defaultIndex={0} selectedTabClassName="tab-design2">
    <TabList  className="my-0"  id="tab-basic">
      <Tab>
       Buy {prop1.data}
     </Tab>
     
   
    </TabList>

    <TabPanel>
  
    <ToastContainer
position="bottom-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="dark"
/>
    <form className="form-buy">
   
    <div className="col-12" style={{textAlign:"left",fontWeight:"500",marginBottom:"9px",fontSize:"13px"}}>
      Balance : {userList.balance} USDT
     </div>

   


  <div id="change1" />

  
  <div className="input-container">
    <i className="fa fa-book icon" />
    <select id="buyType"
      className="input-field forminput" style={{fontSize:"11px",fontWeight:"bold"}}
      onChange={handleSelectChange}
    >
       <option value="market" selected="">
        Market
      </option>
      <option value="limit">Limit</option>
     
    </select>
  </div>
  <div className="input-container" id="coin_input">
    <i className="fa fa-minus icon" />
    <input
      className="input-field forminput"
      type="text"
      placeholder="Price "
      value={coinPrice}  
      id="coin_price"  onChange={handleInputChange}
      disabled={isInputDisabled}
     style={{fontWeight:"bold"}}/>
    <i className="fa fa-plus icon" />
  </div>
 
  <div className="input-container">
    <i className="fa fa-minus icon" />
    <input
      className="input-field forminput"
      type="text"
      placeholder="Enter USDT"
      id="coin_amount"
       onKeyUp={updateUSDT}
      style={{ fontWeight: "bold" }}
    />
    <i className="fa fa-plus icon" />
  </div>




  <button type="button" className="btn3" onClick={(e)=>setCoin(25)}>
    25%
  </button>
  <button  type="button"  className="btn3" onClick={(e)=>setCoin(50)}>
    50%
  </button>
  <button  type="button"  className="btn3" onClick={(e)=>setCoin(75)}>
    75%
  </button>
  <button  type="button"  className="btn3" onClick={(e)=>setCoin(100)}>
    100%
  </button>
  <div className="input-container" style={{ marginTop: 10 }}>
    <input
      className="input-field forminput"
      type="text"
      placeholder={`Total (${prop1.data})`}
      id="coin_count"
      onKeyUp={updateamount}
      style={{ fontWeight: "bold" }}
    />
  </div>
 {
   login ? (
   <>
   <button  type="button"  className="btn12" onClick={buyCoin}>
    BUY {prop1.data}
  </button>
   </>
   ):(
     <>
     <a  href="/login" type="button" style={{backgroundColor:"transparent",width:"100%",padding:"5px",fontWeight:"bold",border:"1.5px solid #5cb85b",color:"#5cb85b",textAlign:"center"}}>
   Login To Buy <span className="fa fa-sign-in"></span>
  </a>
     </>
   )
   
 }
  
</form>

    </TabPanel>


 
    
    </Tabs>
                   
 </div>
</div>
  
                       </div>

                       <div className="col-6">
           <Sell data={prop1.data}/>

    </div>
                     </div>

                  
    </>
  );
}

export default Orderbook;
