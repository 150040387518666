import React , {useState} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useEffect } from 'react';
import Sale01 from '../components/sale/Sale01';
import PageTitle from '../components/pagetitle';
import Modal from 'react-modal';
import Copy from 'clipboard-copy';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { saveAs } from 'file-saver'
import { Bars } from  'react-loader-spinner'
import ImageLoader from "../components/loader/loader";
import Cookies1 from 'js-cookie';
import { Helmet } from 'react-helmet';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      width:'100%',
      maxWidth:'380px',
      margin:'5px',
     
      transform: 'translate(-50%, -50%)',
    },
  };

  

function Deposit(props) {

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [depositList, setDepositList] = useState([]);
    const[loader,setLoader] = useState(false);
    const[depositLoad,setDepositLoad] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const [deposit,setDeposit] = useState({
        coin:"",
        address:"",
        qrCode:"",
        downloadQRCode:"",
        chain:""
    });
    
   

   const filteredData = depositList.filter((item) =>
  item.coinSymbol.toLowerCase().includes(searchQuery.toLowerCase()) ||
  item.coinName.toLowerCase().includes(searchQuery.toLowerCase())
);



    useEffect(() => {
      const token = Cookies1.get('LoginToken');
     
      if(token != null)
      {
       fetchMyTable()
      } 
      else{
        navigate('/login');
      }    
      },[])


      const downloadImage = (e)=>{
        e.preventDefault();
    
        saveAs('image_url',deposit.downloadQRCode);
        toast.success("Address Image is downloaded.", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
      }
    
      function closeModal() {
        setIsOpen(false);
      }
  
  


      const handleSearchChange = (event) => {
        const realVal = event.target.value;
       setSearchQuery(realVal.toUpperCase());
     };


  
   async function fetchMyTable() {
          const response =  await axios.get(`https://test.cetoex.org/api/deposit`);
          setDepositLoad(false)
          setDepositList(response.data)
    }


    const makeDeposit = async(coinId)=>{

       const depositType = document.getElementById(coinId).value;
       const accountUserId = Cookies1.get('investorId');


       setLoader(true)

       var data = JSON.stringify({
        "investorId": accountUserId,
        "depositType": depositType
      });
      
      var config = {
        method: 'post',
        url: 'https://test.cetoex.org/api/makedeposit',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
       
        if(response.data.status === "success")
        {

            setDeposit({coin:coinId,address:response.data.depositAddress,qrCode:response.data.depositQR,downloadQRCode:response.data.depositQRDownload,chain:response.data.depositNetwork});
            setIsOpen(true);
          
        }

        setLoader(false)
       
      })
      .catch(function (error) {
        toast.error(error, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
      });
    }
  


    const CopyAddress = (e)=>{
        e.preventDefault();
        Copy(deposit.address);
         

        toast.success("Deposit Address is copied", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });

    }

    const CryptoTab = (title)=>
    {
       if(title === "Deposit History")
       {
        navigate('/deposit-history');
       }
    }

    const [dataCryptoTab] = useState([
        {
            id: 1,
            title: 'Crypto Deposit',
        },
        {
            id: 2,
            title: 'Deposit History',
        },
        
    ])
    return (
      <>

<Helmet>
        <title>Secure Crypto Deposit | Cetoex</title>
        <meta name="description" content="Effortlessly fund your account with secure crypto deposits on Cetoex Exchange. Start trading with confidence today!"/>
        <meta name="keywords" content="crypto market, crypto deposit, cetoex, exchange, top ranking coin, top volume, newly listed, top gainers, top losers, cetoex exchange, spot, trading, crypto"/>
        <meta name="author" content="Cetoex"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        
        {/* Open Graph (OG) tags for sharing on social media */}
        <meta property="og:title" content="Secure Crypto Deposit | Cetoex"/>
        <meta property="og:description" content="Effortlessly fund your account with secure crypto deposits on Cetoex Exchange. Start trading with confidence today!"/>
        
        {/* Twitter card tags for sharing on Twitter */}
        <meta name="twitter:card" content="cetoex"/>
        <meta name="twitter:title" content="Secure Crypto Deposit | Cetoex"/>
        <meta name="twitter:description" content="Effortlessly fund your account with secure crypto deposits on Cetoex Exchange. Start trading with confidence today!"/>
       
   
        {/* Add other meta tags here */}
      </Helmet>


        <div>
            <PageTitle heading='Deposit Crypto' title='Deposit Crypto' />

            <ToastContainer position="bottom-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark"/>

            
{
    loader && <ImageLoader/>
}

            <section className="wallet sell buy-crypto flat-tabs">
                <div className="container">
                    <div className="row">
                    <Tabs>
                            <TabList>

                                {
                                    dataCryptoTab.map(idx => (
                                        <Tab key={idx.id} onClick={()=>CryptoTab(idx.title)}>{idx.title}</Tab>
                                    ))
                                }

                            </TabList>

                            <TabPanel>
                                <div className="content-inner">
                              
                                       
                                        <Modal
                                            isOpen={modalIsOpen}
                                            onRequestClose={closeModal}
                                            style={customStyles}
                                            contentLabel="Example Modal"
                                        >
                                    
                                        <form>
                                           <p style={{paddingBottom:"10px",color:"black",fontSize:"20px"}}><b>{deposit.coin} Deposit</b></p><br/>
                                           <center>
                                        <img src={deposit.downloadQRCode} alt="QR Code" style={{width:"60%"}}/>
                                        <p style={{padding:"18px",fontSize:"11px"}}>Send only {deposit.coin} to this address. This address does not support deposit of non-fungiable token.</p>
                                        </center>
                                        
                                        <div className="form-group">
                                        <label for="exampleInputEmail1" style={{fontSize:"13px",color:"black"}}>WALLET ADDRESS</label>
                                        <p style={{fontSize:"12px"}}>{deposit.address} <span className="fa fa-copy"  onClick={CopyAddress} style={{fontSize:"15px"}}></span></p>
                                        </div>
                                       
                                        <div className="form-group" style={{marginTop:"10px"}}>
                                        <label for="exampleInputEmail1" style={{fontSize:"13px",color:"black"}}>NETWORK</label>
                                        <p style={{fontSize:"12px"}}>{deposit.chain}</p>
                                        </div>

                                        <div className="form-group"  style={{marginTop:"10px"}}>
                                        <label for="exampleInputEmail1" style={{fontSize:"13px",color:"black"}}>TIMEOUT</label>
                                        <p style={{fontSize:"12px"}}>20 MINUTES</p>
                                        </div>
                                        <br/>
                                      <center> <button className="btn btn-primary" style={{color:"white",fontSize:"12px"}} onClick={CopyAddress}>Copy Address</button>
                                     <button className="btn btn-secondary"  onClick={downloadImage} style={{color:"white",fontSize:"12px",marginLeft:"25px"}}>Download QR Code</button>
                                       </center>
                                        </form>
                                        </Modal>
                                       
                         
                                   

                                    <div className="coin-list-wallet"  style={{height:"700px",overflowY:"auto"}}>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12 col-sm-12">
                                        <h6> Coin Deposit</h6>
                                        <p>Find promising coins and great opportunities!</p><br/>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12">
                                        <div className="field2" id="searchform">
                                            <input type="text"   placeholder="Type symbol, name.." onChange={handleSearchChange} value={searchQuery}/>
                                                <button type="button" id="search">
                                                <span className="fa fa-search"></span> Search
                                                </button>
                                          </div>
                                        </div>
                                     </div><br/>
                                    <table className="table">
                                        <thead>
                                        <tr>
                                          
                                            <th style={{textAlign:"left",color:"var(--tablemain)"}}scope="col">Name</th>
                                            <th scope="col" style={{color:"var(--tablemain)"}}>Blockchain</th>
                                            <th scope="col"  style={{color:"var(--tablemain)"}}>Deposit</th>
                                            
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {
                filteredData && filteredData.length>0 ?
                filteredData.map((idx, i) =>
                    
                <tr key={idx.coinSymbol}>
                
                <td className="asset">
                <span className="icon-btc">
                  <img src={idx.coinLogo} style={{width:"29px",borderRadius:"50%"}}/>
                    
                    </span>
                <p>
                    <span className="boild">{idx.coinSymbol}</span>
                    <span className="unit">{idx.coinName}</span>
                </p>
                </td>
               <td>
                   <select id={idx.coinSymbol}>
                   {idx.BSC? <option value={idx.coinSymbol+".BEP20"}>Binance Smart Chain</option>: null }
                   {idx.ETH? <option value={idx.coinSymbol+".ERC20"}>Ethereum Network</option>: null }
                   {idx.BASE? <option value={idx.coinSymbol+".BASE"}>Base Network</option>: null }
                   {idx.TRX? <option value={idx.coinSymbol+".TRC20"}>Tron Network</option>: null }
                   {idx.MATIC? <option value={idx.coinSymbol+".MATIC"}>Polygon Network</option>: null }
                   {idx.FTM? <option value={idx.coinSymbol+".FTM"}>Fantom Network</option>: null }
                   {idx.SOL? <option value={idx.coinSymbol+".SOL"}>Solana Network</option>: null }
                   {idx.TON? <option value={idx.coinSymbol+".TON"}>TON Network (Jettons)</option>: null }
                   {idx.OWN? <option value={idx.coinSymbol}>Mainnet</option>: null }
                  
                     
                   </select>
               </td>
                <td className="color-success">
                <button to="#" className="btn" style={{backgroundColor:"#3673ff",color:"white",fontSize:"10px"}} onClick={()=>makeDeposit(idx.coinSymbol)}>Deposit {idx.coinSymbol}</button>
                </td>
            </tr>

                ): (
               <>{
                depositLoad?
                (
                  <>
                     <tr>
                        <td colSpan="5">
                    <center>   <div style={{marginLeft:"45%"}}>
                   <Bars
    height = "40"
    width = "40"
    radius = "9"
    color = '#3375fe'
    ariaLabel = 'three-dots-loading'  
    marginLeft='200'   
    wrapperStyle
    wrapperClass
  />
  </div></center>
  </td>
  </tr>
                </>
                ):(
                  <>
                   <tr>
                        <td colSpan="5">
                    <center>   <div >
                    <div style={{ justifyContent: 'center', alignItems: 'center', paddingTop: '132px' }}>
                 <center> <p className="fa fa-bar-chart" style={{fontSize:"35px",color:"#3375fe",paddingBottom:"10px"}}></p>
                  <h6  className="no-datatext">Asset Not Found</h6></center>
                </div>
  </div></center>
  </td>
  </tr>
                  
                  </>
                )
               }
               </>


                ) 
                 }
                 


                                            
                                      
                                       
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                            </TabPanel>
                          

                    </Tabs> 

                    </div>
                </div>
            </section>

            <Sale01 />
            
        </div>
        </>
    );
}

export default Deposit;