import React from 'react';
import Banner05 from '../components/banner/Banner05';
import dataPartner from '../assets/fake-data/data-partner';
import { Helmet } from 'react-helmet';


function Airdrop(props) {

    return (
        <div className='home-1'>

                          
<Helmet>
        <title>Get Free Coin: Participate Now | CetoEX</title>
        <meta name="description" content="Claim Your Share of Free Cryptocurrencies through Airdrops on Cetoex. Don't Miss Out on Exciting Opportunities in the Crypto Space."/>
        <meta name="keywords" content="crypto market, crypto deposit history, cetoex, exchange, top ranking coin, top volume, newly listed, top gainers, top losers, cetoex exchange, spot, trading, crypto"/>
        <meta name="author" content="Cetoex"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        
        {/* Open Graph (OG) tags for sharing on social media */}
        <meta property="og:title" content="Get Free Coin: Participate Now | CetoEX"/>
        <meta property="og:description" content="Claim Your Share of Free Cryptocurrencies through Airdrops on Cetoex. Don't Miss Out on Exciting Opportunities in the Crypto Space."/>
        
        {/* Twitter card tags for sharing on Twitter */}
        <meta name="twitter:card" content="cetoex"/>
        <meta name="twitter:title" content="Get Free Coin: Participate Now | CetoEX"/>
        <meta name="twitter:description" content="Claim Your Share of Free Cryptocurrencies through Airdrops on Cetoex. Don't Miss Out on Exciting Opportunities in the Crypto Space."/>
        <meta name="twitter:image" content="https://pbs.twimg.com/profile_images/1556224061248008192/KQTT5Qk3_400x400.jpg"/>
        
      </Helmet>

            <Banner05 data={dataPartner} />


            
        </div>
    );
}

export default Airdrop;