import React,{ useState,useEffect } from "react";
import PropTypes from 'prop-types';
import axios  from "axios";
import Sale01 from '../components/sale/Sale01';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PageTitle from '../components/pagetitle';
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom';
import {Link} from 'react-router-dom';
import img from '../assets/images/icon/qrcode.png'
import Cookies1 from 'js-cookie';

Logout.propTypes = {
    
};

function Logout(props) {
   
  
    const navigate = useNavigate();

   useEffect(() => {
       Cookies1.remove('investorMail');
       Cookies1.remove('investorName');
       Cookies1.remove('investorId');
       Cookies1.remove('LoginToken');
  
        navigate('/login');
          

   
   },[]);
 




    return (
        <div>
        
        </div>
    );
}

export default Logout;