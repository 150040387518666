import React , {useState} from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Chart } from 'react-google-charts';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import 'react-modal-video/scss/modal-video.scss';
import './launchpad.scss';
import Sale01 from '../sale/Sale01';
import { useMediaQuery } from 'react-responsive';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios  from "axios";
import Cookies1 from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';

function LaunchPad2(props) {

    const [isOpen, setOpen] = useState(false);
    const[islogin,setLogin] = useState(false);
    const [tokenDetail,setTokenDetail] = useState({
         coinName:'',
         coinLogo:'',
         coinSymbol:'',
         coinType:'',
         coinSupply:'',
         coinAllocation:'',
         coinPrice:'',
         coinStart:'',
         coinEnd:'',
         coinSold:'',
         coinMin:'',
         coinAbout:'',
         coinStatus:'',
         coinBalance:'',
       });
    
    const isMobile = useMediaQuery({ maxWidth: 667 });
     const { id } = useParams();

 
    
      useEffect(()=>{
        const accountUserId = Cookies1.get('investorId');
     
        if(accountUserId != null)
        {
            setLogin(true);
        } 
        

     
        var data = JSON.stringify({
            "coin": "GAMB",
            "investorID": accountUserId
          });
          
          var config = {
            method: 'post',
            url: 'https://test.cetoex.org/api/tokenDetails',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
           axios(config)
          .then(function (response) {
              if(response.data.status === "success")
              {
                  const fetchData = response.data.data[0];
                 
                  setTokenDetail({coinName:fetchData.coin_name,coinLogo:fetchData.coin_logo,coinSymbol:fetchData.coin_symbol,coinType:fetchData.coinType,coinSupply:fetchData.total_supply,coinAllocation:fetchData.total_allocation,coinPrice:fetchData.coin_price,coinStart:fetchData.coin_start,coinEnd:fetchData.coin_end,coinSold:fetchData.coin_sold,coinMin:fetchData.coin_min,coinAbout:fetchData.coin_about,coinStatus:fetchData.coin_status,coinBalance:response.data.TokenBal});
              }
              else{

              }
          })
          .catch(function (error) {
            console.log(error);
          });
          

      },[]);
    

     const buyToken = ()=>{

        const usdtAmount = document.getElementById('usdt_field').value;
        const accountUserId = Cookies1.get('investorId');

        var data = JSON.stringify({
            "coin": "GAMB",
            "investorID": accountUserId,
            "invest": usdtAmount
          });
          
          var config = {
            method: 'post',
            url: 'https://test.cetoex.org/api/buyToken',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {

           if(response.data.status === "success")
           {
               toast.success(response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
           }
           else{
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
           }

          })
          .catch(function (error) {
            console.log(error);
          });


        
     }




      const tokenCount = ()=>{

        const usdtAmount = document.getElementById('usdt_field').value;
        document.getElementById('calculatedToken').value = `${usdtAmount / tokenDetail.coinPrice} GAMB`;
        
      }

    const [dataCrytoTab] = useState([
        {
            id: 1,
            title: 'All IEO',
        },

        {
            id: 2,
            title: 'On Sale',
        },
        {
            id: 3,
            title: 'Success',
        },
       


        
        

    ])

    return (
        <div>

<ToastContainer
position="bottom-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="dark"
/>


            {
                isMobile?(
                    <>
                     <section className="join">
                <div className="container">
                    <div className="row">
                    <div className="col-xl-8 col-lg-8 col-md-10 col-sm-12 col-xs-12">
                        <div className="row">
                           <div className="col-12 d-inline-flex" ><img src={tokenDetail.coinLogo} alt="coin_logo" width="35px" height="35px" />
                           <h6 className="coin-name">{tokenDetail.coinName}</h6> 
                           <p className="coin-symbol">{tokenDetail.coinSymbol}</p>
                            </div>

                            <div className="col-6">
                            <button className="launchpad-button la-success"><span className="fa fa-circle" style={{fontSize:"13px"}}></span> Upcoming</button>
                             </div>

                            <div className="col-6 mt-4">
                               <p className="launchpad-timer"><span className="fa fa-history"></span> 15 days 14:19:08</p>
                            </div>


                            <div className="align-items-center col-10 d-inline-flex  mt-4">
                                <p style={{width:"250px"}}><b>{`${tokenDetail.coinSold} ${tokenDetail.coinSymbol}`}</b></p>  
                            </div>

                            <div className="col-2 mt-4">
                                <p style={{color:"#0b6efd",fontWeight:"600"}}>0.00%</p>
                            </div>
                            <div className="col-12 mt-3">
                            <ProgressBar now={0.5} striped style={{width:"100%",height:"8px"}} />
                            </div>
                          


]
                             <div className="col-12 row launchpad-deadline">
                                 <div className="col-6">
                                 <p>Started On: 25 Jan 2024 12:30 PM</p>
                                 </div>

                                 <div className="col-6 right">
                                     <p>Ends On: 15 Feb 2024 12:30 PM</p>
                                 </div>
                                
                             </div>


                             <div className="col-12 row launchpad-table">

                               <div className="col-6  la-ta-border" style={{borderBottom:"1px solid #a2a7b2"}}>
                               <p>Price</p>
                                   <p>1 {tokenDetail.coinSymbol}</p>
                                   <p>= {tokenDetail.coinPrice} $</p>
                               </div>

                               <div className="col-6"  style={{borderBottom:"1px solid #a2a7b2"}}>
                               <p>Tokens Amount</p>
                                   <p>{`${tokenDetail.coinAllocation} ${tokenDetail.coinSymbol}`}</p>
                                   <p>= ${tokenDetail.coinPrice * tokenDetail.coinAllocation}</p>
                               </div>

                               <div className="col-6  la-ta-border"  style={{borderBottom:"1px solid #a2a7b2"}}>
                               <p>Min Buy Amount</p>
                                   <p>{`${tokenDetail.coinMin} ${tokenDetail.coinSymbol}`}</p>
                                   <p>= ${tokenDetail.coinPrice * tokenDetail.coinMin}</p>
                               </div>
                            
                               <div className="col-6"  style={{borderBottom:"1px solid #a2a7b2"}}>
                               <p>Total Token Sell</p>
                                   <p>{`${tokenDetail.coinSold} ${tokenDetail.coinSymbol}`}</p>
                                   <p>= ${tokenDetail.coinPrice * tokenDetail.coinSold}</p>
                               </div>

                               <div className="col-6  la-ta-border">
                               <p>Round</p>
                                   <p>1</p>
                                   <p>= {`${tokenDetail.coinAllocation} ${tokenDetail.coinSymbol}`}</p>
                               </div>

                               <div className="col-6">
                               <p>My Tokens</p>
                                   <p>{tokenDetail.coinBalance} {tokenDetail.coinSymbol}</p>
                                   <p>= ${tokenDetail.coinPrice * tokenDetail.coinBalance}</p>
                               </div>

                             </div>


                             <div className="col-12 mt-4">
                                 <p><b>Buy Now {tokenDetail.coinSymbol}</b></p>
                                 <div className="mt-2">
                                 <div className="field2" id="searchform">
                                    <input type="text"   placeholder="Enter USDT Amount" id="usdt_field" onChange={tokenCount}/>
                                    {
                                        islogin?(
                                            <>
                                              <button type="button" id="search" 
                                            //   onClick={buyToken}
                                              >
                                    Starts From 25 Jan
                                        </button>
                                            </>
                                        ):(
                                            <>
                                              <button type="button" id="search">
                                    Login To Buy
                                        </button>
                                            </>
                                        )
                                    }
                                  
                                 </div>

                                 <div className="m-3">
                                 <center> <h5 className="fa fa-exchange" style={{fontSize:"18px",color:"#3574ff"}}></h5></center>
                                 </div>

                                 <div className="field2" id="searchform">
                                    <input type="text" id="calculatedToken"   placeholder={`Calculated ${tokenDetail.coinSymbol}..`} />
                                   
                                 </div>
                                
                                 </div>
                             </div>



                             <div className="col-12 mt-4">
                                 <p>Available Currencies</p>
                                 <div className="mt-2">
                                 <img src="https://s2.coinmarketcap.com/static/img/coins/64x64/825.png" alt="tether" width="30px" /><span> <b>Tether USDT</b></span>
                            
                                 </div>
                             </div>





                        </div>
                   
                    </div>
                    </div>
                </div>

            </section>


                  
           <section className="blog">
           <div className="container">
                    <div className="row">
                    <Tabs selectedTabClassName="tab-design">
              <TabList   className="my-1"  id="tab-basic">
                <Tab>About Project</Tab>
                <Tab>Token Details</Tab>
              </TabList>
              <TabPanel>
              <div className="col-12 mt-4">
                            <h6>About Project</h6>
                            <p>
                            Welcome to the dawn of an unprecedented era where hype meets utility, and meme culture ascends to new heights in the crypto realm. This is not just another NFT project; this is a revolution, a community, a movement. With the launch of our inaugural collection of DRC-20 tokens, we're spearheading the avant-garde of digital assets with tangible, real-world perks.
                            <ul>
                                <li className="p-1"><b>What is Dogi Gamblers Casino?</b> Dogi Gamblers Casino unveils a groundbreaking collection of 3,000 Dogi NFTs, each one a masterful blend of art and opportunity, a tribute to the Pepe and doge meme legacies that have captivated the internet. But these Dogis aren't just for show—they're your all-access pass to participate in the future profits of a fully-fledged casino built on the Dogecoin network.</li>
                                <li className="p-1"><b>Revenue Sharing: Your Slice of the Casino</b> Each Dogi NFT is a powerhouse of utility, engineered to reward its holder with more than just aesthetic pleasure. 
                                With ownership comes the privilege of profit sharing—every NFT grants you a percentage of the casino's revenue. This isn't a one-time payout; it's a continuous flow of earnings, a dividend from the decentralized house of fortune you're helping to build and sustain.</li>
                                <li className="p-1"><b>The Holder's Jackpot</b> magine a casino where the patrons become the house, where the roll of the dice and the flip of a card translate into real earnings in your wallet. That's the revolutionary promise of Dogi Gamblers Casino, where a staggering 60% of all revenue is returned to NFT holders. Your Dogi NFT isn't just a token of membership; it's a share in a profitable enterprise.</li>
                                <li className="p-1"><b>Fee Distribution: The Winning Hand</b> The true game-changer lies in the distribution of the casino's fees. We've designed an economy where the house's success is shared:
                                <ol>
                                    <li><b>60% to Holders:</b> The lion's share of the casino's revenue is distributed back to NFT holders. Holding a Dogi NFT means you're continuously in the game, earning a share of the action.</li>

                                    <li><b>30% to Casino Pools:</b> To keep the casino's thrills fresh and the jackpots juicy, 15% of the fees are reinvested into the casino's prize pools allowing more degens to come in.</li>

                                    <li><b>10% to Project Treasury:</b> A modest but crucial 5% is allocated to the project treasury, supporting ongoing development, operational costs, marketing and future growth initiatives. This ensures that Dogi Gamblers Casino isn't just a one-off event but a lasting enterprise.</li>
                                   
                                </ol>
                                </li>
                               
                            </ul>

                            </p>
{/* 
                            <h6 className="mt-4">Token Distribution</h6>
                            <Chart
        chartType="PieChart"
        data={data}
        options={{
            is3D: true,
            backgroundColor: 'transparent',
            tooltip: { isHtml: true },
            legend: {
                alignment: 'center',
                textStyle: {
                    fontSize: 12,
                    color: 'gray',
                 
                  },
              },
    
          }}
        width="100%"
        height="300px"
        legendToggle
      /> */}
                        </div>
              </TabPanel>
              <TabPanel>
                    
                        <div className="col-12 row mt-4">
                        <h6 className="fs-13">Project Details</h6>
                            <div className="col-12 launchpad-port m-2">
                                <div className="d-flex row">
                                <p className="col-4">Official Site</p>
                               <div className="col-8" > <a href="https://dogigamblers.com" style={{fontSize:"13px",fontWeight:"normal"}}>https://dogigamblers.com</a></div>
                                </div>
                                <hr/>
                                <div className="d-flex row">
                                <p className="col-4">Social Profile</p>
                                <div className="col-7" > 
                                <a href="https://twitter.com/dogigamblers" target="_blank"> <span className="fa-brands fa-twitter p-2"></span></a>
                                <a href="https://t.me/DogiGamblers" target="_blank"><span className="fa-brands fa-telegram p-2"></span></a>
                               </div>
                                </div>
                               
                                
                               
                            </div>


                           
                            <div className="col-12 launchpad-port mt-4">
                                <h6 style={{fontSize:"17px"}}>Documentation</h6>
                                <hr/>
                             <a href="https://dogi-gamblers-casino.gitbook.io"  target="_blank">   <p>  <span className="fa fa-file p-2"></span> <a href="#" className="fs-14">Whitepaper</a></p></a>
                           </div>




                           <div className="col-12 launchpad-port1 m-2">
                                <h6 style={{fontSize:"17px"}}>Token Sale and Economics</h6>


                                <hr/>
                                 <div className="d-flex row">
                                 <p className="col-5">Token name</p>
                                <div className="col-7" style={{textAlign:"right"}}> <p>{tokenDetail.coinName}</p></div>
                                </div>

                                <hr/>
                                 <div className="d-flex row">
                                 <p className="col-5">Token Price</p>
                                <div className="col-7" style={{textAlign:"right"}}> <p>${tokenDetail.coinPrice}</p></div>
                                </div>

                                <hr/>
                                 <div className="d-flex row">
                                 <p className="col-5">Token Type</p>
                                <div className="col-7" style={{textAlign:"right"}}> <p>DRC20</p></div>
                                </div>

                                <hr/>
                                 <div className="d-flex row">
                                 <p className="col-5">Total Supply</p>
                                <div className="col-7" style={{textAlign:"right"}}> <p>{`${tokenDetail.coinSupply} ${tokenDetail.coinSymbol}`}</p></div>
                                </div>

                                <hr/>
                                 <div className="d-flex row">
                                 <p className="col-5">Launchpad Allocation</p>
                                <div className="col-7" style={{textAlign:"right"}}> <p>{`${tokenDetail.coinAllocation} ${tokenDetail.coinSymbol}`}</p></div>
                                </div>

                           </div>

                           


                            
                        </div>
              </TabPanel>
              
            </Tabs>
                        


                        </div>
             </div>

           </section>
                    </>
                ):(
                    <>
                       <section className="join">
                <div className="container">
                    <div className="row">
                    <div className="col-xl-8 col-lg-8 col-md-10 col-sm-12 col-xs-12">
                        <div className="row">
                           <div className="col-6 d-inline-flex" ><img src={tokenDetail.coinLogo} alt="coin_logo" width="35px" height="35px" />
                           <h6 className="coin-name">{tokenDetail.coinName}</h6> 
                           <p className="coin-symbol">{tokenDetail.coinSymbol}</p>
                            </div>
                            <div className="col-6">
                               <p className="launchpad-timer"><span className="fa fa-history"></span> 7 Days 12:00 PM</p>
                            </div>
                            <div className="align-items-center col-10 d-inline-flex  mt-4">
                                <p style={{width:"250px"}}><b>{tokenDetail.coinSold} {tokenDetail.coinSymbol}</b></p>
                                <ProgressBar now={0.5} striped style={{width:"100%",height:"8px"}} />
                            </div>

                            <div className="col-2 mt-4">
                                <p style={{color:"#0b6efd",fontWeight:"600"}}>0.00%</p>
                            </div>

                            <div className="col-3">
                            <button className="launchpad-button la-success"><span className="fa fa-circle" style={{fontSize:"13px"}}></span> Upcoming</button>
                             </div>

                             <div className="col-9 row launchpad-deadline">
                                 <div className="col-6 right">
                                 <p>Started On: 13 Sept 2023 12:00 UTC</p>
                                 </div>

                                 <div className="col-6 right">
                                     <p>Ends On: 20 Sept 2023 12:30 UTC</p>
                                 </div>
                                
                             </div>


                             <div className="col-11 row launchpad-table">

                               <div className="col-4  la-ta-border">
                                   <p>Price</p>
                                   <p>1 {tokenDetail.coinSymbol}</p>
                                   <p>= {tokenDetail.coinPrice} $</p>
                               </div>

                               <div className="col-4  la-ta-border">
                                   <p>Tokens Amount</p>
                                   <p>{`${tokenDetail.coinAllocation} ${tokenDetail.coinSymbol}`}</p>
                                   <p>= ${tokenDetail.coinPrice * tokenDetail.coinAllocation}</p>
                               </div>

                               <div className="col-4">
                                   <p>Min Buy Amount</p>
                                   <p>{`${tokenDetail.coinMin} ${tokenDetail.coinSymbol}`}</p>
                                   <p>= ${tokenDetail.coinPrice * tokenDetail.coinMin}</p>
                               </div>
                            
                               <div className="col-4 la-ta-border">
                                   <p>Total Token Sell</p>
                                   <p>{`${tokenDetail.coinSold} ${tokenDetail.coinSymbol}`}</p>
                                   <p>= ${tokenDetail.coinPrice * tokenDetail.coinSold}</p>
                               </div>

                               <div className="col-4  la-ta-border">
                                   <p>Round</p>
                                   <p>1</p>
                                   <p>= {`${tokenDetail.coinAllocation} ${tokenDetail.coinSymbol}`}</p>
                               </div>

                               <div className="col-4">
                                   <p>My Tokens</p>
                                   <p>0 {tokenDetail.coinSymbol}</p>
                                   <p>= 0.00 $</p>
                               </div>

                             </div>


                             <div className="col-5" style={{marginTop:"30px"}}>
                                 <p><b>Buy Now {tokenDetail.coinSymbol}</b></p>
                                 <div className="mt-2">
                                 <div className="field2" id="searchform">
                                    <input type="text"   placeholder="Enter USDT Amount" id="usdt_field" onChange={tokenCount}/>
                                    {
                                        islogin?(
                                            <>
                                              <button type="button" id="search"
                                            //    onClick={buyToken}
                                               >
                                              Starts From 25 Jan
                                        </button>
                                            </>
                                        ):(
                                            <>
                                              <button type="button" id="search">
                                    Login To Buy
                                        </button>
                                            </>
                                        )
                                    }
                                  
                                 </div>
                                 <br/>

                                 <div className="m-3">
                             <center>  <h5 className="fa fa-exchange" style={{fontSize:"18px",color:"#3574ff"}}></h5></center>
                                 </div>

                                 <div className="field2" id="searchform">
                                    <input type="text" id="calculatedToken"   placeholder="Calculated GAMB.." />
                                   
                                 </div>
                                
                                 </div>
                             </div>

                             
                             <div className="col-12 mt-4">
                                 <p>Available Currencies</p>
                                 <div className="mt-2">
                                 <img src="https://s2.coinmarketcap.com/static/img/coins/64x64/825.png" alt="tether" width="30px" /><span> <b>Tether USDT</b></span>
                            
                                 </div>
                             </div>

                        </div>
                   
                    </div>
                    </div>
                </div>

            </section>


                  
           <section className="blog">
           <div className="container">
                    <div className="row">
                        <div className="col-8 p-4">
                            <h6>About Project</h6>
                            <p>
                            Welcome to the dawn of an unprecedented era where hype meets utility, and meme culture ascends to new heights in the crypto realm. This is not just another NFT project; this is a revolution, a community, a movement. With the launch of our inaugural collection of DRC-20 tokens, we're spearheading the avant-garde of digital assets with tangible, real-world perks.
                            <ul>
                                <li className="p-1"><b>What is Dogi Gamblers Casino?</b> Dogi Gamblers Casino unveils a groundbreaking collection of 3,000 Dogi NFTs, each one a masterful blend of art and opportunity, a tribute to the Pepe and doge meme legacies that have captivated the internet. But these Dogis aren't just for show—they're your all-access pass to participate in the future profits of a fully-fledged casino built on the Dogecoin network.</li>
                                <li className="p-1"><b>Revenue Sharing: Your Slice of the Casino</b> Each Dogi NFT is a powerhouse of utility, engineered to reward its holder with more than just aesthetic pleasure. 
With ownership comes the privilege of profit sharing—every NFT grants you a percentage of the casino's revenue. This isn't a one-time payout; it's a continuous flow of earnings, a dividend from the decentralized house of fortune you're helping to build and sustain.</li>
                                <li className="p-1"><b>The Holder's Jackpot</b> magine a casino where the patrons become the house, where the roll of the dice and the flip of a card translate into real earnings in your wallet. That's the revolutionary promise of Dogi Gamblers Casino, where a staggering 60% of all revenue is returned to NFT holders. Your Dogi NFT isn't just a token of membership; it's a share in a profitable enterprise.</li>
                                <li className="p-1"><b>Fee Distribution: The Winning Hand</b> The true game-changer lies in the distribution of the casino's fees. We've designed an economy where the house's success is shared:
                                <ol>
                                    <li><b>60% to Holders:</b> The lion's share of the casino's revenue is distributed back to NFT holders. Holding a Dogi NFT means you're continuously in the game, earning a share of the action.</li>

                                    <li><b>30% to Casino Pools:</b> To keep the casino's thrills fresh and the jackpots juicy, 15% of the fees are reinvested into the casino's prize pools allowing more degens to come in.</li>

                                    <li><b>10% to Project Treasury:</b> A modest but crucial 5% is allocated to the project treasury, supporting ongoing development, operational costs, marketing and future growth initiatives. This ensures that Dogi Gamblers Casino isn't just a one-off event but a lasting enterprise.</li>
                                   
                                </ol>
                                </li>
                               
                            </ul>

                            </p>

                            {/* <h6 className="mt-4">Token Distribution</h6>
                            <Chart
        chartType="PieChart"
        data={data}
        options={{
            is3D: true,
            backgroundColor: 'transparent',
            tooltip: { isHtml: true },
            legend: {
                alignment: 'center',
                textStyle: {
                    fontSize: 12,
                    color: 'gray',
                 
                  },
              },
    
          }}
        width="100%"
        height="300px"
        legendToggle
      /> */}
                        </div>
                        <div className="col-4 row p-4">
                        <div className="col-12 launchpad-port m-2">
                                <div className="d-flex row">
                                <p className="col-4">Official Site</p>
                               <div className="col-8" > <a href="https://dogigamblers.com" style={{fontSize:"13px",fontWeight:"normal"}}>https://dogigamblers.com</a></div>
                                </div>
                                <hr/>
                                <div className="d-flex row">
                                <p className="col-4">Social Profile</p>
                                <div className="col-7" > 
                                <a href="https://twitter.com/dogigamblers" target="_blank"> <span className="fa-brands fa-twitter p-2"></span></a>
                                <a href="https://t.me/DogiGamblers" target="_blank"><span className="fa-brands fa-telegram p-2"></span></a>
                               </div>
                                </div>
                               
                                
                               
                            </div>


                           
                            <div className="col-12 launchpad-port mt-4">
                                <h6 style={{fontSize:"17px"}}>Documentation</h6>
                                <hr/>
                             <a href="https://dogi-gamblers-casino.gitbook.io"  target="_blank">   <p>  <span className="fa fa-file p-2"></span> <a href="#" className="fs-14">Whitepaper</a></p></a>
                           </div>




                           <div className="col-12 launchpad-port1 m-2">
                                <h6 style={{fontSize:"17px"}}>Token Sale and Economics</h6>


                                <hr/>
                                 <div className="d-flex row">
                                 <p className="col-5">Token name</p>
                                <div className="col-7" style={{textAlign:"right"}}> <p>{tokenDetail.coinName}</p></div>
                                </div>

                                <hr/>
                                 <div className="d-flex row">
                                 <p className="col-5">Token Price</p>
                                <div className="col-7" style={{textAlign:"right"}}> <p>${tokenDetail.coinPrice}</p></div>
                                </div>

                                <hr/>
                                 <div className="d-flex row">
                                 <p className="col-5">Token Type</p>
                                <div className="col-7" style={{textAlign:"right"}}> <p>DRC20</p></div>
                                </div>

                                <hr/>
                                 <div className="d-flex row">
                                 <p className="col-5">Total Supply</p>
                                <div className="col-7" style={{textAlign:"right"}}> <p>{`${tokenDetail.coinSupply} ${tokenDetail.coinSymbol}`}</p></div>
                                </div>

                                <hr/>
                                 <div className="d-flex row">
                                 <p className="col-5">Launchpad Allocation</p>
                                <div className="col-7" style={{textAlign:"right"}}> <p>{`${tokenDetail.coinAllocation} ${tokenDetail.coinSymbol}`}</p></div>
                                </div>

                           </div>

                           
                           


                            
                        </div>


                        </div>
             </div>

           </section>
                    </>
                )
            }

           

         
     

           <Sale01 />

         
            
        </div>
    );
}

export default LaunchPad2;