import React from 'react';
import Sale01 from '../components/sale/Sale01';
import { Accordion } from 'react-bootstrap-accordion';
import PageTitle from '../components/pagetitle';
import { Helmet } from 'react-helmet';

Faq.propTypes = {
    
};

function Faq(props) {

    const dataAbout = [
        {
            id: 1,
            title: 'What is a CetoEX exchange?',
            text: 'CetoEX crypto exchange is a platform that allows users to buy, sell, and trade cryptocurrencies. It serves as a marketplace where individuals can exchange one cryptocurrency for another or convert cryptocurrencies into another currencies (such as USDT, ETH, etc.) and vice versa.',
            show: 'show'
        },
        {
            id: 2,
            title: 'How do I create an account on a CetoEX exchange?',
            text: 'To create an account on a crypto exchange, you typically need to visit the exchange website and sign up by providing your personal information, such as your name, email address, and sometimes identity verification documents. You may also need to set up two-factor authentication (2FA) for added security.',
        },
        {
            id: 3,
            title: 'What cryptocurrencies can I trade on a crypto exchange?',
            text: 'The availability of cryptocurrencies for trading varies depending on the exchange. Popular exchanges usually support major cryptocurrencies like Bitcoin (BTC), Ethereum (ETH), Litecoin (LTC), and Bitcoin Cash (BCH). Some exchanges offer a wide range of altcoins, while others have a more limited selection. ',
        },
        {
            id: 4,
            title: 'How do I deposit funds into my exchange account?',
            text: 'Once your account is set up, you can deposit funds by selecting the "Deposit" or "Funds" section on the exchanges platform. Each exchange provides specific instructions on how to deposit funds, which may involve generating a unique wallet address for the specific cryptocurrency you want to deposit. You can then transfer funds from your personal wallet or another exchange to the provided address.',
        },
        {
            id: 5,
            title: 'How do I place a trade on a crypto exchange?',
            text: 'To place a trade, you typically need to select the trading pair you want to trade (e.g., BTC/ETH) and choose between market orders (buying or selling at the current market price) or limit orders (setting your desired price). Enter the amount you want to trade and confirm the transaction. The exchange will execute the trade based on your instructions.',
        },
        {
            id: 6,
            title: 'What are trading fees on crypto exchanges?',
            text: 'Crypto exchanges charge fees for executing trades on their platforms. These fees can vary and are usually a percentage of the trade amount or a fixed fee per transaction. Exchanges may also charge additional fees for depositing or withdrawing funds from your account. It is important to review the fee structure of an exchange before trading.',
        },
        {
            id: 7,
            title: 'How do I secure my funds on a crypto exchange?',
            text: 'To enhance the security of your funds, use strong, unique passwords and enable two-factor authentication (2FA). Additionally, consider using a hardware wallet to store your cryptocurrencies offline for added protection against hacks and cyber theft.',
        },
        {
            id: 8,
            title: 'Can I withdraw my funds from a crypto exchange?',
            text: 'Yes, most exchanges allow you to withdraw your funds. Withdrawal options usually include transferring cryptocurrencies to your personal wallet or withdrawing fiat currency to your bank account. Each exchange has its own withdrawal procedures, including verification processes and withdrawal fees.',
        },
        {
            id: 9,
            title: 'How is Buy & Sell Works',
            text: <ol>
                <li><b>Placement of the order:</b> A trader places a buy or sell order in the order book from CetoEX exchange. The order includes details such as the type of order (market order or limit order), the quantity of the asset to be bought or sold, and the desired price.</li>
                <li><b>Matching process:</b> The CetoEX Exchange matching engine compares the new order with existing orders in the order book to find a match. For example, if a buy order is placed at a specific price and there is a sell order in the order book at the same or lower price, a match is found.</li>
                <li><b>Order execution:</b> Once a match is found, the buy and sell orders are considered "executed." The CetoEX platform facilitates the transfer of the asset from the seller to the buyer and the corresponding funds from the buyer to the seller. This process may involve updating account balances and verifying transaction details.</li>
                <li><b>Order status updates:</b> After the execution, the order status is updated accordingly. The executed buy and sell orders may be partially filled if the quantity in the matching order is not equal to the original order's quantity. The remaining quantity is either canceled (in the case of a limit order) or kept in the order book as an open order.</li>
                <li><b>Trade confirmation:</b> Both the buyer and the seller receive a trade confirmation, which serves as proof of the executed transaction. This confirmation typically includes details such as the transaction price, quantity, fees, and other relevant information.</li>
            </ol>,
        }
        ,
        {
            id: 10,
            title: 'What is the difference between centralized and decentralized exchanges?',
            text: 'Centralized exchanges (CEX) are traditional platforms where users deposit funds into the exchanges wallet, and the exchange acts as an intermediary for trades. Decentralized exchanges (DEX) operate on blockchain technology, allowing users to trade directly from their wallets without the need for an intermediary. DEXs provide more control over funds but may have lower liquidity and limited trading pairs.',
        }
    
       
    ]


    return (
        <div>

            <PageTitle heading='FAQ' title='FAQ' />
                                                           
<Helmet>
        <title>Answers to Your Questions | Cetoex</title>
        <meta name="description" content="Find Comprehensive Answers to Common Queries about Cryptocurrency Trading and Cetoex. Your Go-To Resource for Clear and Informative Guidance."/>
        <meta name="keywords" content="crypto market, crypto deposit history, cetoex, exchange, top ranking coin, top volume, newly listed, top gainers, top losers, cetoex exchange, spot, trading, crypto"/>
        <meta name="author" content="Cetoex"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        
        {/* Open Graph (OG) tags for sharing on social media */}
        <meta property="og:title" content="Answers to Your Questions | Cetoex"/>
        <meta property="og:description" content="Find Comprehensive Answers to Common Queries about Cryptocurrency Trading and Cetoex. Your Go-To Resource for Clear and Informative Guidance."/>
        
        {/* Twitter card tags for sharing on Twitter */}
        <meta name="twitter:card" content="cetoex"/>
        <meta name="twitter:title" content="Answers to Your Questions | Cetoex"/>
        <meta name="twitter:description" content="Find Comprehensive Answers to Common Queries about Cryptocurrency Trading and Cetoex. Your Go-To Resource for Clear and Informative Guidance."/>
       
      </Helmet>

            <section className="faq">
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <div className="block-text center">
                    <h3 className="heading">Frequently Asked Questions</h3>
                    <p className="desc fs-20">Learn how to get started</p>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="flat-accordion">
                        {
                            dataAbout.map(idx => (
                                <Accordion key={idx.id} title={idx.title} show={idx.show}>
                                    <p className="toggle-content">{idx.text}</p>
                                   
                                </Accordion>
                            ))
                        }
                    </div>
                </div>
                </div>
            </div>
            </section>

            <Sale01 />
            
        </div>
    );
}

export default Faq;