import React , {useState} from 'react';
import { useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import axios from 'axios';
import Cookies1 from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImageLoader from "../loader/loader";

function Sell(prop1) {
  
  const [sellList, setSellList] = useState({
    balance:0,
    price:0
  });
 
  const[coinPrice,setCoinPrice]= useState('');
  const [isInputDisabled, setIsInputDisabled] = useState(true);
  const[loader,setLoader] = useState(false);
  const [login,setLogin] = useState(true);
   

  useEffect(() => {
    const token = Cookies1.get('LoginToken');
     
    if(token != null)
    {
      fetchMyTable()
    }
    else{
      setLogin(false)
    }
   
  }, [])



async function fetchMyTable() {
  const accountUserId = Cookies1.get('investorId');
  var data = '';

var config = {
  method: 'get',
  url: `https://test.cetoex.org/api/sellDetails?investorId=${accountUserId}&APIKEY=494093&Coin=${prop1.data}`,
  headers: { },
  data : data
};

axios(config)
.then(function (response) {
  setSellList({balance:JSON.stringify(response.data.Balance),price:JSON.stringify(response.data.last_price)});
  setCoinPrice(response.data.last_price)
})
.catch(function (error) {
  console.log(error);
});

     
}



const sellCoin =  async ()=>{

  const accountUserId = Cookies1.get('investorId');
  const investVal = document.getElementById('sell_amount').value;
  const sellType = document.getElementById('sellType').value;
  setLoader(true)
  var data = JSON.stringify({
    "investorId": accountUserId,
    "APIKey": "392thfwfj9if",
    "Coin":  prop1.data,
    "Value": investVal,
    "Order": sellType,
    "bidPrice":coinPrice
  });


  
  var config = {
    method: 'post',
    url: 'https://test.cetoex.org/api/sellCrypto',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };
  
  axios(config)
  .then(function (response) {
    if(response.data.status === "Success")
    {

      fetchMyTable()

      toast.success(response.data.message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });


     
    }
    else{
      toast.error(response.data.status, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
    }

    setLoader(false)
   
  })
  .catch(function (error) {
    console.log(error);
  });


}

const handleSelectChange = (event) => {
  if(event.target.value === "market")
  {
    setIsInputDisabled(true)
    setCoinPrice(sellList.price)

    const coinAmount =  document.getElementById('sell_amount').value;
  
    document.getElementById('sell_count').value = (coinAmount * sellList.price).toFixed(3)


  }
  else{
    setIsInputDisabled(false)
  }
 
};

const handleInputChange = (event) => {
  setCoinPrice(event.target.value);
 
  const coinAmount =  document.getElementById('sell_amount').value;
  document.getElementById('sell_count').value = (coinAmount * event.target.value).toFixed(3)



};


const setCoin1 = (coinPer)=>{
   
  const usdtAmount =  sellList.balance * coinPer/100
  document.getElementById('sell_amount').value = (usdtAmount).toFixed(7)
  document.getElementById('sell_count').value = (usdtAmount * coinPrice).toFixed(2)
 }


 const updateUSDT1 = ()=>{
  const coinAmount =  document.getElementById('sell_amount').value;

  document.getElementById('sell_count').value = (coinAmount * coinPrice).toFixed(2)

   }

   const updateamount1 = ()=>{
     const coinAmount =  document.getElementById('sell_count').value;
     document.getElementById('sell_amount').value = (coinAmount / coinPrice).toFixed(6)
   }
 

  return (
    <>


        
  
{
    loader && <ImageLoader/>
}

    
                       <div className="flat-tabs">
                      
  <div className="table-main"  style={{border:"1px solid #eee"}}>   
  <Tabs defaultIndex={0} selectedTabClassName="tab-design1">
    <TabList  className="my-0"  id="tab-basic">
      <Tab>
       Sell {prop1.data}
     </Tab>
     
   
    </TabList>

    <TabPanel>


       
    <ToastContainer
position="bottom-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="dark"
/>
   
    <form className="form-buy">
    <div className="col-12" style={{textAlign:"left",fontWeight:"500",marginBottom:"9px",fontSize:"13px"}}>
      Balance : {sellList.balance}  {prop1.data}
     </div>

 
  <div className="input-container">
    <i className="fa fa-book icon" />
    <select id="sellType"
      className="input-field forminput" style={{fontSize:"11px",fontWeight:"bold"}}
      onChange={handleSelectChange}
    >
       <option value="market" selected="">
        Market
      </option>
      <option value="limit">Limit</option>
     
    </select>
  </div>
  <div className="input-container" id="coin_input">
    <i className="fa fa-minus icon" />
    <input
      className="input-field forminput"
      type="text"

      value={coinPrice}  
      placeholder="Price"  onChange={handleInputChange}
      disabled={isInputDisabled}
    
    />
    <i className="fa fa-plus icon" />
  </div>

  <div className="input-container">
    <i className="fa fa-minus icon" />
    <input
      className="input-field forminput"
      type="text"
      placeholder={`Enter ${prop1.data} Amount`}
      id="sell_amount"
      onKeyUp={updateUSDT1}
      style={{ fontWeight: "bold" }}
    />
    <i className="fa fa-plus icon" />
  </div>
  <button type="button" className="btn3" onClick={(e)=>setCoin1(25)}>
    25%
  </button>
  <button type="button" className="btn3" onClick={(e)=>setCoin1(50)}>
    50%
  </button>
  <button type="button" className="btn3" onClick={(e)=>setCoin1(75)}>
    75%
  </button>
  <button type="button" className="btn3" onClick={(e)=>setCoin1(100)}>
    100%
  </button>
  <div className="input-container" style={{ marginTop: 10 }}>
    <input
      className="input-field forminput"
      type="text"
      placeholder="Total (USDT)"
      id="sell_count"
      onKeyUp={updateamount1}
      style={{ fontWeight: "bold" }}
    />
  </div>
  
  {
   login ? (
   <>
    <button type="button" className="btn1" onClick={sellCoin}>
    SELL {prop1.data}
  </button>
   </>
   ):(
     <>
     <a  href="/login" type="button" style={{backgroundColor:"transparent",width:"100%",padding:"5px",fontWeight:"bold",border:"1.5px solid #d33535",color:"#d33535",textAlign:"center"}}>
   Login To Sell <span className="fa fa-sign-in"></span>
  </a>
     </>
   )
   
 }
 
</form>

    </TabPanel>


 
    
    </Tabs>
                   
 </div>
</div>
  
                  
  
    </>
  );
}

export default Sell;
