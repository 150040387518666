import React, {useState}  from 'react';
import Sale01 from '../components/sale/Sale01';
import ReCAPTCHA from "react-google-recaptcha";
import axios  from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImageLoader from "../components/loader/loader";
import { Helmet } from 'react-helmet';
const recaptchaRef = React.createRef();


function ListingForm(props) {
    const [show, setShow] = useState(1);
    const[loader,setLoader] = useState(false);


   const ListingCoin = ()=>{

  


    const name = document.getElementById('owner_name').value;
    const mail = document.getElementById('email_address').value;
    const coin = document.getElementById('coin_name').value;
    const symbol  = document.getElementById('coin_symbol').value;
    const supply = document.getElementById('coin_supply').value;
    const blockchain = document.getElementById('blockchain').value;
    const contract = document.getElementById('contract_address').value;
    const website = document.getElementById('website').value;
    const twitter = document.getElementById('twiiter').value;
    const logo = document.getElementById('coin_logo').value;
    const telegram = document.getElementById('offical_telegram').value;
    const recaptchaValue = recaptchaRef.current.getValue();


  


    if(name === "")
    {
        document.getElementById('nameerror').style.color = "#d9534f";
        return false;
    }
    else{
        document.getElementById('nameerror').style.color = "#787f90";
    }

    if(mail === "")
    {
        document.getElementById('mailerror').style.color = "#d9534f";
        return false;
    }
    else{
        document.getElementById('mailerror').style.color = "#787f90";
    }

    if(coin === "")
    {
        document.getElementById('coinerror').style.color = "#d9534f";
        return false;
    }
    else{
        document.getElementById('coinerror').style.color = "#787f90";
    }

    if(symbol === "")
    {
        document.getElementById('symbolerror').style.color = "#d9534f";
        return false;
    }
    else{
        document.getElementById('symbolerror').style.color = "#787f90";
    }

    if(supply === "")
    {
        document.getElementById('supplyerror').style.color = "#d9534f";
        return false;
    }
    else{
        document.getElementById('supplyerror').style.color = "#787f90";
    }

    if(blockchain === "")
    {
        document.getElementById('blockchainerror').style.color = "#d9534f";
        return false;
    }
    else{
        document.getElementById('blockchainerror').style.color = "#787f90";
    }


    if(contract === "")
    {
        document.getElementById('contracterror').style.color = "#d9534f";
        return false;
    }
    else{
        document.getElementById('contracterror').style.color = "#787f90";
    }


    if(website === "")
    {
        document.getElementById('websiteerror').style.color = "#d9534f";
        return false;
    }
    else{
        document.getElementById('websiteerror').style.color = "#787f90";
    }

    if(twitter === "")
    {
        document.getElementById('twittererror').style.color = "#d9534f";
        return false;
    }
    else{
        document.getElementById('twittererror').style.color = "#787f90";
    }


    if(logo === "")
    {
        document.getElementById('logoerror').style.color = "#d9534f";
        return false;
    }
    else{
        document.getElementById('logoerror').style.color = "#787f90";
    }

    if(telegram === "")
    {
        document.getElementById('telegramerror').style.color = "#d9534f";
        return false;
    }

    else{
        document.getElementById('telegramerror').style.color = "#787f90";
    }



    if(recaptchaValue === "")
    {
        toast.error("Check Recapacha", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
    }
    else{

        setLoader(true)
        var data = JSON.stringify({
            "ownername": name,
            "owneremail": mail,
            "coin_name": coin,
            "coin_symbol": symbol,
            "coin_supply": supply,
            "coin_blockchain":blockchain,
            "coin_address": contract,
            "website": website,
            "twitter": twitter,
            "logo": logo,
            "telegram": telegram
          });
          
          var config = {
            method: 'post',
            url: 'https://test.cetoex.org/api/listingform',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
             if(response.data.status === "success")
             {
                toast.success("Form submitted for coin listing.", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });

                  setShow(2);
             }
             else{
                toast.error(response.data.message, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
             }

             setLoader(false)
          })
          .catch(function (error) {
            toast.error(error, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
          });
          

    }
   
   }

    return (
        <div>

            {/* <PageTitle heading='FAQ' title='FAQ' /> */}


            <ToastContainer
position="bottom-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="dark"
/>


{
    loader && <ImageLoader/>
}

            <section className="faq">

                                    
<Helmet>
        <title>Listing Form | Add Your Coin | Cetoex</title>
        <meta name="description" content="Introduce Your Crypto Project to a Global Audience. Use the [Your Crypto Exchange Name] Coin Listing Form to Apply for Exposure and Trading Opportunities."/>
        <meta name="keywords" content="crypto market, crypto deposit history, cetoex, exchange, top ranking coin, top volume, newly listed, top gainers, top losers, cetoex exchange, spot, trading, crypto"/>
        <meta name="author" content="Cetoex"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        
        {/* Open Graph (OG) tags for sharing on social media */}
        <meta property="og:title" content="Listing Form | Add Your Coin | Cetoex"/>
        <meta property="og:description" content="Introduce Your Crypto Project to a Global Audience. Use the [Your Crypto Exchange Name] Coin Listing Form to Apply for Exposure and Trading Opportunities."/>
        
        {/* Twitter card tags for sharing on Twitter */}
        <meta name="twitter:card" content="cetoex"/>
        <meta name="twitter:title" content="Listing Form | Add Your Coin | Cetoex"/>
        <meta name="twitter:description" content="Introduce Your Crypto Project to a Global Audience. Use the [Your Crypto Exchange Name] Coin Listing Form to Apply for Exposure and Trading Opportunities."/>
        <meta name="twitter:image" content="https://pbs.twimg.com/profile_images/1556224061248008192/KQTT5Qk3_400x400.jpg"/>
        
      </Helmet>


            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <div className="block-text center">
                    <h3 className="heading">CetoEX Listing Form</h3>
                    <p className="desc fs-20">Fill this form with correct information of your Token/Coin.</p><br/>
                    <center> <a href="https://telegram.me/AbdulRahman_786" className="btn-action"> <span className="fa fa-paper-plane"></span>&nbsp;&nbsp;Telegram Message For Listing</a></center>

                    </div>
                </div>

                {

                show === 1 && (
                <div className="col-md-12">
             
                <div className="main">
                   
                     <h6>Apply For Listing</h6>
                     <p>Our listing representatives will contact your soon after this form submission.</p><br/>
                    
                          <h6 style={{fontSize:"15px"}}>Contact Information*</h6>
                         
                          
                            <form action="#">
                                <div className="row">
                               


                            <div className="form-group col-6">
                            <div>
                                <label  id="nameerror">Owner Name<span style={{color:"red"}}>*</span></label>
                                <input  type="text"  className="form-control" id="owner_name" />
                               <br/>
                                </div>
                                </div>


                                
                            <div className="form-group col-6">
                            <div>
                                <label id="mailerror">Owner's Telegram<span style={{color:"red"}}>*</span></label>
                                <input type="mail"  className="form-control"  id="email_address" /> <br/>
                                </div>
                                </div>

                                <hr/>
                                <h6 style={{fontSize:"15px"}}>Coin Profile*</h6>
                               
                                <div className="form-group col-6">
                            <div>
                                <label id="coinerror">Coin Name<span style={{color:"red"}}>*</span></label>
                                <input type="text"
                                    className="form-control"
                                   id="coin_name" 
                                /> <br/>
                                </div>
                                </div>


                                
                            <div className="form-group col-6">
                            <div>
                                <label id="symbolerror">Coin Symbol<span style={{color:"red"}}>*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                   
                                    id="coin_symbol"
                                /> <br/>
                               </div>
                                </div>


                                        
                            <div className="form-group col-6">
                            <div>
                                <label id="supplyerror">Coin Supply<span style={{color:"red"}}>*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                   
                                    id="coin_supply"
                                /> <br/>
                               </div>
                                </div>


                                <div className="form-group col-6">
                            <div>
                                <label id="blockchainerror">Blockchain<span style={{color:"red"}}>*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                   
                                    id="blockchain"
                                /> <br/>
                                </div>
                                </div>


                                        
                            <div className="form-group col-12">
                            <div>
                                <label id="contracterror">Contract Address<span style={{color:"red"}}>*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                   
                                    id="contract_address"
                                /> <br/>
                                </div>
                                </div>


                                  <hr/>
                                  <h6 style={{fontSize:"15px"}}>Social Profile*</h6>
                                   
                                <div className="form-group col-6">
                               <div>
                                <label id="websiteerror">Offical Website<span style={{color:"red"}}>*</span></label>
                                <input 
                                    type="text"
                                    className="form-control"
                                   id="website" 
                                /> <br/>
                                </div>
                                </div>


                                <div className="form-group col-6">
                            <div>
                                <label id="twittererror">Offical Twitter<span style={{color:"red"}}>*</span></label>
                                <input 
                                    type="text"
                                    className="form-control"
                                   id="twiiter" 
                                /> <br/>
                                </div>
                                </div>

                                 
                                <div className="form-group col-6">
                            <div>
                                <label id="logoerror">Coin Logo Link<span style={{color:"red"}}>*</span></label>
                                <input 
                                    type="text"
                                    className="form-control"
                                   id="coin_logo" 
                                /> <br/>
                               </div>
                                </div>



                                <div className="form-group col-6">
                            <div>
                                <label id="telegramerror">Offical Telegram<span style={{color:"red"}}>*</span></label>
                                <input 
                                    type="text"
                                    className="form-control"
                                   id="offical_telegram" 
                                /> <br/>
                                </div>
                                </div><br/>
                                <ReCAPTCHA  ref={recaptchaRef}  sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"/>
                          
                                    </div>
                                   
                            </form>
                     <center> <a className="btn-action m-5" onClick={ListingCoin}>Send List Request</a> </center>
                            </div>


                </div>
                    )
                    }

                    {
                          show === 2 && (
                         <div className="col-md-12">
             
                         <div className="main">
                           <p style={{color:"#0380fd",fontSize:"30px",textAlign:"center"}}><span className="fa fa-check-circle"></span></p>
                           <p style={{color:"#0380fd",fontSize:"25px",textAlign:"center",fontWeight:"bold",marginTop:"10px"}}>Listing Form Submitted</p>
                           <p style={{fontSize:"18px",textAlign:"center",marginTop:"7px"}}>We get back as soon as possible</p>
                         </div>
                         </div>
                          )
                    }
                </div>
            </div>
            </section>

            <Sale01 />
            
        </div>
    );
}

export default ListingForm;