import React , {useState} from 'react';
import { useEffect } from 'react';
import {Link} from 'react-router-dom';
import Crypto03 from '../crypto/Crypto03';
import axios from 'axios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import MarketPagation from '../../pages/Marketpagination';
import { Helmet } from 'react-helmet';


function TopGainers(props) {

   

    const [userList, setUserList] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
   

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
      };
    
      const filteredData = userList.filter((item) =>
        item.coin_symbol.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.coin_name.toLowerCase().includes(searchQuery.toLowerCase())
      );



      useEffect(() => {
        const socket = new WebSocket('wss://websocket.cetoex.com:8080/market');
    
        socket.onopen = () => {
          console.log('WebSocket connection established.');
        };
    
        socket.onmessage = (event) => {
            const receivedData = JSON.parse(event.data);
            const sortedData = [...receivedData].sort((a, b) => b.coin_pricechange - a.coin_pricechange);
            const GainerData = sortedData.slice(0, 10);
            setUserList(GainerData);
        };
    
        socket.onerror = (error) => {
          console.error('WebSocket error:', error);
        };
    
        socket.onclose = () => {
          console.log('WebSocket connection closed.');
        };
    
        // Cleanup function
        return () => {
          socket.close();
        };
      }, []);

  
    const [dataCoinTab] = useState([
        {
            id: 1,
            title: 'Hot',
        },
        {
            id: 2,
            title: 'New',
        },
        {
            id: 3,
            title: 'DeFi',
        },
        {
            id: 4,
            title: 'Spot',
        },

    ]);
    return (
        <div className='markets home-2'>



<Helmet>
        <title>Top Gainers Coin | Cetoex</title>
        <meta name="description" content="Highest percentage increase in their prices over last 24 Hour period. In the context of the cryptocurrency market."/>
        <meta name="keywords" content="crypto market, cetoex, exchange, top ranking coin, top volume, newly listed, top gainers, top losers, cetoex exchange, spot, trading, crypto"/>
        <meta name="author" content="Cetoex"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        
        {/* Open Graph (OG) tags for sharing on social media */}
        <meta property="og:title" content="Top Gainers Coin | Cetoex"/>
        <meta property="og:description" content="Highest percentage increase in their prices over last 24 Hour period. In the context of the cryptocurrency market."/>
        
        {/* Twitter card tags for sharing on Twitter */}
        <meta name="twitter:card" content="cetoex"/>
        <meta name="twitter:title" content="Top Gainers Coin | Cetoex"/>
        <meta name="twitter:description" content="Highest percentage increase in their prices over last 24 Hour period. In the context of the cryptocurrency market."/>
        
   
        {/* Add other meta tags here */}
      </Helmet>
            <section className="banner">        
            <section className="crypto" data-aos="fade-up" data-aos-duration="1000">
            <div className="container" style={{marginTop:"55px"}}>
             <h6>Today's Cryptocurrency Top 10 Gainers</h6>
             <p style={{maxWidth:"800px",width:"100%"}}>List of cryptocurrencies that have experienced the highest percentage increase in their prices over last 24 Hour period. In the context of the cryptocurrency market.</p>
             </div>
             </section>   
            </section>

          

            
            <section className="coin-list">
                
                <div className="container">
                    
                    <div className="row">
                        
                    <div className="col-md-12">
                        <div className="flat-tabs1">
                            
                       
                        <div className="content-tab1">
                            
                            
                            <div className="content-inner">
                                
                            <div className="flat-tabs2">
                                <div className="content-tab2">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12 col-sm-12">
                                        <h6> CetoEX Market Gainers</h6>
                                        <p>Find promising coins and great opportunities!</p><br/>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12">
                                        <div className="field2" id="searchform">
                                            <input type="text"   placeholder="Type symbol, name.."  value={searchQuery} onChange={handleSearchChange}/>
                                                <button type="button" id="search">
                                                <span className="fa fa-search"></span> Search
                                                </button>
                                          </div>
                                        </div>
                                     </div>
                               
     

                                <div className="content-inner">
                                    <div className="coin-list__main">
                                    <div className="flat-tabs">
                                       
                                    <Tabs>
                                       

                                       {
                                           dataCoinTab.map(data => (
                                               <TabPanel key={data.id}>


                                                 <MarketPagation data={filteredData}/>
                                                   



                                               </TabPanel>
                                           ))
                                       }
                                       

                                   </Tabs> 
                                           
                                    </div>
                                    </div>
                                </div>

                                </div>
                            </div>
                            </div>

                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>

       
           
        </div>
    );
}

export default TopGainers;