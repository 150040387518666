import React , {useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Sale01 from '../components/sale/Sale01';
import PageTitle from '../components/pagetitle';
import Cookies1 from 'js-cookie';
import Copy from 'clipboard-copy';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bars } from  'react-loader-spinner'

import { Helmet } from 'react-helmet';


function WithdrawalStatement(props) {


    const [depositList, setDepositList] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const token = Cookies1.get('LoginToken');
     
        if(token != null)
        {
         fetchMyTable()
        } 
        else{
          navigate('/login');
        }    
      })
  
  
  
   async function fetchMyTable() {

    const accountUserId =   Cookies1.get('investorId');
      var data = JSON.stringify({
       "investorId": accountUserId
     });
     
     var config = {
       method: 'post',
       url: 'https://test.cetoex.org/api/withdrawalstatment',
       headers: { 
         'Content-Type': 'application/json'
       },
       data : data
     };
     
     axios(config)
     .then(function (response) {
        setDepositList(response.data);
      
     })
     .catch(function (error) {
       console.log(error);
     });
     
        
    }

    const withdrawCopy = (address)=>{

        Copy(address);
         

        toast.success("Withdrawal Address is copied", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });

    }

    const CryptoTab = (title)=>
    {
       if(title === "Crypto Withdrawal")
       {
        navigate('/crypto-withdrawal');
       }
    }

    const [dataCryptoTab] = useState([
        {
            id: 2,
            title: 'Withdrawal History',
        },
        {
            id: 3,
            title: 'Crypto Withdrawal',
        },
        
    ])
    return (
        <div>


<Helmet>
        <title>Crypto Withdrawal History | Transaction Records | Cetoex</title>
        <meta name="description" content="Access a Detailed Record of Your Crypto Withdrawals on Cetoex. Stay Informed and Track Your Transaction History with Ease."/>
        <meta name="keywords" content="crypto market, crypto deposit history, cetoex, exchange, top ranking coin, top volume, newly listed, top gainers, top losers, cetoex exchange, spot, trading, crypto"/>
        <meta name="author" content="Cetoex"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        
        {/* Open Graph (OG) tags for sharing on social media */}
        <meta property="og:title" content="Crypto Withdrawal History | Transaction Records | Cetoex"/>
        <meta property="og:description" content="Access a Detailed Record of Your Crypto Withdrawals on Cetoex. Stay Informed and Track Your Transaction History with Ease."/>
        
        {/* Twitter card tags for sharing on Twitter */}
        <meta name="twitter:card" content="cetoex"/>
        <meta name="twitter:title" content="Crypto Withdrawal History | Transaction Records | Cetoex"/>
        <meta name="twitter:description" content="Access a Detailed Record of Your Crypto Withdrawals on Cetoex. Stay Informed and Track Your Transaction History with Ease."/>
       
   
        {/* Add other meta tags here */}
      </Helmet>


            <PageTitle heading='Withdrawal History' title='Withdrawal History' />
            <ToastContainer position="bottom-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark"/>

            <section className="wallet sell buy-crypto flat-tabs">
                <div className="container">
                    <div className="row">
                    <Tabs>
                            <TabList>

                                {
                                    dataCryptoTab.map(idx => (
                                        <Tab  key={idx.id} onClick={()=>CryptoTab(idx.title)}>{idx.title}</Tab>
                                    ))
                                }

                            </TabList>

                            <TabPanel>
                            <div className="content-inner">
                <center>   <h4>Withdrawal History</h4></center>
                         
                            {
                depositList && depositList.length>0 ?
                depositList.map((idx, i) =>
                    
                
              
                 <div className="main info">
                 <p style={{fontSize:"20px",fontWeight:"bold"}}>{idx.depositCoin} Withdraw ({idx.withdrawStatus})</p>
                

                 <ul className="list">
                     <li>
                     <div className="icon">
                        <img src={idx.coinLogo} style={{width:"35px",borderRadius:"50%"}}/>
                     </div>
                     <div className="content">
                         <p>Amount</p>
                         <h6 className="price">{idx.withdrawCoin+" "+idx.withdrawAmount}</h6>
                     </div>
                     </li>
                     <li>
                     <div className="icon">
                     <span className="bx bx-package" style={{color:"white",fontSize:"30px"}}></span>
                     </div>
                     <div className="content">
                         <p>Blockchain</p>
                         <h6 className="price">{idx.withdrawNetwork}</h6>
                     </div>
                     </li>
                     <li>
                     <div className="icon">
                     <span className="bx bx-calendar" style={{color:"white",fontSize:"30px"}}></span>
                     </div>
                     <div className="content">
                         <p>Withdraw Date</p>
                         <h6 className="price">{idx.withdrawDate}</h6>
                     </div>
                     </li>
                     <li>
                     <div className="icon" style={{backgroundColor:"#000"}}>
                     <span className="bx bx-wallet" style={{color:"white",fontSize:"27px"}}></span>
                     </div>
                     <div className="content">
                         <p>Address</p>
                         <h6 className="price">{idx.withdrawAddress.slice(0, 8)+"..."+idx.withdrawAddress.slice(-8)} <span className="bx bx-copy-alt" onClick={()=>withdrawCopy(idx.withdrawAddress)}></span></h6>
                     </div>
                     </li>
                 </ul>
                 </div>
            

                ):(
                    <>
                     <center>   <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '120px' }}>
                   <Bars
    height = "50"
    width = "50"
    radius = "9"
    color = '#3375fe'
    ariaLabel = 'three-dots-loading'  
    marginLeft='200'   
    wrapperStyle
    wrapperClass
  />
  </div></center>
                    </>
                )
                 }
                  </div>

                            </TabPanel>
                         
                    </Tabs> 

                    </div>
                </div>
            </section>

            <Sale01 />
            
        </div>
    );
}

export default WithdrawalStatement;