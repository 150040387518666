import React,{ useState,useEffect } from "react";
import axios  from "axios";
import Sale01 from '../components/sale/Sale01';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {Link} from 'react-router-dom';
import img from '../assets/images/layout/register.png'
import './login.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from "jquery";
import ImageLoader from "../components/loader/loader";
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

function Register() {
   
    const { id } = useParams();
 

    const [mail, setMail] = useState(null);
     const [show, setShow] = useState(1);
     const[loader,setLoader] = useState(false);

   
        const createAccount = async e =>{
            e.preventDefault();
       const referral = document.getElementById('referralCode').value;
       const name = document.getElementById('name').value;
       const emailAddress = document.getElementById('emailAddress').value;
       const password = document.getElementById('password').value;

       setMail(emailAddress);
       setLoader(true)
       var data = JSON.stringify({
        "emailaddress": emailAddress,
        "referralCode": referral,
        "name": name,
        "password": password
      });
      
      var config = {
        method: 'post',
        url: 'https://test.cetoex.org/api/emailverification1',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        if(response.data.status === "success")
        {
            toast.success(response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
    
            setShow(2);
        }
        else{
            toast.error(response.data.status, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
        }
        setLoader(false)
      })
      .catch(function (error) {
        toast.error(error, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
      });
      
    }

    const verifyOTP = ()=>{
        const otp = document.getElementById('input1').value+document.getElementById('input2').value+document.getElementById('input3').value+document.getElementById('input4').value+document.getElementById('input5').value+document.getElementById('input6').value;
       
       
        setLoader(true)
        var data = JSON.stringify({
            "emailaddress": mail,
            "otp": otp
          });
          
          var config = {
            method: 'post',
            url: 'https://test.cetoex.org/api/verifyOTP',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
            if(response.data.status === "success")
        {
            toast.success(response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
    
            setShow(3);
        }
            else{
                toast.error(response.data.status, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
            }

            setLoader(false)
          })
          .catch(function (error) {
            toast.error(error, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
          });
          
       
    }
 
    

    function moveCurser(first,second)
    {
        
     var text = document.getElementById(first).value;
     let text_lenght = text.length;
     if (text_lenght >= 1) {
         
         $(second).focus();
     }
    }
 




    return (
        <div>

<ToastContainer
position="bottom-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="dark"
/>


{
    loader && <ImageLoader/>
}


             
             {/* <PageTitle heading='Login' title='Login' /> */}
               
             <section className="register login">


                                                             
<Helmet>
        <title>Create Your Account | Join the World of Cryptocurrency Trading | Cetoex</title>
        <meta name="description" content="Start Your Cryptocurrency Journey Today! Create an Account on Cetoex to Access a Secure and Innovative Platform for Trading Digital Assets."/>
        <meta name="keywords" content="crypto market, crypto deposit history, cetoex, exchange, top ranking coin, top volume, newly listed, top gainers, top losers, cetoex exchange, spot, trading, crypto"/>
        <meta name="author" content="Cetoex"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        
        {/* Open Graph (OG) tags for sharing on social media */}
        <meta property="og:title" content="Create Your Account | Join the World of Cryptocurrency Trading | Cetoex"/>
        <meta property="og:description" content="Start Your Cryptocurrency Journey Today! Create an Account on Cetoex to Access a Secure and Innovative Platform for Trading Digital Assets."/>
        
        {/* Twitter card tags for sharing on Twitter */}
        <meta name="twitter:card" content="cetoex"/>
        <meta name="twitter:title" content="Create Your Account | Join the World of Cryptocurrency Trading | Cetoex"/>
        <meta name="twitter:description" content="Start Your Cryptocurrency Journey Today! Create an Account on Cetoex to Access a Secure and Innovative Platform for Trading Digital Assets."/>
       
      </Helmet>



             <div className="container">
                <div className="row">

              
                <div className="col-xl-6 col-md-12">
                    <div className="trading__image">
                    <img src={img} alt="referral_program" style={{width:"90%",height:"90%",marginTop:"40px"}} />
                    </div>
                </div>
                <div className="col-xl-6 col-md-12">
                <Tabs>
                    
                    <TabList>
      
                        {/* <Tab><h6 className="fs-16">Email</h6></Tab> */}
                        {/* <Tab><h6 className="fs-16">Mobile</h6></Tab> */}

                    </TabList>



                    <TabPanel>

                        <div className="content-inner">
                          

                            <div className="col-md-12">
                    <div className="block-text center">
                    <h3 className="heading">Register with  CetoEX</h3>
                    <p className="desc fs-20">
                        Welcome user! Register now to start trading
                    </p>
                    <div className="lock" id="message_container">
                        <div className="icon">
                        <svg
                            width="16"
                            height="20"
                            viewBox="0 0 16 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                            d="M8.00004 11.7578C7.67672 11.7578 7.41406 12.0205 7.41406 12.3438C7.41406 12.6671 7.67672 12.9298 8.00004 12.9298C8.32336 12.9298 8.58602 12.6671 8.58602 12.3438C8.58602 12.0205 8.32336 11.7578 8.00004 11.7578Z"
                            fill="white"
                            />
                            <path
                            d="M11.5162 8.24219H4.2187C2.10011 8.24219 0.382568 9.95965 0.382568 12.0783C0.382568 15.6973 2.78413 19.0605 6.32241 19.8205C11.2508 20.8791 15.618 17.0922 15.618 12.344C15.618 10.0787 13.7816 8.24219 11.5162 8.24219ZM8.58628 13.9941V17.071C8.58628 17.3949 8.32417 17.657 8.0003 17.657C7.6764 17.657 7.41433 17.3949 7.41433 17.071V13.9941C6.73374 13.7514 6.24237 13.107 6.24237 12.3441C6.24237 11.3747 7.03093 10.5861 8.0003 10.5861C8.96968 10.5861 9.75823 11.3747 9.75823 12.3441C9.75823 13.107 9.26686 13.7513 8.58628 13.9941Z"
                            fill="white"
                            />
                            <path
                            d="M8.00039 0C5.08223 0 2.72656 2.35562 2.72656 5.27383V7.3234C3.20102 7.17391 3.69582 7.07086 4.21898 7.07086H5.07051V5.27383C5.07051 3.65652 6.38309 2.34395 8.00039 2.34395C9.6177 2.34395 10.9303 3.65652 10.9303 5.27383V7.07082H11.5163C12.1356 7.07082 12.7216 7.19777 13.2742 7.3948V5.27383C13.2742 2.35844 10.9128 0 8.00039 0Z"
                            fill="white"
                            />
                        </svg>
                        </div>
                        <p ><span>https://</span>cetoex.com/create-account</p>
                    </div>
                    </div>
                    
                </div>



                                                    {

                                       show === 1 && (
                                        <form>
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Referral Code<span style={{fontSize:"13px",color:"#0380fd"}}> Optional</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="referralCode"
                                                value={id}
                                                placeholder="Enter Referral Code"
                                            />
                                            </div>
            
                                            <div className="form-group">
                                            <label for="exampleInputEmail1">Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="name"
                                                placeholder="Enter Name"
                                            />
                                            </div>
            
            
                                            <div className="form-group">
                                            <label for="exampleInputEmail1">Email Address</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="emailAddress"
                                                placeholder="Enter Email Address"
                                            />
                                            </div>
                                            <div className="form-group s1">
                                            <label>Password </label>
                                            <input id="password"
                                                type="password"
                                                className="form-control"
                                                placeholder="Enter Password"
                                            />
                                            </div>
            
                                           
            
                                            <button type="submit" className="btn-action" style={{backgroundColor:"#3673ff",color:"white",borderRadius:"28px"}} onClick={createAccount}>Create Account</button>
                                            <div className="bottom">
                                            <p>Already an account?</p>
                                            <a href="/login">Login</a>
                                            </div>
                                        </form>

                                            )
                                    }



                                        {

                                        show === 2 && (
                                        
                                            <form action="javascript: void(0)" className="otp-form" name="otp-form">

                                            <div className="title11" style={{marginTop:"50px"}}>
                                            
                                            <p>OTP has been sent to {mail}</p>
                                </div>
                                <div className="otp-input-fields">
                                  <input type="number" id="input1" className="otp__digit otp__field__1 otp_box" onKeyUp={(e)=>moveCurser('input1','#input2')} maxLength="1"/>
                                  <input type="number" id="input2" className="otp__digit otp__field__2 otp_box"  onKeyUp={(e)=>moveCurser('input2','#input3')} maxLength="1"/>
                                  <input type="number" id="input3" className="otp__digit otp__field__3 otp_box"  onKeyUp={(e)=>moveCurser('input3','#input4')} maxLength="1"/>
                                  <input type="number" id="input4" className="otp__digit otp__field__4 otp_box"  onKeyUp={(e)=>moveCurser('input4','#input5')} maxLength="1"/>
                                  <input type="number" id="input5" className="otp__digit otp__field__5 otp_box"  onKeyUp={(e)=>moveCurser('input5','#input6')} maxLength="1"/>
                                  <input type="number" id="input6" className="otp__digit otp__field__6 otp_box" maxLength="1"/>
                                  
                                </div>
                                        <center>  <p><b>Enter 6 Digit OTP.<a  style={{color:"#3673ff",fontSize:"14px"}}>&nbsp;&nbsp;Resend OTP?</a></b></p>
                                            
                                        <br/>
                                            <button type="submit" className="btn-action" style={{width:"80%"}} onClick={verifyOTP}>Verify OTP</button>
                                            </center>
                                        </form>
                                        
                                            )

                                        }


{
                            show === 3 && (
                             
                              <center> <br/> <div style={{margin:"15px",padding:"20px",border:"1px dashed #3673ff",width:"80%"}}>
                                  <p style={{color:"#3673ff",fontSize:"28px"}} className="fa fa-check-circle"></p>
                                   <h4 style={{color:"#3673ff",fontSize:"28px",marginTop:"10px"}}>New User Is Registered.</h4>
                                   <p>You are successfully changed the password.</p>
                                </div>
                               
                                <div className="bottom">
                                <span>I Have Credentials?</span>&nbsp;&nbsp;
                                <a href="/login" style={{color:"#3673ff"}}> Login</a>
                                </div>
                                </center>
                                  
                                )  
                         }

                          {
                            show === 4 && (
                             
                              <center> <br/>  <div style={{margin:"15px",padding:"20px",border:"1px dashed #d9534f",width:"80%"}}>
                                  <p style={{color:"#d9534f",fontSize:"28px"}} className="fa fa-times-circle"></p><br/>
                                   <h4 style={{color:"#d9534f",fontSize:"28px"}}>Something went wrong.</h4>
                                   <p>Occurrence of an error in password change.</p>
                                </div>
                                </center>
                              
                                )  
                         }

              
                           
                        </div>
                    </TabPanel>

                    {/* <TabPanel>
                        <div className="content-inner">
                            <form>
                                <div className="form-group">
                                <label for="exampleInputEmail1">Mobile Phone</label>
                                <div>
                                    <select
                                    className="form-control"
                                    id="exampleFormControlSelect1"
                                    >
                                    <option>+1</option>
                                    <option>+84</option>
                                    <option>+82</option>
                                    <option>+32</option>
                                    </select>
                                    <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Your Phone number"
                                    />
                                </div>
                                </div>
                                <div className="form-group s1">
                                <label>Password </label>
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Please enter a password."
                                />
                                </div>

                                <div className="form-group form-check">
                                <div>
                                    <input type="checkbox" className="form-check-input" />
                                    <label className="form-check-label">Remember Me</label>
                                </div>
                                <p>Forgot Password?</p>
                                </div>

                                <button type="submit" className="btn-action">Login</button>
                                <div className="bottom">
                                <p>Not a member?</p>
                                <Link to="/create-account">Register</Link>
                                </div>
                            </form>
                        </div>
                    </TabPanel> */}

                    

                </Tabs> 
                </div>
                </div>
            </div>
            </section>
           
      


            <Sale01 />
            
        </div>
    );
}

export default Register;