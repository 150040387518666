import React from 'react';
import { Link } from 'react-router-dom';

function ReloadableLink({ to, children }) {
  const handleClick = () => {
    window.location.href = to; // This will trigger a full page reload
  };

  return <Link to={to} onClick={handleClick}>{children}</Link>;
}

export default ReloadableLink;
