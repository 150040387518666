import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Crypto03 from '../components/crypto/Crypto03';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import MarketPagation from './Marketpagination';
import { Helmet } from 'react-helmet';

function Markets(props) {
  const [userList, setUserList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = userList.filter(
    (item) =>
      item.coin_symbol.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.coin_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    const socket = new WebSocket('wss://websocket.cetoex.com:8080/market');

    socket.onopen = () => {
      console.log('WebSocket connection established.');
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setUserList(data);
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    socket.onclose = () => {
      console.log('WebSocket connection closed.');
    };

    // Cleanup function
    return () => {
      socket.close();
    };
  }, []);

  const [dataCoinTab] = useState([
    {
      id: 1,
      title: 'Hot',
    },
    {
      id: 2,
      title: 'New',
    },
    {
      id: 3,
      title: 'DeFi',
    },
    {
      id: 4,
      title: 'Spot',
    },
  ]);

  return (
    <>
      <Helmet>
        <title>Crypto Market | Coin Price &amp; Volume | Cetoex</title>
        <meta
          name="description"
          content="Real-time cryptocurrency market data and prices on Cetoex. Track the latest trends and prices of popular cryptocurrencies."
        />
        <meta
          name="keywords"
          content="crypto market, cetoex, exchange, top ranking coin, top volume, newly listed, top gainers, top losers, cetoex exchange, spot, trading, crypto"
        />
        <meta name="author" content="Cetoex" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        {/* Open Graph (OG) tags for sharing on social media */}
        <meta
          property="og:title"
          content="Crypto Market | Coin Price &amp; Volume | Cetoex"
        />
        <meta
          property="og:description"
          content="Real-time cryptocurrency market data and prices on Cetoex. Track the latest trends and prices of popular cryptocurrencies."
        />

        {/* Twitter card tags for sharing on Twitter */}
        <meta name="twitter:card" content="cetoex" />
        <meta
          name="twitter:title"
          content="Crypto Market | Coin Price &amp; Volume | Cetoex"
        />
        <meta
          name="twitter:description"
          content="Real-time cryptocurrency market data and prices on Cetoex. Track the latest trends and prices of popular cryptocurrencies."
        />
        <meta
          name="twitter:image"
          content="https://pbs.twimg.com/profile_images/1556224061248008192/KQTT5Qk3_400x400.jpg"
        />

        {/* Add other meta tags here */}
      </Helmet>

      <div className="markets home-2">
        <section className="banner">
          <div className="container">
            <Crypto03 data={userList} />
          </div>
        </section>

        <section className="coin-list">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="content-inner">
                  <div className="flat-tabs2">
                    <div className="content-tab2">
                      <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <h6>CetoEX Market Watch</h6>
                          <p>Find promising coins and great opportunities!</p>
                          <br />
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <div className="field2" id="searchform">
                            <input
                              type="text"
                              placeholder="Type symbol, name.."
                              value={searchQuery}
                              onChange={handleSearchChange}
                            />
                            <button type="button" id="search">
                              <span className="fa fa-search"></span> Search
                            </button>
                          </div>
                        </div>
                      </div>

                      <ul className="tab-tab2">
                        <li className="active">
                          <a href="/markets">All</a>
                        </li>
                        <li>
                          <a href="/memecoin">Memecoin</a>
                        </li>
                        <li>
                          <a href="/blockchain">Blockchain</a>
                        </li>
                        <li>
                          <a href="/de-fi">De-Fi</a>
                        </li>
                      </ul>

                      <div className="content-inner">
                        <div className="coin-list__main">
                          <div className="flat-tabs">
                            <Tabs>
                            

                              {dataCoinTab.map((data) => (
                                <TabPanel key={data.id}>
                                  <MarketPagation data={filteredData} />
                                </TabPanel>
                              ))}
                            </Tabs>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Markets;
