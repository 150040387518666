import React from 'react';
import Banner01 from '../components/banner/Banner01';
import { Helmet } from 'react-helmet';


function HomeOne(props) {

    return (
        <div className='home-1'>
       
<Helmet>
        <title>Coin Launchpad | Cetoex</title>
        <meta name="description" content="Explore Promising Projects and Join Token Launches with CetoEx Launchpad. Unleash Innovation in the World of Cryptocurrencies."/>
        <meta name="keywords" content="crypto market, crypto deposit history, cetoex, exchange, top ranking coin, top volume, newly listed, top gainers, top losers, cetoex exchange, spot, trading, crypto"/>
        <meta name="author" content="Cetoex"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        
        {/* Open Graph (OG) tags for sharing on social media */}
        <meta property="og:title" content="Coin Launchpad | Cetoex"/>
        <meta property="og:description" content="Explore Promising Projects and Join Token Launches with CetoEx Launchpad. Unleash Innovation in the World of Cryptocurrencies."/>
        
        {/* Twitter card tags for sharing on Twitter */}
        <meta name="twitter:card" content="cetoex"/>
        <meta name="twitter:title" content="Coin Launchpad | Cetoex"/>
        <meta name="twitter:description" content="Explore Promising Projects and Join Token Launches with CetoEx Launchpad. Unleash Innovation in the World of Cryptocurrencies."/>
        
      </Helmet>
            <Banner01 />


            
        </div>
    );
}

export default HomeOne;