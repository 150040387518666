import React, { useEffect, useRef } from 'react';

const CryptoLineChart = ({ prop1, prop2}) => {

  
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Sample data for the line chart
    const data1 = prop1.split(',').map((item) => parseFloat(item));
    
    const data = modifyData(data1);
    // Chart configuration
    const numberAsString = prop2.toString();
  
    let chartColor;
    let shadowColor;

    if(prop2 === 0)
    {  
      chartColor = 'black';
      shadowColor= "#292b2c";
             
    }
     else if(numberAsString.substring(0, 1) === "-")
    {
       chartColor = 'red';
       shadowColor= "#d9534f";
      
     }
   else{
        chartColor= 'green';
        shadowColor= "#5cb85c";
     }
     
    
    const chartConfig = {
      width: 150,
      height: 50,
      margin: 4,
      pointRadius: 0,
      lineColor: chartColor,
      pointColor: 'red',
      shadowColor:shadowColor,
    };


    function modifyData(data) {
      const areAllValuesSame = data.every((value, index, array) => value === array[0]);
    
      if (areAllValuesSame) {
       
        // Apply the 0.001% modification
        data = [10000,10000];
      }
    
      return data;
    }
    const drawLineChart = () => {
      const { width, height, margin, pointRadius, lineColor, pointColor,shadowColor } = chartConfig;

      // Calculate the maximum and minimum values in the data
      const maxValue = Math.max(...data);
      const minValue = Math.min(...data);

      // Calculate the scale for the y-axis
      const yScale = (height - 2 * margin) / (maxValue - minValue);

      // Calculate the x-axis step size
      const xStep = (width - 2 * margin) / (data.length - 1);

      // Clear the canvas
      ctx.clearRect(0, 0, width, height);

      // Draw the line
      ctx.beginPath();
      ctx.strokeStyle = lineColor;
      // ctx.shadowColor = shadowColor;
      // ctx.lineWidth = 1;
      // ctx.shadowOffsetX = 0;
      // ctx.shadowOffsetY = 10;
      // ctx.shadowBlur = 10;
      ctx.moveTo(margin, height - margin - (data[0] - minValue) * yScale);
      for (let i = 1; i < data.length; i++) {
        ctx.lineTo(margin + i * xStep, height - margin - (data[i] - minValue) * yScale);
      }
      ctx.stroke();


      
      // Draw the data points
      ctx.fillStyle = pointColor;
      data.forEach((value, index) => {
        ctx.beginPath();
        ctx.arc(margin + index * xStep, height - margin - (value - minValue) * yScale, pointRadius, 0, Math.PI * 2);
        ctx.fill();
      });
    };

    drawLineChart();
  });

  return <canvas ref={canvasRef} width={150} height={50} />;
};

export default CryptoLineChart;
