import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sale01 from '../components/sale/Sale01';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Link } from 'react-router-dom';
import img from '../assets/images/user.jpg';
import Cookies1 from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import { FaFacebook, FaWhatsapp, FaLink, FaTwitter, FaEnvelopeOpenText } from 'react-icons/fa';
import 'react-toastify/dist/ReactToastify.css';
import ImageLoader from "../components/loader/loader";
import Copy from 'clipboard-copy';
import { Helmet } from 'react-helmet';
import Kycupload from './Kycupload';
import LiquidityPool from '../components/liquidity/liqudityPool';
import Liquidity from '../components/liquidity/liquidity';

function UserProfile(props) {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['user']);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(1);
  const [ID, setID] = useState('...');

  const [data, setData] = useState({
    totalCoin: 'Loading..',
    refferal: 'Loading..',
    first: 'Loading..',
    kyc: 'Loading..',
    liqudity: false,
  });

  const [dataCoinTab, setDataCoinTab] = useState([
    { id: 1, title: 'Referrals Code', icon: 'fa-share-nodes' },
    { id: 2, title: 'KYC Verification', icon: 'fa-file-text' },
    { id: 5, title: 'Change password', icon: 'fa-key' },
    { id: 6, title: 'Logout', icon: 'fa-sign-out' },
  ]);

  const shareMessage = encodeURIComponent(
    "🌟 Hey Friend, Get started with CetoEX and receive a massive 500 Million BABYDOGE as a special signup bonus! 🌟\n\n" +
    "✨ Join now and be a part of the future of crypto trading.✨\n\n" +
    "1️⃣ Sign up at CetoEX using this link:  https://cetoex.com/create-account/" + ID + "\n\n" +
    "🎉 Download CetoEX App from https://play.google.com/store/apps/details?id=com.cetoex.cetoex 🌐💰"
  );

  const twitterMessage = encodeURIComponent(
    "🌟 Hey Friend, Get started with CetoEX and receive a massive 500 Million BABYDOGE as a special signup bonus! 🌟\n\n" +
    "✨ Join now and be a part of the future of crypto trading.✨\n\n" +
    "1️⃣ Sign up at CetoEX using this link:  https://cetoex.com/create-account/" + ID
  );

  const emailAddress = 'support@cetoex.com';
  const subject = "500 Million BABYDOGE SignUp Bonus FREE!!";
  const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${shareMessage}`;
  const whatsappLink = `https://api.whatsapp.com/send/?text=${shareMessage}`;
  const urlToShare = "https://cetoex.com/create-account/" + ID;
  const facebookShareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(urlToShare)}&quote=${encodeURIComponent(shareMessage)}`;
  const twitterShareLink = `https://twitter.com/intent/tweet?text=${twitterMessage}`;

  useEffect(() => {
    const token = Cookies1.get('LoginToken');
    const accountUserId = Cookies1.get('investorId');
    setID(accountUserId);

    if (token != null) {
      fetchMyTable();
    } else {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    if (data.liqudity == "1") {
      setDataCoinTab(prevDataCoinTab => {
        const updatedTabs = prevDataCoinTab.filter(tab => tab.id !== 3 && tab.id !== 4);
        return [
          ...updatedTabs.slice(0, -2),
          { id: 3, title: 'View Liquidity', icon: 'fa-tint' },
          { id: 4, title: 'Pool Policy', icon: 'fa-info-circle' },
          ...updatedTabs.slice(-2),
        ];
      });
    }
  }, [data.liqudity]);

  async function fetchMyTable() {
    const accountUserId = Cookies1.get('investorId');
    var data = JSON.stringify({ "investorID": accountUserId });

    var config = {
      method: 'post',
      url: 'https://test.cetoex.org/api/refferalData',
      headers: { 'Content-Type': 'application/json' },
      data: data
    };

    axios(config)
      .then(function (response) {
        setData({
          totalCoin: response.data.total,
          refferal: response.data.refferal,
          first: response.data.investment,
          kyc: response.data.KYC,
          liqudity: response.data.Liqudity
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const changePassword = async () => {
    const oldPass = document.getElementById('old_password').value;
    const newPass = document.getElementById('new_password').value;
    const confirmPass = document.getElementById('confirm_password').value;

    var empty = "";
    var psdcheck = /^[a-zA-Z0-9!@#$%^&*]{8,15}$/;

    if (empty === oldPass) {
      document.getElementById('olderror').innerHTML = "&nbsp;&nbsp;**&nbsp;&nbsp;Old password is required.";
      return false;
    } else {
      document.getElementById('olderror').innerHTML = "";
    }

    if (empty === newPass) {
      document.getElementById('newerror').innerHTML = "&nbsp;&nbsp;**&nbsp;&nbsp;New password is required.";
      return false;
    } else {
      if (psdcheck.test(newPass)) {
        document.getElementById('newerror').innerHTML = "";
      } else {
        document.getElementById('newerror').innerHTML = "&nbsp;&nbsp;**&nbsp;&nbsp;Invalid New password(Add Special Symbol).";
        return false;
      }
    }

    if (empty === confirmPass) {
      document.getElementById('confirmerror').innerHTML = "&nbsp;&nbsp;**&nbsp;&nbsp;Confirm Password is required.";
      return false;
    } else {
      if (newPass === confirmPass) {
        document.getElementById('confirmerror').innerHTML = "";
      } else {
        document.getElementById('confirmerror').innerHTML = "&nbsp;&nbsp;**&nbsp;&nbsp;Confirm Password isn't matched.";
        return false;
      }
    }

    setLoader(true);

    var data = JSON.stringify({
      "investorId": "49",
      "APIKey": "392thfwfj9if",
      "oldpass": oldPass,
      "newpass": newPass,
      "confirmpass": confirmPass
    });

    var config = {
      method: 'post',
      url: 'https://test.cetoex.org/api/changePassword',
      headers: { 'Content-Type': 'application/json' },
      data: data
    };

    axios(config)
      .then(function (response) {
        if (response.data.errorcode === "X001") {
          toast.success(response.data.status, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });

          document.getElementById('old_password').value = "";
          document.getElementById('new_password').value = "";
          document.getElementById('confirm_password').value = "";
        } else {
          toast.error(response.data.status, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }

        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      <Helmet>
        <title>Crypto Profile | Cetoex</title>
        <meta name="description" content="Take Control of Your Experience with Cetoex. Access and Customize Your Exchange Profile to Tailor Your Trading and Account Settings." />
        <meta name="keywords" content="crypto market, crypto deposit history, cetoex, exchange, top ranking coin, top volume, newly listed, top gainers, top losers, cetoex exchange, spot, trading, crypto" />
        <meta name="author" content="Cetoex" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        {/* Open Graph (OG) tags for sharing on social media */}
        <meta property="og:title" content="Crypto Profile | Cetoex" />
        <meta property="og:description" content="Take Control of Your Experience with Cetoex. Access and Customize Your Exchange Profile to Tailor Your Trading and Account Settings." />

        {/* Twitter card tags for sharing on Twitter */}
        <meta name="twitter:card" content="cetoex" />
        <meta name="twitter:title" content="Crypto Profile | Cetoex" />
        <meta name="twitter:description" content="Take Control of Your Experience with Cetoex. Access and Customize Your Exchange Profile to Tailor Your Trading and Account Settings." />
      </Helmet>

      <div>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />

        {loader && <ImageLoader />}

        <section className="user-profile join">
          <div className="container">
            <div className="row">
              <Tabs>
                <TabList>
                  <div className="user-info center">
                    <div className="avt">
                      <img id="blah" src={img} alt="no file" />
                    </div>
                    <h6 className="name">{data.customerName}</h6>
                    <p style={{ color: "green", fontSize: "14px", display: "flex", justifyContent: "center" }}>
                      <span className="bx bx-badge-check" style={{ color: "green", fontSize: "20px" }}></span> Verified
                    </p>
                  </div>
                  {dataCoinTab.map(idx => (
                    <Tab key={idx.id}>
                      <h6 className="fs-16">
                        <i className={`fa ${idx.icon}`}></i>
                        {idx.title}
                      </h6>
                    </Tab>
                  ))}
                </TabList>

                <TabPanel>
                  <div className="content-inner referrals">
                    <h6>Total rewards</h6>
                    <h5>{data.totalCoin} <span style={{ color: "#58bd7d", fontSize: "20px" }}>BABYDOGE</span></h5>
                    <p>
                      You can earn 500 Million BABYDOGE with our cetoex referral program.&nbsp;
                      <a href="/referral-program">Learn more</a>
                    </p>
                    <div className="main">
                      <h6>Invite friends to earn 500 Million BABYDOGE</h6>
                      <div>
                        <center>
                          <div>
                            <FaWhatsapp size={35} color="#25D366" className="m-2" onClick={() => {
                              window.open(whatsappLink);
                            }} />
                            <FaFacebook size={32} color="#3b5998" className="m-2" onClick={() => {
                              window.open(facebookShareLink);
                            }} />
                            <FaTwitter size={32} color="#00acee" className="m-2" onClick={() => {
                              window.open(twitterShareLink);
                            }} />
                            <FaEnvelopeOpenText size={30} color="#ba0001" className="m-2" onClick={() => {
                              window.open(mailtoLink);
                            }} />
                            <FaLink size={30} color="#3872ff" className="m-2" onClick={() => {
                              Copy(`https://cetoex.com/create-account/${ID}`);
                              toast.success("Referral Link Copied", {
                                position: "bottom-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "dark",
                              });
                            }} />
                          </div>
                        </center>
                      </div>
                      <br />
                      <p>Earning Statement</p>
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="left" scope="col">#</th>
                            <th scope="col">Earning Type</th>
                            <th scope="col">Income</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>
                              <span className="boild">Airdrop Income </span>
                            </td>
                            <td>{data.kyc} BABYDOGE</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>
                              <span className="boild">Referral Income </span>
                            </td>
                            <td>{data.refferal} BABYDOGE</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>
                              <span className="boild">First Investment Airdrop</span>
                            </td>
                            <td>{data.first} BABYDOGE</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="content-inner api">
                    <div className="main">
                      <h6>KYC Verification</h6>
                      <Kycupload />
                    </div>
                  </div>
                </TabPanel>

                {data.liqudity === "1" && (
                  <>
                    <TabPanel>
                      <div className="">
                        <Liquidity />
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="content-inner referrals">
                        <LiquidityPool />
                      </div>
                    </TabPanel>
                  </>
                )}

                <TabPanel>
                  <div className="">
                    <h5>Change Password</h5>
                    <p>Always use a strong password, Contains A-Z,a-z & special char.</p><br />
                    <form action="#">
                      <div className="row">
                        <div className="form-group col-12">
                          <div>
                            <label>Old Password<span>*</span></label>
                            <input
                              type="text" id="old_password"
                              className="form-control"
                              placeholder="Enter Old Password"
                            />
                            <span style={{ color: "#d9534f", fontSize: "11px", fontWeight: "bold", paddingLeft: "4px" }} id="olderror"></span>
                          </div>
                        </div>
                        <div className="form-group col-6">
                          <div>
                            <label>New Password<span>*</span></label>
                            <input placeholder="Enter New Password"
                              type="text"
                              className="form-control"
                              id="new_password"
                            />
                            <span style={{ color: "#d9534f", fontSize: "11px", fontWeight: "bold", paddingLeft: "4px" }} id="newerror"></span>
                          </div>
                        </div>
                        <div className="form-group col-6">
                          <div>
                            <label>Confirm Password<span>*</span></label>
                            <input
                              type="password"
                              className="form-control"
                              placeholder="************"
                              id="confirm_password"
                            />
                            <span style={{ color: "#d9534f", fontSize: "11px", fontWeight: "bold", paddingLeft: "4px" }} id="confirmerror"></span>
                          </div>
                        </div>
                      </div>
                    </form>
                    <a type="submit" className="btn-action m-2" onClick={changePassword}>
                      Change Password
                    </a>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="content-inner api">
                    <div className="main">
                      <h6>Logout Account</h6>
                      <p style={{ fontSize: "18px" }}>Click on this below button for <span style={{ color: "#d33535" }}>Signout Account</span></p>
                      <br />
                      <center>
                        <a type="submit" className="btn-action m-2" href="./logout">
                          Logout Account
                        </a>
                      </center>
                    </div>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </section>
        <Sale01 />
      </div>
    </>
  );
}

export default UserProfile;
