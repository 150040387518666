import React, { useState,useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Bars } from  'react-loader-spinner'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImageLoader from "../loader/loader";
import Cookies1 from 'js-cookie';
import Sale01 from '../sale/Sale01';
import PageTitle from '../pagetitle';
import './liquidity.scss';


function Liquidity() {
    const navigate = useNavigate();

    const [LiqudityList, setLiqudityList] = useState({
        coin:"...",
        usdt:"...",
        token:"...",
        price:0,
        liqudateusdt:0,
        liqudatedtoken:0,

      });


      const [depositAmount,setDepositAmount] = useState(0);
      const [withdawAmount,setWithdrawAmount] = useState(0);


    useEffect(() => {
    
      
        const token = Cookies1.get('LoginToken');
     
        if(token != null)
        {
            fetchMyTable();
                  
        } 
        else{
          navigate('/login');
        }    
      }, [])
  

      async function fetchMyTable() {

        const accountUserId = Cookies1.get('investorId');
      
    
          var data = JSON.stringify({
           "investorId": accountUserId
         });
         
         var config = {
           method: 'post',
           url: 'https://test.cetoex.org/api/liqudity',
           headers: { 
             'Content-Type': 'application/json'
           },
           data : data
         };
         
         axios(config)
         .then(function (response) {

           const response1 = response.data;
             
            if(response1.status === "success")
            {

           

             
             setLiqudityList({
                coin:response1.coin_symbol,
                usdt:response1.usdt,
                token:response1.token,
                price:response1.price,
                liqudateusdt:response1.LiqudateUSDT,
                liqudatedtoken:response1.LiqudateToken,

             });



            }
            else{
                navigate('/wallet'); 
            }
    
              })
         .catch(function (error) {
           console.log(error);
         });
      
        }



        const makeWithdraw = (e)=>{
          e.preventDefault();

          const withdrawUSDT = document.getElementById('withdrawAmount').value;
          const accountUserId = Cookies1.get('investorId');


          if(withdrawUSDT === "")
        {

          toast.warning("Enter Withdraw Amount", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });

        }
        else{

          var data = JSON.stringify({
            "clientID": accountUserId,
            "withdrawAmount":withdrawUSDT,
            "withdrawToken":withdawAmount
          });
          
          var config = {
            method: 'post',
            url: 'https://test.cetoex.org/api/withdrawLiqudity',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
  
            
  
             if(response.data.status === "success")
             {
              
              fetchMyTable();
  
              toast.success(response.data.msg, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
  
                document.getElementById('withdrawAmount').value = "";
                setWithdrawAmount(0);

                
             }
             else{
  
              toast.error(response.data.msg, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
                 
             }
  
  
            
     
               })
          .catch(function (error) {
            console.log(error);
          });

          
        }





        }
  

    const makeDeposit = (e)=>{
      e.preventDefault();

  
    const depositUSDT = document.getElementById('depositAmount').value;
    const accountUserId = Cookies1.get('investorId');

        if(depositUSDT === "")
        {

          toast.warning("Enter Deposit Amount", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });

        }
        else{

          var data = JSON.stringify({
            "clientID": accountUserId,
            "depositAmount":depositUSDT,
            "depositToken":depositAmount
          });
          
          var config = {
            method: 'post',
            url: 'https://test.cetoex.org/api/depositLiqudity',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
  
            
  
             if(response.data.status === "success")
             {
              
              fetchMyTable();
  
              toast.success(response.data.msg, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
  
                document.getElementById('depositAmount').value = "";
                setDepositAmount(0);

                
             }
             else{
  
              toast.error(response.data.msg, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
                 
             }
  
  
            
     
               })
          .catch(function (error) {
            console.log(error);
          });

          
        }



    
      


   }


  
    const [dataCryptoTab] = useState([
        {
            id: 1,
            title: 'Pool',
        },
        {
            id: 2,
            title: 'Policy',
        },
        
        
    ])
    return (
        <div>
           
           
                <div className="container">
                    <div className="row">



                   
                            <div className="content-inner buy-crypto__main">

                             <center><h5>Liqudity Control</h5></center>
                               

                                <div  className="main policy-bannner" style={{padding:"30px",}}>
                                <h6>View Pool </h6>
                                <p>Liquidate Token : {`${LiqudityList.liqudatedtoken+" "+LiqudityList.coin}`}</p>
                                <p>Liquidate USDT : {`${LiqudityList.liqudateusdt} USDT`}</p>

                                <form action="/buy-crypto-confirm" className="form">
                                    <div className="form-field">
                                    <label>USDT</label>
                                    <input type="number" className="dollar" placeholder="Enter Pool USDT" value={LiqudityList.usdt}  disabled/>
                                    </div>
                                    <button className="btn-convert" onClick="">
                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                        d="M17.6691 3.02447L15.4471 0.35791C15.3074 0.195998 15.1203 0.106407 14.9261 0.108432C14.7319 0.110457 14.5461 0.203937 14.4088 0.368737C14.2715 0.533538 14.1936 0.756473 14.1919 0.989527C14.1902 1.22258 14.2649 1.44711 14.3998 1.61475L15.3575 2.76403H2.3319C1.74258 2.76403 1.1774 3.04497 0.760683 3.54505C0.34397 4.04512 0.109863 4.72337 0.109863 5.43059L0.109863 8.09714C0.109863 8.33288 0.187899 8.55896 0.326803 8.72566C0.465707 8.89235 0.654102 8.986 0.850542 8.986C1.04698 8.986 1.23538 8.89235 1.37428 8.72566C1.51319 8.55896 1.59122 8.33288 1.59122 8.09714V5.43059C1.59122 5.19485 1.66926 4.96877 1.80816 4.80207C1.94707 4.63538 2.13546 4.54174 2.3319 4.54174H15.3575L14.3998 5.69102C14.329 5.77302 14.2726 5.8711 14.2338 5.97954C14.195 6.08798 14.1745 6.20462 14.1737 6.32264C14.1728 6.44066 14.1916 6.5577 14.2288 6.66694C14.2661 6.77618 14.3211 6.87542 14.3906 6.95888C14.4601 7.04233 14.5428 7.10833 14.6339 7.15302C14.7249 7.19772 14.8224 7.22021 14.9208 7.21918C15.0191 7.21816 15.1163 7.19363 15.2067 7.14705C15.297 7.10047 15.3788 7.03275 15.4471 6.94786L17.6691 4.2813C17.8052 4.11283 17.8812 3.88746 17.8812 3.65288C17.8812 3.41831 17.8052 3.19293 17.6691 3.02447Z"
                                        fill="white"
                                        />
                                        <path
                                        d="M17.1467 8.98828C16.9503 8.98828 16.7619 9.08193 16.623 9.24862C16.4841 9.41531 16.406 9.64139 16.406 9.87713V12.5437C16.406 12.7794 16.328 13.0055 16.1891 13.1722C16.0502 13.3389 15.8618 13.4325 15.6653 13.4325H2.63976L3.59746 12.2832C3.73238 12.1156 3.80704 11.8911 3.80535 11.658C3.80366 11.425 3.72576 11.202 3.58844 11.0372C3.45111 10.8724 3.26534 10.779 3.07113 10.7769C2.87693 10.7749 2.68983 10.8645 2.55014 11.0264L0.328098 13.693C0.191902 13.8613 0.115723 14.0867 0.115723 14.3214C0.115723 14.556 0.191902 14.7814 0.328098 14.9498L2.55014 17.6164C2.61846 17.7012 2.70019 17.769 2.79056 17.8155C2.88092 17.8621 2.97812 17.8866 3.07646 17.8877C3.17481 17.8887 3.27234 17.8662 3.36337 17.8215C3.4544 17.7768 3.53709 17.7108 3.60664 17.6274C3.67618 17.5439 3.73118 17.4447 3.76842 17.3354C3.80567 17.2262 3.82441 17.1092 3.82355 16.9911C3.8227 16.8731 3.80226 16.7565 3.76345 16.648C3.72463 16.5396 3.6682 16.4415 3.59746 16.3595L2.63976 15.2102H15.6653C16.2547 15.2102 16.8199 14.9293 17.2366 14.4292C17.6533 13.9291 17.8874 13.2509 17.8874 12.5437V9.87713C17.8874 9.64139 17.8094 9.41531 17.6704 9.24862C17.5315 9.08193 17.3431 8.98828 17.1467 8.98828Z"
                                        fill="white"
                                        />
                                    </svg>
                                    </button>
                                    <div className="form-field">
                                    <label>{LiqudityList.coin}</label>
                                    <input type="number" className="bitcoin" placeholder="Enter Pool Token"  value={LiqudityList.token}  disabled/>
                                    </div>

                                   
                                </form>

                                <div className="button"></div>
                                </div>
                            </div>



                            <div className="content-inner buy-crypto__main">
                                <div  className="main policy-bannner" style={{padding:"30px",}}>
                                <h6>Deposit Liqudity </h6>
                                <p></p>
                                <p> {LiqudityList.coin} Price : ${LiqudityList.price}</p>

                                <form action="/buy-crypto-confirm" className="form">
                                    <div className="form-field">
                                    <label>USDT</label>
                                    <input type="number" className="dollar" placeholder="Enter Pool USDT"  id="depositAmount"  onChange={(e) => setDepositAmount((e.target.value / LiqudityList.price).toFixed(3))} />
                                    </div>
                                    <button className="btn-convert" onClick="">
                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                        d="M17.6691 3.02447L15.4471 0.35791C15.3074 0.195998 15.1203 0.106407 14.9261 0.108432C14.7319 0.110457 14.5461 0.203937 14.4088 0.368737C14.2715 0.533538 14.1936 0.756473 14.1919 0.989527C14.1902 1.22258 14.2649 1.44711 14.3998 1.61475L15.3575 2.76403H2.3319C1.74258 2.76403 1.1774 3.04497 0.760683 3.54505C0.34397 4.04512 0.109863 4.72337 0.109863 5.43059L0.109863 8.09714C0.109863 8.33288 0.187899 8.55896 0.326803 8.72566C0.465707 8.89235 0.654102 8.986 0.850542 8.986C1.04698 8.986 1.23538 8.89235 1.37428 8.72566C1.51319 8.55896 1.59122 8.33288 1.59122 8.09714V5.43059C1.59122 5.19485 1.66926 4.96877 1.80816 4.80207C1.94707 4.63538 2.13546 4.54174 2.3319 4.54174H15.3575L14.3998 5.69102C14.329 5.77302 14.2726 5.8711 14.2338 5.97954C14.195 6.08798 14.1745 6.20462 14.1737 6.32264C14.1728 6.44066 14.1916 6.5577 14.2288 6.66694C14.2661 6.77618 14.3211 6.87542 14.3906 6.95888C14.4601 7.04233 14.5428 7.10833 14.6339 7.15302C14.7249 7.19772 14.8224 7.22021 14.9208 7.21918C15.0191 7.21816 15.1163 7.19363 15.2067 7.14705C15.297 7.10047 15.3788 7.03275 15.4471 6.94786L17.6691 4.2813C17.8052 4.11283 17.8812 3.88746 17.8812 3.65288C17.8812 3.41831 17.8052 3.19293 17.6691 3.02447Z"
                                        fill="white"
                                        />
                                        <path
                                        d="M17.1467 8.98828C16.9503 8.98828 16.7619 9.08193 16.623 9.24862C16.4841 9.41531 16.406 9.64139 16.406 9.87713V12.5437C16.406 12.7794 16.328 13.0055 16.1891 13.1722C16.0502 13.3389 15.8618 13.4325 15.6653 13.4325H2.63976L3.59746 12.2832C3.73238 12.1156 3.80704 11.8911 3.80535 11.658C3.80366 11.425 3.72576 11.202 3.58844 11.0372C3.45111 10.8724 3.26534 10.779 3.07113 10.7769C2.87693 10.7749 2.68983 10.8645 2.55014 11.0264L0.328098 13.693C0.191902 13.8613 0.115723 14.0867 0.115723 14.3214C0.115723 14.556 0.191902 14.7814 0.328098 14.9498L2.55014 17.6164C2.61846 17.7012 2.70019 17.769 2.79056 17.8155C2.88092 17.8621 2.97812 17.8866 3.07646 17.8877C3.17481 17.8887 3.27234 17.8662 3.36337 17.8215C3.4544 17.7768 3.53709 17.7108 3.60664 17.6274C3.67618 17.5439 3.73118 17.4447 3.76842 17.3354C3.80567 17.2262 3.82441 17.1092 3.82355 16.9911C3.8227 16.8731 3.80226 16.7565 3.76345 16.648C3.72463 16.5396 3.6682 16.4415 3.59746 16.3595L2.63976 15.2102H15.6653C16.2547 15.2102 16.8199 14.9293 17.2366 14.4292C17.6533 13.9291 17.8874 13.2509 17.8874 12.5437V9.87713C17.8874 9.64139 17.8094 9.41531 17.6704 9.24862C17.5315 9.08193 17.3431 8.98828 17.1467 8.98828Z"
                                        fill="white"
                                        />
                                    </svg>
                                    </button>
                                    <div className="form-field">
                                    <label>{LiqudityList.coin}</label>
                                    <input type="number" className="bitcoin"  placeholder="Calculating.. Token" value={depositAmount}   disabled/>
                                    </div>
                                    
                                    <button  className="btn-action" onClick={makeDeposit}>Make Deposit</button>
                                   
                                </form>

                                <div className="button"></div>
                                </div>
                            </div>






                            <div className="content-inner buy-crypto__main">
                                <div  className="main policy-bannner" style={{padding:"30px",}}>
                                <h6>Withdraw Liqudity </h6>
                               
                                <p>Liqudit USDT : {`${LiqudityList.usdt} USDT`}</p>
                               <p>Liqudit Token : {`${LiqudityList.token+" "+LiqudityList.coin}`}</p>
                               <p> {LiqudityList.coin} Price :  ${LiqudityList.price} </p>

                                <form action="/buy-crypto-confirm" className="form">
                                    <div className="form-field">
                                    <label>USDT</label>
                                    <input type="number" className="dollar" placeholder="Enter Pool USDT" id="withdrawAmount"  onChange={(e) => setWithdrawAmount((e.target.value / LiqudityList.price).toFixed(3))}/>
                                    </div>
                                    <button className="btn-convert" onClick="">
                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                        d="M17.6691 3.02447L15.4471 0.35791C15.3074 0.195998 15.1203 0.106407 14.9261 0.108432C14.7319 0.110457 14.5461 0.203937 14.4088 0.368737C14.2715 0.533538 14.1936 0.756473 14.1919 0.989527C14.1902 1.22258 14.2649 1.44711 14.3998 1.61475L15.3575 2.76403H2.3319C1.74258 2.76403 1.1774 3.04497 0.760683 3.54505C0.34397 4.04512 0.109863 4.72337 0.109863 5.43059L0.109863 8.09714C0.109863 8.33288 0.187899 8.55896 0.326803 8.72566C0.465707 8.89235 0.654102 8.986 0.850542 8.986C1.04698 8.986 1.23538 8.89235 1.37428 8.72566C1.51319 8.55896 1.59122 8.33288 1.59122 8.09714V5.43059C1.59122 5.19485 1.66926 4.96877 1.80816 4.80207C1.94707 4.63538 2.13546 4.54174 2.3319 4.54174H15.3575L14.3998 5.69102C14.329 5.77302 14.2726 5.8711 14.2338 5.97954C14.195 6.08798 14.1745 6.20462 14.1737 6.32264C14.1728 6.44066 14.1916 6.5577 14.2288 6.66694C14.2661 6.77618 14.3211 6.87542 14.3906 6.95888C14.4601 7.04233 14.5428 7.10833 14.6339 7.15302C14.7249 7.19772 14.8224 7.22021 14.9208 7.21918C15.0191 7.21816 15.1163 7.19363 15.2067 7.14705C15.297 7.10047 15.3788 7.03275 15.4471 6.94786L17.6691 4.2813C17.8052 4.11283 17.8812 3.88746 17.8812 3.65288C17.8812 3.41831 17.8052 3.19293 17.6691 3.02447Z"
                                        fill="white"
                                        />
                                        <path
                                        d="M17.1467 8.98828C16.9503 8.98828 16.7619 9.08193 16.623 9.24862C16.4841 9.41531 16.406 9.64139 16.406 9.87713V12.5437C16.406 12.7794 16.328 13.0055 16.1891 13.1722C16.0502 13.3389 15.8618 13.4325 15.6653 13.4325H2.63976L3.59746 12.2832C3.73238 12.1156 3.80704 11.8911 3.80535 11.658C3.80366 11.425 3.72576 11.202 3.58844 11.0372C3.45111 10.8724 3.26534 10.779 3.07113 10.7769C2.87693 10.7749 2.68983 10.8645 2.55014 11.0264L0.328098 13.693C0.191902 13.8613 0.115723 14.0867 0.115723 14.3214C0.115723 14.556 0.191902 14.7814 0.328098 14.9498L2.55014 17.6164C2.61846 17.7012 2.70019 17.769 2.79056 17.8155C2.88092 17.8621 2.97812 17.8866 3.07646 17.8877C3.17481 17.8887 3.27234 17.8662 3.36337 17.8215C3.4544 17.7768 3.53709 17.7108 3.60664 17.6274C3.67618 17.5439 3.73118 17.4447 3.76842 17.3354C3.80567 17.2262 3.82441 17.1092 3.82355 16.9911C3.8227 16.8731 3.80226 16.7565 3.76345 16.648C3.72463 16.5396 3.6682 16.4415 3.59746 16.3595L2.63976 15.2102H15.6653C16.2547 15.2102 16.8199 14.9293 17.2366 14.4292C17.6533 13.9291 17.8874 13.2509 17.8874 12.5437V9.87713C17.8874 9.64139 17.8094 9.41531 17.6704 9.24862C17.5315 9.08193 17.3431 8.98828 17.1467 8.98828Z"
                                        fill="white"
                                        />
                                    </svg>
                                    </button>
                                    <div className="form-field">
                                    <label>{LiqudityList.coin}</label>
                                    <input type="number" className="bitcoin" placeholder="Calculating.. Token" value={withdawAmount}   disabled/>
                                    </div>

                                    <button  className="btn-action" onClick={makeWithdraw}>Make Withdraw</button>
                                </form>

                                <div className="button"></div>
                                </div>
                            </div>


                          
                    </div>
                </div>
          
            
        </div>
    );
}

export default Liquidity;
