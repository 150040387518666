import React , {useState,useEffect} from 'react';
import Sale01 from '../components/sale/Sale01';
import './fee.scss';
import axios from 'axios';
import { Bars } from  'react-loader-spinner'
import { Helmet } from 'react-helmet';

function Fee(props) {

    
    const [feeList, setFeeList] = useState([]);
    const [depositList, setDepositList] = useState([]);
   

    useEffect(() => {
        fetchMyTable()
      }, [])
  
  
  
   async function fetchMyTable() {
          const response =  await axios.get(`https://test.cetoex.org/api/tradingfee`);
          setFeeList(response.data)


          const response1 =  await axios.get(`https://test.cetoex.org/api/depositfee`);
          setDepositList(response1.data)



    }


    return (
        <div>

                                                            
<Helmet>
        <title> Fee Schedule | Cetoex</title>
        <meta name="description" content="Explore Our Transparent Fee Structure for Trading on Cetoex. Learn About Trading Fees, Withdrawal Costs, and More to Make Informed Decisions."/>
        <meta name="keywords" content="crypto market, crypto deposit history, cetoex, exchange, top ranking coin, top volume, newly listed, top gainers, top losers, cetoex exchange, spot, trading, crypto"/>
        <meta name="author" content="Cetoex"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        
        {/* Open Graph (OG) tags for sharing on social media */}
        <meta property="og:title" content="Fee Schedule | Cetoex"/>
        <meta property="og:description" content="Explore Our Transparent Fee Structure for Trading on Cetoex. Learn About Trading Fees, Withdrawal Costs, and More to Make Informed Decisions."/>
        
        {/* Twitter card tags for sharing on Twitter */}
        <meta name="twitter:card" content="cetoex"/>
        <meta name="twitter:title" content=" Fee Schedule | Cetoex"/>
        <meta name="twitter:description" content="Explore Our Transparent Fee Structure for Trading on Cetoex. Learn About Trading Fees, Withdrawal Costs, and More to Make Informed Decisions."/>
        <meta name="twitter:image" content="https://pbs.twimg.com/profile_images/1556224061248008192/KQTT5Qk3_400x400.jpg"/>
        
      </Helmet>


            {/* <PageTitle heading='Trading Fee' title='Trading Fee' /> */}
          
            <section className="faq">
            <div className="container">
                <div className="row">



                <div className="col-md-12">
                    <div className="block-text center">
                    <h3 className="heading">CetoEX Fee Schedule</h3>
                    <p className="desc fs-20">Please make sure that you are visiting <span style={{color:"#3773ff"}}>https://www.cetoex.com</span></p>
                 <br/>   </div>
                </div>








                <div className="col-md-12">
                    <h6>1. Deposit Fee</h6>
                <table className="table-reset feetable" cellSpacing={0} cellPadding={0}>
  <tbody>
    <tr>
      <th>Coin / Token</th>
      <th>Minimum Deposit</th>
      <th>Deposit Fee</th>
    </tr>




    {
                depositList && depositList.length>0 ?
                depositList.map((idx, i) =>
                    

    <tr>
        <td>

        <img
          src={idx.coin_logo}
          style={{
            borderRadius: "50%",
            width: 20,
            height: 20,
            marginTop: 2,
            display: "flex",
            textAlign: "center"
          }}
          alt="coin" />
        <span style={{ paddingBottom: 20 }}>
          <b>{idx.coin_name}</b>
        </span>
      </td>




      <td>{idx.coin_symbol+" "+idx.deposit}</td>
      <td>Free (0%)</td>
    </tr>
  
  ):(
    <>
    <tr>
        <td colSpan="3">
    <center>   <div style={{marginLeft:"45%",paddingTop:"50px",paddingBottom:"50px"}}>
   <Bars
height = "40"
width = "40"
radius = "9"
color = '#3375fe'
ariaLabel = 'three-dots-loading'  
marginLeft='200'   
wrapperStyle
wrapperClass
/>
</div></center>
</td>
</tr>
    </>
)
}

  </tbody>
</table>


                </div>



                <div className="col-md-12"  style={{marginTop:"40px"}}> 
                    <h6>2. Withdrawal Fee</h6>
                <table className="table-reset feetable" cellSpacing={0} cellPadding={0}>
  <tbody>
    <tr>
      <th>Network</th>
      <th>Min. Withdrawal($)</th>
      <th>Withdrawal Fee</th>
    </tr>
    <tr>
      <td>
        <img
          src="https://s2.coinmarketcap.com/static/img/coins/32x32/1027.png"
          style={{
            borderRadius: "50%",
            width: 20,
            height: 20,
            marginTop: 2,
            display: "flex",
            textAlign: "center"
          }}
          alt="coin" />
        <span style={{ paddingBottom: 20 }}>
          <b>Ethereum Coin</b>
        </span>
      </td>
      <td>$10</td>
      <td>0.009 ETH</td>
    </tr>
    <tr>
      <td>
        <img
          src="https://s2.coinmarketcap.com/static/img/coins/32x32/1027.png"
          style={{
            borderRadius: "50%",
            width: 20,
            height: 20,
            marginTop: 2,
            display: "flex",
            textAlign: "center"
          }}
          alt="coin" />
        <span style={{ paddingBottom: 20 }}>
          <b>Ethereum Token</b>
        </span>
      </td>
      <td>$10</td>
      <td>0.009 ETH</td>
    </tr>
    <tr>
      <td>
        <img
          src="https://s2.coinmarketcap.com/static/img/coins/32x32/1839.png"
          style={{
            borderRadius: "50%",
            width: 20,
            height: 20,
            marginTop: 2,
            display: "flex",
            textAlign: "center"
          }}
         alt="coin"/>
        <span style={{ paddingBottom: 20 }}>
          <b>Binance Smart Chain (BEP20 Coin)</b>
        </span>
      </td>
      <td>$10</td>
      <td>0.0008 BNB</td>
    </tr>
    <tr>
      <td>
        <img
          src="https://s2.coinmarketcap.com/static/img/coins/32x32/1839.png"
          style={{
            borderRadius: "50%",
            width: 20,
            height: 20,
            marginTop: 2,
            display: "flex",
            textAlign: "center"
          }}
          alt="coin"/>
        <span style={{ paddingBottom: 20 }}>
          <b>Binance Smart Chain (BEP20 Token)</b>
        </span>
      </td>
      <td>$10</td>
      <td>0.001 BNB</td>
    </tr>
    <tr>
      <td>
        <img
          src="https://s2.coinmarketcap.com/static/img/coins/32x32/1958.png"
          style={{
            borderRadius: "50%",
            width: 20,
            height: 20,
            marginTop: 2,
            display: "flex",
            textAlign: "center"
          }}
          alt="coin"/>
        <span style={{ paddingBottom: 20 }}>
          <b>Tron Network (Coin)</b>
        </span>
      </td>
      <td>$10</td>
      <td>5 TRX</td>
    </tr>
    <tr>
      <td>
        <img
          src="https://s2.coinmarketcap.com/static/img/coins/32x32/1958.png"
          style={{
            borderRadius: "50%",
            width: 20,
            height: 20,
            marginTop: 2,
            display: "flex",
            textAlign: "center"
          }}
          alt="coin"/>
        <span style={{ paddingBottom: 20 }}>
          <b>Tron Network (TRC20 Token)</b>
        </span>
      </td>
      <td>$10</td>
      <td>15 TRX</td>
    </tr>
    <tr>
      <td>
        <img
          src="https://s2.coinmarketcap.com/static/img/coins/64x64/22470.png"
          style={{
            borderRadius: "50%",
            width: 20,
            height: 20,
            marginTop: 2,
            display: "flex",
            textAlign: "center"
          }}
          alt="coin"/>
        <span style={{ paddingBottom: 20 }}>
          <b>BabaCoin Network (Coin)</b>
        </span>
      </td>
      <td>$10</td>
      <td>1000 BBC</td>
    </tr>
    <tr>
      <td>
        <img
          src="https://s2.coinmarketcap.com/static/img/coins/64x64/12661.png"
          style={{
            borderRadius: "50%",
            width: 20,
            height: 20,
            marginTop: 2,
            display: "flex",
            textAlign: "center"
          }}
          alt="coin"/>
        <span style={{ paddingBottom: 20 }}>
          <b>HashBit Blockchain (Coin)</b>
        </span>
      </td>
      <td>$10</td>
      <td>1000 HBIT</td>
    </tr>
  </tbody>
</table>


                </div>









                <div className="col-md-12" style={{marginTop:"40px"}}>
                    <h6>3. Trading Fee (Buy/Sell)</h6>
                <table className="table-reset feetable" cellSpacing={0} cellPadding={0}>
  <tbody>
    <tr>
      <th>Coin / Token</th>
      <th>Maker Fee (%)</th>
      <th>Taker Fee (%)</th>
    </tr>




    {
                feeList && feeList.length>0 ?
                feeList.map((idx, i) =>
                    

    <tr>
        <td>

        <img
          src={idx.coin_logo}
          style={{
            borderRadius: "50%",
            width: 20,
            height: 20,
            marginTop: 2,
            display: "flex",
            textAlign: "center"
          }}
          alt="coin" />
        <span style={{ paddingBottom: 20 }}>
          <b>{idx.coin_name}</b>
        </span>
      </td>




      <td>0.15%</td>
      <td>0.15%</td>
    </tr>
  
  ):(
    <>
    <tr>
        <td colSpan="3">
    <center>   <div style={{marginLeft:"45%",paddingTop:"50px",paddingBottom:"50px"}}>
   <Bars
height = "40"
width = "40"
radius = "9"
color = '#3375fe'
ariaLabel = 'three-dots-loading'  
marginLeft='200'   
wrapperStyle
wrapperClass
/>
</div></center>
</td>
</tr>
    </>
)
}

  </tbody>
</table>


                </div>



        
             
                </div>
            </div>
            </section>

            <Sale01 />
            
        </div>
    );
}

export default Fee;