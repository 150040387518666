import React , {useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Sale01 from '../components/sale/Sale01';
import PageTitle from '../components/pagetitle';
import Cookies1 from 'js-cookie';
import { useEffect } from 'react';
import './userProfile.scss';
import { Bars } from  'react-loader-spinner'
import PieChart from '../components/piechart/piechart'
import { Helmet } from 'react-helmet';


function Wallet(props) {


    const [WalletList, setWalletList] = useState([]);
    const [pieData, setPieChart] = useState([]);
 



    const navigate = useNavigate();

    useEffect(() => {
    
      
        const token = Cookies1.get('LoginToken');
     
        if(token != null)
        {
         fetchMyTable()
        } 
        else{
          navigate('/login');
        }    
      }, [])
  
  
  
   async function fetchMyTable() {

    const accountUserId = Cookies1.get('investorId');
  

      var data = JSON.stringify({
       "investorId": accountUserId
     });
     
     var config = {
       method: 'post',
       url: 'https://test.cetoex.org/api/wallet1',
       headers: { 
         'Content-Type': 'application/json'
       },
       data : data
     };
     
     axios(config)
     .then(function (response) {
       setWalletList(response.data.InvestmentData);

       const extractedData = response.data.InvestmentData.map(item => [item.walletSymbol, item.walletInvest]);

            // Adding the header ['Crypto', 'Percentage'] to the new array
            extractedData.unshift(['Crypto', 'Percentage']);
            setPieChart(extractedData)

          })
     .catch(function (error) {
       console.log(error);
     });
  
    }





    return (
        <div>

<Helmet>
        <title>Crypto Wallet | Cetoex</title>
        <meta name="description" content="Safeguard and Manage Your Cryptocurrencies with the Official Cetoex Wallet. Enjoy Secure Storage and Convenient Access to Your Digital Assets."/>
        <meta name="keywords" content="crypto market, crypto deposit history, cetoex, exchange, top ranking coin, top volume, newly listed, top gainers, top losers, cetoex exchange, spot, trading, crypto"/>
        <meta name="author" content="Cetoex"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        
        {/* Open Graph (OG) tags for sharing on social media */}
        <meta property="og:title" content="Crypto Wallet | Cetoex"/>
        <meta property="og:description" content="Safeguard and Manage Your Cryptocurrencies with the Official Cetoex Wallet. Enjoy Secure Storage and Convenient Access to Your Digital Assets."/>
        
        {/* Twitter card tags for sharing on Twitter */}
        <meta name="twitter:card" content="cetoex"/>
        <meta name="twitter:title" content="Crypto Wallet | Cetoex"/>
        <meta name="twitter:description" content="Safeguard and Manage Your Cryptocurrencies with the Official Cetoex Wallet. Enjoy Secure Storage and Convenient Access to Your Digital Assets."/>
       
      </Helmet>
            <br/> <br/>
            <section className="wallet m-2">
                <div className="container">
                    <div className="row">
                    <Tabs>
                           

                            <TabPanel>
                            <div className="content-inner" >

                   

                            
                                <div className="row">
                               <div className="coin-list-wallet col-xl-6 col-lg-6 col-md-6 col-sm-12 p-4 m-1">
                               <div>
                                    <PieChart data={pieData} />
                                </div>
                                </div>
                              


                                <div className="coin-list-wallet  col-xl-5  col-lg-5 col-md-5 col-sm-12  m-1"  style={{height:"650px",overflowY:"auto"}}>
                                <table className="table">
                                    <thead>
                                    <tr>
                                   
                                        <th   style={{marginLeft:"20px",textAlign:"left",color:"var(--tablemain)"}}>Crypto Assets</th>
                                        <th scope="col"  style={{color:"var(--tablemain)"}}>Amount</th>
                                       
                                       
                                    </tr>
                                    </thead>
                                    <tbody>
                                   
                            {
                WalletList && WalletList.length>0 ?
                WalletList.map((idx, i) =>
                    
                <tr>
               
                <td className="asset">
                <span className="icon-btc"
                    >
            <img src={idx.walletLogo} style={{width:"35px",borderRadius:"50%",marginLeft:"10px"}} alt="coin_symbol"/>
                    </span>
                <p>
                    <span className="boild">{idx.walletSymbol}</span>
                    <span className="unit">{idx.walletName}</span>
                </p>
                </td>
               
                <td>
                <span className="boild">{idx.currentInvestment+" "+idx.walletSymbol}</span>
                <span className="unit">${idx.walletInvest}</span>
                </td>
               
               
            </tr>
           

                ):(
                    <>
                    <tr>
                        <td colspan="4">
                    <center>   <div style={{marginLeft:"45%"}}>
                   <Bars
    height = "40"
    width = "40"
    radius = "9"
    color = '#3375fe'
    ariaLabel = 'three-dots-loading'  
    marginLeft='200'   
    wrapperStyle
    wrapperClass
  />
  </div></center>
  </td>
  </tr>
                    </>
                )
                 }


                                   
                                    </tbody>
                                </table>
                                </div>

                               

                                </div>
                            </div>
                            </TabPanel>
  
                            

                    </Tabs> 

                    </div>
                </div>
            </section>

            <Sale01 />
            
        </div>
    );
}

export default Wallet;