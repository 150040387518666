import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Pagination, Scrollbar, A11y, Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import ContentLoader from 'react-content-loader';

const MyLoader = () => (
  <ContentLoader backgroundColor="var(--sketon)" foregroundColor="var(--forecolor)">
    <rect x="0" y="0" width="21em" height="13em" rx="5" ry="5" />
  </ContentLoader>
);

function Crypto02() {
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://test.cetoex.org/api/feature');
        setUserList(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData(); // Initial fetch

    const interval = setInterval(fetchData, 10000); // Fetch data every 10 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []); // Empty dependency array to run effect only once

  return (
    <section className="crypto">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h6 style={{ paddingLeft: '10px' }}>Activities Updates:</h6>
            <div
              style={{
                border: '1px solid var(--bannercolor)',
                padding: '15px',
                backgroundColor: 'var(--bannercolor)',
                borderRadius: '7px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
              }}
            >
              <Swiper
                style={{
                  '--swiper-navigation-size': '15px',
                  '--swiper-navigation-background-color': '#3774ff',
                }}
                modules={[Navigation, Pagination, Autoplay, Scrollbar, A11y]}
                spaceBetween={20}
                slidesPerView={4}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  0: { slidesPerView: 1 },
                  300: { slidesPerView: 1 },
                  500: { slidesPerView: 2 },
                  639: { slidesPerView: 2 },
                  865: { slidesPerView: 3 },
                  1000: { slidesPerView: 3 },
                  1500: { slidesPerView: 4 },
                  1700: { slidesPerView: 4 },
                }}
                navigation
              >
                {userList.map((user, index) => (
                  <SwiperSlide key={index}>
                    {isLoading ? (
                      <MyLoader />
                    ) : (
                      <img
                        src={`https://cetoex.com/banner/${user.banner_photo}`}
                        alt="coin"
                        style={{ width: '100%', borderRadius: '2px', height: '11em' }}
                      />
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Crypto02;
