import React , {useState} from 'react';
import PropTypes from 'prop-types';
import { FaGooglePlay, FaAppStoreIos } from 'react-icons/fa';
import img from '../../assets/images/layout/Illustration.png'
import img1 from '../../assets/images/icon/discovery.png'
import img2 from '../../assets/images/icon/change.png'
import img3 from '../../assets/images/icon/adduser.png'
import './about.scss';
import appScreenshots from './screen.png';

About02.propTypes = {
    data: PropTypes.array,
};

function About02(props) {

    const {data} = props;

    const [dataBlock] = useState(
        {
            heading: 'We are the most trusted cryptocurrency platform.',
            desc : 'We believe Cryptolly is here to stay — and that a future worth building is one which opens its doors and invites everyone in.',
        }
    );
    const [dataTrader] = useState([
        {
            id: 1,
            icon: img1,
            numb: '198+',
            text: 'Countries',
        },
        {
            id: 2,
            icon: img2,
            numb: '350+',
            text: 'Trading Pairs',
        },
        {
            id: 3,
            icon: img3,
            numb: '20 million+',
            text: 'Trades',
        },
    ]
    );
    return (
        <section className="join">
        <div className="container">
            <div className="row">
                <div className="download-section">
      <div className="left">
        <h2>Download CetoEX App</h2>
        <p>Empower your world with our app - download now and experience the crypto market at your fingertips!</p>
        <div className="app-icons">
        <div className="app-store-buttons">
      <a href="https://play.google.com/store/apps/details?id=com.cetoex.cetoex" target="_blank" rel="noopener noreferrer" className="play-store-button">
        <FaGooglePlay size={20} />
        &nbsp;&nbsp;Google Play
      </a>
      <a href="https://apps.apple.com/your-app-url" target="_blank" rel="noopener noreferrer" className="app-store-button">
        <FaAppStoreIos size={20} />
        &nbsp;&nbsp; App Store
      </a>
    </div>

        </div>
       
      </div>
      <div className="right">
        <div className="app-screenshots">
          <img src={appScreenshots} alt="App Screenshots" width="80%"/>
        </div>
      </div>
    </div>
                </div>
            </div>
        </section>
    );
}

export default About02;