import React, { useEffect, useRef, useState } from 'react';
import { widget } from '../../charting_library';
import ChartHeader from '../ChartHeader/chartheader';
import ChartHeaderMobile from '../ChartHeader/chartheadermobile';
import { useMediaQuery } from 'react-responsive';



export default function App(prop1) {
  const chartContainerRef = useRef();
   const theme1 = localStorage.getItem("theme");
   const themeColor =	theme1 === "is_dark" ? "dark" : "light"
         
  

	const defaultProps = {
		symbol: `${prop1.data}USDT`,
		interval: '15',
		datafeedUrl: 'https://test.cetoex.org/api/tradingview',
		libraryPath: '/charting_library/',
		chartsStorageUrl: 'https://saveload.tradingview.com',
		chartsStorageApiVersion: '1.1',
		clientId: 'tradingview.com',
		userId: 'public_user_id',
		timeframe:'720',
		fullscreen: false,
		autosize: true,
		locale: "en",
   
		            disabled_features: ["save_chart_properties_to_local_storage","delete_button_in_legend","header_settings","header_symbol_search","header_compare","volume_force_overlay", 'timeframes_toolbar', 'edit_buttons_in_legend', 'context_menus', 'border_around_the_chart'],
					enabled_features: ["study_templates","hide_left_toolbar_by_default","hide_unresolved_symbols_in_legend"],
					overrides: {
						"mainSeriesProperties.style": 1,
						"volumePaneSize": "medium",
					},
                	studies_overrides: {
						"volume.volume.color.0": "#f23645",
						"volume.volume.color.1": "#079981",
						"volume.volume.transparency": 70,
						"volume.volume ma.color": "#FF0000",
						"volume.volume ma.transparency": 30,
						"volume.volume ma.linewidth": 5,
						"volume.volume ma.visible": true,
						"bollinger bands.median.color": "#33FF88",
						"bollinger bands.upper.linewidth": 7,
						"moving average exponential.plot.color": "black",
					
						
                    },
					debug: false,
					client_id: 'tradingview.com',
					user_id: 'public_user',
					favorites: {
						intervals: ["5", "15", "60", "360","W","M"],
						chartTypes: ["Candles"]
					},
					
	};

  useEffect(() => {

	

    const widgetOptions = {
			symbol: defaultProps.symbol,
			// BEWARE: no trailing slash is expected in feed URL
			datafeed: new window.Datafeeds.UDFCompatibleDatafeed(defaultProps.datafeedUrl),
			interval: defaultProps.interval,
			container: chartContainerRef.current,
			library_path: defaultProps.libraryPath,
            locale: 'en',
			disabled_features:  defaultProps.disabled_features,
			enabled_features: defaultProps.enabled_features,
			charts_storage_url: defaultProps.chartsStorageUrl,
			charts_storage_api_version: defaultProps.chartsStorageApiVersion,
			client_id: defaultProps.clientId,
			user_id: defaultProps.userId,
			fullscreen: defaultProps.fullscreen,
			autosize: defaultProps.autosize,
			studies_overrides: defaultProps.studiesOverrides,
			overrides:defaultProps.overrides,
			favorites:defaultProps.favorites,
			allow_symbol_change: false,
			timeframe:defaultProps.timeframe,
			theme:themeColor,
			
			
			
			
		};

		const tvWidget = new widget(widgetOptions);

		tvWidget.onChartReady(() => {
			tvWidget.headerReady().then(() => {
				const button = tvWidget.createButton();
				button.setAttribute('title', 'Click to show ETH Details');
				button.classList.add('apply-common-tooltip');
				button.addEventListener('click', () => tvWidget.showNoticeDialog({
					title: 'Notification',
					body: 'Welcome To Cetoex Technical Analysis',
					callback: () => {
						console.log('Noticed!');
					},
				}));

				button.innerHTML = 'CetoEX';
			});
		});
		




		return () => {
			tvWidget.remove();
		};
   
  },[themeColor]);

  

  const isMobile = useMediaQuery({ maxWidth: 667 });

  return (
      <>
     <div style={{marginTop:"10px"}}>
	 {isMobile ? <ChartHeaderMobile   data={prop1.data}/> :  <ChartHeader data={prop1.data}/>}
   
     <div style={{border:"1px solid #bbb",borderRadius:"2px"}}>

    <div style={{height:"450px"}}
			ref={chartContainerRef}
			className={'TVChartContainer'}
		/>

    </div>

	<p className="p-1" style={{fontSize:"9px",fontWeight:"bold"}}>TradingView is a charting platform that offers advanced charting and great tools for market analysis, <a  style={{fontSize:"9px",fontWeight:"bold"}} href="https://www.tradingview.com/economic-calendar/" target="_blank">economic calendar </a>  and <a  style={{fontSize:"9px",fontWeight:"bold"}} href="https://www.tradingview.com/screener/" target="_blank">stock screener</a> to find and filter stocks</p>

	</div>
    </>
  );
}
