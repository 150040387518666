import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-modal-video/scss/modal-video.scss';
import 'react-tabs/style/react-tabs.css';
import './trade.scss';
import Chart from '../components/chart/chart';
import Orderbook from '../components/orderbook/orderbook';
import Buysell from '../components/BuySell/buysell';
import Trades from '../components/Trades/trades';
import Orders from '../components/Orders/Orders';
import { useParams, useNavigate } from 'react-router-dom';
import Assets from '../components/assets/assets';
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive';

function Trade(props) {
  const { id } = useParams();
  const symbolParameter = id.replace("USDT", "");
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 667 });
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div>
      <Helmet>
        <title>{symbolParameter}/USDT Trade | Cetoex</title>
        <meta
          name="description"
          content="Experience seamless and secure cryptocurrency trading on our platform. Buy, sell, and exchange a wide range of digital assets with real-time market data."
        />
        <meta
          name="keywords"
          content="crypto market, crypto deposit history, cetoex, exchange, top ranking coin, top volume, newly listed, top gainers, top losers, cetoex exchange, spot, trading, crypto"
        />
        <meta name="author" content="Cetoex" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        {/* Open Graph (OG) tags for sharing on social media */}
        <meta property="og:title" content={`${symbolParameter}/USDT Trade | Cetoex Exchange`} />
        <meta
          property="og:description"
          content="Experience seamless and secure cryptocurrency trading on our platform. Buy, sell, and exchange a wide range of digital assets with real-time market data."
        />

        {/* Twitter card tags for sharing on Twitter */}
        <meta name="twitter:card" content="cetoex" />
        <meta name="twitter:title" content={`${symbolParameter}/USDT Trade | Cetoex Exchange`} />
        <meta
          name="twitter:description"
          content="CetoEx Global Services Crypto with unlimited access #bitcoin, #Eth & 100+ #altcoin"
        />
        <meta name="twitter:image" content="https://cetoex.com/favicon-banner.jpg" />
      </Helmet>

      {isMobile ? (
        <section>
          <div className="mainpage">
            <div className="row">
              <div className="col-xl-3 col-md-12">
                <Assets />
              </div>
              <Tabs
                selectedTabClassName="tab-design"
                selectedIndex={activeTab}
                onSelect={(index) => setActiveTab(index)}
              >
                <TabList className="my-1" id="tab-basic">
                  <Tab>Chart</Tab>
                  <Tab>Orderbook</Tab>
                  <Tab>Trades</Tab>
                </TabList>
                <TabPanel>
                  <Chart data={symbolParameter} />
                </TabPanel>
                <TabPanel>
                  <Orderbook data={symbolParameter} />
                </TabPanel>
                <TabPanel>
                  <Trades data={symbolParameter} />
                </TabPanel>
              </Tabs>
              <div className="col-xl-6 col-md-12">
                <Buysell data={symbolParameter} />
              </div>
              <div className="col-xl-3 col-md-12">
                <Orders data={symbolParameter} />
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section>
          <div className="mainpage">
            <div className="row">
              <div className="col-xl-3 col-md-12">
                <Assets />
              </div>
              <div className="col-xl-6 col-md-12">
                <Chart data={symbolParameter} />
              </div>
              <div className="col-xl-3 col-md-12">
                <Orderbook data={symbolParameter} />
              </div>
              <div className="col-xl-3 col-md-12">
                <Orders data={symbolParameter} />
              </div>
              <div className="col-xl-6 col-md-12">
                <Buysell data={symbolParameter} />
              </div>
              <div className="col-xl-3 col-md-12">
                <Trades data={symbolParameter} />
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default Trade;
