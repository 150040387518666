import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Button from '../button';
import { Bars } from  'react-loader-spinner'
import img from '../../assets/images/layout/banner-02.png'
import ReloadableLink from '../../pages/Reload';

function Banner02(props) {
    const [dataBlock] = useState(
        {
            heading: 'Most trusted & secure crypto exchange.',
            desc : 'A cryptocurrency exchange platform that offers a secure, user-friendly and efficient way to buy, sell and trade digital currencies. At CetoEx, we understand the importance of accessibility, transparency and security in the crypto market. ',
        }
    );
    return (
        <section className="banner">
            <div className="container">
                <div className="row">
                <div className="col-xl-6 col-md-12">
                    <div className="banner__content">
                        <h2 className="title">
                            {dataBlock.heading}
                        </h2>
                        <p className="fs-20 desc">
                            {dataBlock.desc}
                        </p>
                       <ReloadableLink to="/trade/BTCUSDT"> <Button title='Trading Now' /></ReloadableLink>
                    </div>
                </div>
                <div className="col-xl-6 col-md-12">
                    <div className="banner__image">
                    <img src={img} alt="CetoEX" />
                    </div>
                </div>
                </div>
            </div>
            </section>
    );
}

export default Banner02;