import React , {useEffect, useState} from 'react';
import img1 from '../../assets/images/layout/banner-01.png'
import './banner.scss'
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import LaunchPad from '../launchpad/Launchpad';


function Banner01(props) {

    useEffect(()=>{
     

    });
  
    const [dataBlock] = useState(
        {
            title: 'Crypto LaunchPad',
            desc : 'CetoEX launchpad provide investors and participants with early access to promising cryptocurrency projects. These projects often have high growth potential and may offer unique features or innovations. Launchpads curate and vet projects, giving users the opportunity to invest in or participate in the early stages of potentially successful ventures.',
            title2: 'COMMING IN'
        }
    );
    return (
      <>
        <section className="banner">
                <div className="container">
                    <div className="row">
                    <div className="col-xl-7 col-md-12">
                        <div className="banner__content">
                        <h2 className="title">{dataBlock.title}</h2>
                        <p className="fs-20 desc">
                            {dataBlock.desc}
                        </p>
                       
                        </div>
                    </div>
                    <div className="col-xl-5 col-md-12">
                        <div className="banner__image">
                        <img src={img1} alt="launchpad"  style={{width:"80%"}}/>
                        </div>
                    </div>
                    </div>
                    
                </div>
                
            </section>
            <LaunchPad/>
          
            </>
    );
}

export default Banner01;