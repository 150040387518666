import React , {useState} from 'react';
import 'react-modal-video/scss/modal-video.scss' ;
import Trading from '../components/trading/Trading';
import { Helmet } from 'react-helmet';
import Sale01 from '../components/sale/Sale01';
import dataTrading from '../assets/fake-data/data-trading';

function About(props) {

  

    return (
        <div>

                                                      
<Helmet>
        <title>About CetoEX Exchange | Cetoex</title>
        <meta name="description" content="Discover the Vision and Values of Cetoex. We're Committed to Revolutionizing the Financial Landscape through Innovative Solutions and Trusted Services."/>
        <meta name="keywords" content="crypto market, crypto deposit history, cetoex, exchange, top ranking coin, top volume, newly listed, top gainers, top losers, cetoex exchange, spot, trading, crypto"/>
        <meta name="author" content="Cetoex"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        
        {/* Open Graph (OG) tags for sharing on social media */}
        <meta property="og:title" content="About CetoEX Exchange | Cetoex"/>
        <meta property="og:description" content="Discover the Vision and Values of Cetoex. We're Committed to Revolutionizing the Financial Landscape through Innovative Solutions and Trusted Services."/>
        
        {/* Twitter card tags for sharing on Twitter */}
        <meta name="twitter:card" content="cetoex"/>
        <meta name="twitter:title" content="About CetoEX Exchange | Cetoex"/>
        <meta name="twitter:description" content="Discover the Vision and Values of Cetoex. We're Committed to Revolutionizing the Financial Landscape through Innovative Solutions and Trusted Services."/>
        <meta name="twitter:image" content="https://pbs.twimg.com/profile_images/1556224061248008192/KQTT5Qk3_400x400.jpg"/>
        
      </Helmet>
            {/* <PageTitle heading='Referral Program' title='Referral Program' /> */}

            <section className="blog-details">
                <div className="container">
                    <div className="row">
                    <div className="col-xl-12 col-md-12">
                        <div className="blog-main">
                        <h3 className="title">
                           About CetoEX
                        </h3>

                      
                        <div className="content">
                            <h5>What is CetoEX Exchange?</h5>
                            <p>
                            CetoEX allows users to buy and sell cryptocurrencies at best available prices and offers ease of trading like no other cryptocurrency exchange. Some of the top cryptocurrencies one can trade on CetoEX are Bitcoin (BTC), Ethereum (ETH), Tron (TRX), Binance Coin (BNB) and more.<br/>

People who have a creative side and do not live it out are most disagreeable clients. They make a mountain out of a molehill, fuss about unnecessary things, are too passionately in love with somebody who is not worth so much attention, and so on.<br/>

CetoEX exchange typically make money by charging transaction fees, and the fee structure and offerings can vary greatly between different exchanges. With the rise of decentralized finance and the increasing adoption of cryptocurrencies, the crypto exchange industry is poised for growth and evolution in the coming years.
                            </p>

                           <br/>

                          
                           
                            
                        </div>

                    

                        </div>
                    </div>
                   
                    </div>
                </div>
                <Trading data={dataTrading} />
            </section>
          
            <Sale01 />
            
        </div>
    );
}

export default About;