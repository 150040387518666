const menus = [
    {
        id: 1,
        name: 'Trade',
        links: '/trade/BTCUSDT',
       
    },
    
    {
        id: 2,
        name: 'Markets ',
        links: '/markets'
    },
    {
        id: 3,
        name: 'Deposit',
        links: '#',
        namesub: [
            
            {
                id: 1,
                sub: 'Crypto Deposit',
                links: '/crypto-deposit'
            },
            {
                id: 2,
                sub: 'Deposit History',
                links: '/deposit-history'
            },
           
           
        ],
    },
    {
        id: 4,
        name: 'Withdraw',
        links: '#',
        namesub: [
            {
                id: 1,
                sub: 'Crypto Withdrawal',
                links: '/crypto-withdrawal'
            },
            {
                id: 2,
                sub: 'Withdraw History',
                links: '/withdrawal-statement'
            },
           
        ],
    },

    {
        id: 5,
        name: 'Wallet',
        links: '/wallet'
    },
    {
        id: 6,
        name: 'Launchpad',
        links: '/launchpad'
    },
    {
        id: 7,
        name: 'Staking',
        links: '/staking'
    },
    {
        id: 8,
        name: 'Airdrop',
        links: '/airdrop'
    },
    {
        id: 9,
        name: 'Get Listed',
        links: '#',
        namesub: [
            {
                id: 1,
                sub: 'Coin Listing',
                links: '/coin-listing'
            },
            {
                id: 2,
                sub: 'Offical Authenticity',
                links: '/offical-authenticity'
            },
            {
                id: 3,
                sub: 'Participate In Voting',
                links: '/voting'
            },
           
        ],
    },
   
]

export default menus;