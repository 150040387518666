import React from 'react';
import './imageLoader.scss'; // Import CSS file for styling
import LoaderImg from './loader.png';
import { ProgressBar } from  'react-loader-spinner'


const ImageLoader = () => {
  return (
      <>
    <div className="image-loader">
    <ProgressBar
  height="80"
  width="100"
  ariaLabel="progress-bar-loading"
  wrapperStyle={{}}
  wrapperClass="progress-bar-wrapper"
  borderColor = '#fff'
  barColor = '#3375fe'
/>
   <p className="image-text">Please Wait..</p>
    </div>
  
    </>
  );
};

export default ImageLoader;
