import React , {useState} from 'react';
import PropTypes from 'prop-types';

import img from '../../assets/images/layout/join.png'

import {Link} from 'react-router-dom';
import Button from '../button';

Join.propTypes = {
    
};

function Join(props) {

    const [dataBlock] = useState(
        {
            title: 'Join our CetoEX community on telegram',
            desc : 'Our group offers a supportive and engaging environment where you can connect with fellow enthusiasts, get knowledge, and stay updated with the latest trends and developments of cetoex.',
        }
    );
    return (
        <section className="join">
            <div className="container">
                <div className="row">
                <div className="col-xl-6 col-md-12">
                    <div className="join__images">
                    <img src={img} alt="" />
                    </div>
                </div>
                <div className="col-xl-6 col-md-12">
                    <div
                    className="join__content"
                   
                    >
                    <h3 className="heading">{dataBlock.title}</h3>
                    <p className="desc">
                        {dataBlock.desc}
                    </p>
                    <Button title='Join CetoEX Telegram' path='https://t.me/CetoEXExchange' />
                    </div>
                </div>
                </div>
            </div>
        </section>
    );
}

export default Join;