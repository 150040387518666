import {React,useEffect,useState} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Bars } from  'react-loader-spinner'



function Orderbook(Props) {
  
  const[state,setState] = useState('BTC');
  const [userList, setUserList] = useState({});
  
  useEffect(() => {
 
    const socket = new WebSocket('wss://websocket.cetoex.com:8080/trades?coinName='+Props.data);

    socket.onopen = () => {
      console.log('WebSocket connection established.');
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setUserList(data);
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    socket.onclose = () => {
      console.log('WebSocket connection closed.');
    };

    // Cleanup function
    return () => {
      socket.close();
    };

}, [Props.data])


    const timestamp = (timestamp)=>{

    let timestampString = timestamp.toString();
    let splitDate = timestampString.substring(0, 10);
    let splitTime = timestampString.substring(11, 19);
  
     return splitTime;

    //   let unix_timestamp = new Date(timestamp).valueOf();
     
    //   unix_timestamp = unix_timestamp.toString();
    //   unix_timestamp = unix_timestamp.slice(0, -3);
    //  return unix_timestamp = parseInt(unix_timestamp);

    }

    function scientificToDecimal(num) {
      const str = num.toString().toLowerCase();
      if (str.indexOf('e') === -1) {
        return str;
      }
      const parts = str.split('e');
      const coefficient = parseFloat(parts[0]);
      const exponent = parseInt(parts[1]);
      const decimalPart = coefficient.toFixed(4).replace('.', '');
      const exponentPart = Math.abs(exponent).toString();
      return (
          <>
          0.0<sub>{exponentPart-1}</sub>{decimalPart}
          </>
      )
    }

    const tradePrice = (price,side)=>{
       if(side === "sell")
       {
         return (
           <>
            <td className="down">{`${price}`}</td>
           </>
         );
       }
       else{
        return (
          <>
           <td className="up">{`${price}`}</td>
          </>
        );
       }
    }
 

  return (
    <>
     <div className="trade_pair"><center>
                  
                       </center>
                       <div className="flat-tabs">
                       <div className="orderbook">Recent Trades</div>
                       <div className="table-main">   
       
       <table class="table-scroll3">
        <thead>
           <tr>
         
           <th>Trade Time</th>
           <th>Amount <sub>({Props.data})</sub></th>
           <th>Price</th>
           
           </tr>
         </thead>
         <tbody class="body-half-screen">
           
           

         {
                userList && userList.length>0 ?
                userList.map((idx, i) =>
                    
                <tr>
           
             <td style={{color:"var(--tablemain)"}}><p  style={{marginLeft:"5px"}}>{timestamp(idx.order_timestamp)}</p></td>
             {tradePrice(idx.order_amount,idx.order_side)}
             <td style={{color:"var(--tablemain)"}}>{scientificToDecimal(idx.order_price)}</td>
           
              </tr>
                ): userList && userList.length === 0 ? (
                  <div style={{ justifyContent: 'center', alignItems: 'center', paddingTop: '112px' }}>
                   <center> <p className="fa fa-file" style={{fontSize:"35px",color:"#3375fe",paddingBottom:"10px"}}></p>
                    <h6  className="no-datatext">Data Unavailable</h6></center>
                  </div>
                ) 
                :(
                  <>
                 <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '130px' }}>
             <Bars
    height = "40"
    width = "40"
    radius = "9"
    color = '#3375fe'
    ariaLabel = 'three-dots-loading'  
    marginLeft='200'   
    wrapperStyle
    wrapperClass
  />
  </div>

                  </>
                ) 
                 }
                 

       
          
       
       
         </tbody>
       </table>
        
       </div>
                       </div>
                     </div>
    </>
  );
}

export default Orderbook;
