import React , {useState} from 'react';
import Sale01 from '../components/sale/Sale01';
import PageTitle from '../components/pagetitle';
import Services02 from '../components/services/Services02';
import dataServices from '../assets/fake-data/data-services';


function CoinPricing(props) {

  

    return (
        <div>

            <PageTitle heading='Coin Listing' title='Coin Listing' />

           
            <Services02 data={dataServices} />
            <Sale01 />
            
        </div>
    );
}

export default CoinPricing;