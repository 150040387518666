import React , {useState} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Sale01 from '../components/sale/Sale01';
import PageTitle from '../components/pagetitle';
import Cookies1 from 'js-cookie';
import Copy from 'clipboard-copy';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bars } from  'react-loader-spinner'
import { Helmet } from 'react-helmet';


function DepositStatement(props) {


    const [depositList, setDepositList] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const token = Cookies1.get('LoginToken');
     
      if(token != null)
      {
       fetchMyTable()
      } 
      else{
        navigate('/login');
      }   
      })
  
  
  
   async function fetchMyTable() {

    const accountUserId =   Cookies1.get('investorId');
  

      var data = JSON.stringify({
       "investorId": accountUserId
     });
     
     var config = {
       method: 'post',
       url: 'https://test.cetoex.org/api/depositstatment',
       headers: { 
         'Content-Type': 'application/json'
       },
       data : data
     };
     
     axios(config)
     .then(function (response) {

       
        setDepositList(response.data);
      
     })
     .catch(function (error) {
       console.log(error);
     });
     
        
    }

    const depositCopy = (address)=>{

        Copy(address);
         

        toast.success("Deposit Address is copied", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });

    }

    const CryptoTab = (title)=>
    {
       if(title === "Crypto Deposit")
       {
        navigate('/crypto-deposit');
       }
    }

    const [dataCryptoTab] = useState([
        {
            id: 2,
            title: 'Deposit History',
        },
        {
            id: 3,
            title: 'Crypto Deposit',
        },
        
    ])
    return (
        <div>

<Helmet>
        <title>Deposit History | Cetoex</title>
        <meta name="description" content="Track and manage your crypto deposit history with ease on Cetoex. Access detailed records of your account's deposit transactions for transparency and peace of mind."/>
        <meta name="keywords" content="crypto market, crypto deposit history, cetoex, exchange, top ranking coin, top volume, newly listed, top gainers, top losers, cetoex exchange, spot, trading, crypto"/>
        <meta name="author" content="Cetoex"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        
        {/* Open Graph (OG) tags for sharing on social media */}
        <meta property="og:title" content="Deposit History | Cetoex"/>
        <meta property="og:description" content="Track and manage your crypto deposit history with ease on Cetoex. Access detailed records of your account's deposit transactions for transparency and peace of mind."/>
        
        {/* Twitter card tags for sharing on Twitter */}
        <meta name="twitter:card" content="cetoex"/>
        <meta name="twitter:title" content="Deposit History | Cetoex"/>
        <meta name="twitter:description" content="Track and manage your crypto deposit history with ease on Cetoex. Access detailed records of your account's deposit transactions for transparency and peace of mind."/>
       
   
        {/* Add other meta tags here */}
      </Helmet>

            <PageTitle heading='Deposit History' title='Deposit History' />
            <ToastContainer position="bottom-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark"/>

            <section className="wallet sell buy-crypto flat-tabs">
                <div className="container">
                    <div className="row">
                    <Tabs>
                            <TabList>

                                {
                                    dataCryptoTab.map(idx => (
                                        <Tab  key={idx.id} onClick={()=>CryptoTab(idx.title)}>{idx.title}</Tab>
                                    ))
                                }

                            </TabList>

                            <TabPanel>
                            <div className="content-inner">
                <center>   <h4>Deposit History</h4></center>
                         
                            {
                depositList && depositList.length>0 ?
                depositList.map((idx, i) =>
                    
                
              
                 <div className="main info">
                 <p style={{fontSize:"20px",fontWeight:"bold"}}>{idx.depositCoin} Deposit ({idx.depositStatus})</p>
                

                 <ul className="list">
                     <li>
                     <div className="icon">
                        <img src={idx.depositLogo} style={{width:"38px",borderRadius:"50%"}}/>
                     </div>
                     <div className="content">
                         <p>Amount</p>
                         <h6 className="price">{idx.depositCoin+" "+idx.depositAmount}</h6>
                     </div>
                     </li>
                     <li>
                     <div className="icon">
                     <span className="bx bx-package" style={{color:"white",fontSize:"27px"}}></span>
                     </div>
                     <div className="content">
                         <p>Blockchain</p>
                         <h6 className="price">{idx.depositNetwork}</h6>
                     </div>
                     </li>
                     <li>
                     <div className="icon">
                     <span className="bx bx-calendar" style={{color:"white",fontSize:"27px"}}></span>
                     </div>
                     <div className="content">
                         <p>Deposit Date</p>
                         <h6 className="price">{idx.depositDate}</h6>
                     </div>
                     </li>


                     <li>
                     <div className="icon" style={{backgroundColor:"#000"}}>
                     <span className="bx bx-wallet-alt" style={{color:"white",fontSize:"25px"}}></span>
                     </div>
                     <div className="content">
                         <p>Deposit Address</p>
                         <h6 className="price">{idx.depositAddress.slice(0, 8)+"..."+idx.depositAddress.slice(-8)} <span className="bx bx-copy-alt" onClick={()=>depositCopy(idx.depositAddress)}></span></h6>
                     </div>
                     </li>
                 </ul>
                 </div>
            

                ):(
                    <>
                     <center>   <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '120px' }}>
                   <Bars
    height = "50"
    width = "50"
    radius = "9"
    color = '#3375fe'
    ariaLabel = 'three-dots-loading'  
    marginLeft='200'   
    wrapperStyle
    wrapperClass
  />
  </div></center>
                    </>
                )
                 }
                  </div>

                            </TabPanel>
                         
                    </Tabs> 

                    </div>
                </div>
            </section>

            <Sale01 />
            
        </div>
    );
}

export default DepositStatement;