import React , {useState,useEffect} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-modal-video/scss/modal-video.scss' ;
import 'react-tabs/style/react-tabs.css';
import Chart from '../chart/chart';
import Orderbook from '../orderbook/orderbook';
import Buysell from  '../BuySell/buysell';
import Trades from '../Trades/trades';
import Orders from '../Orders/Orders';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Assets from '../assets/assets';
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive';
import SoftnoteTxn from './softnote_txn';
import Orderbook1 from './orderbook';
import Buy1 from './buy';
import Trades1 from './trades';
import Orders1 from './orders';



function Softnote() {

  const  id  = "BTCUSDT";
 
  const symbolParameter = id.replace("USDT", "");
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 667 });
  const [activeTab, setActiveTab] = useState(0);


  const symbolParameter1 = "BTCsn";



    return (
        <div>      
     <Helmet>
        <title>{symbolParameter}/USDT Trade | Cetoex</title>
        <meta name="description" content="Experience seamless and secure cryptocurrency trading on our platform. Buy, sell, and exchange a wide range of digital assets with real-time market data."/>
        <meta name="keywords" content="crypto market, crypto deposit history, cetoex, exchange, top ranking coin, top volume, newly listed, top gainers, top losers, cetoex exchange, spot, trading, crypto"/>
        <meta name="author" content="Cetoex"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        
        {/* Open Graph (OG) tags for sharing on social media */}
        <meta property="og:title" content= {symbolParameter+"/USDT Trade | Cetoex Exchange"}/>
        <meta property="og:description" content="Experience seamless and secure cryptocurrency trading on our platform. Buy, sell, and exchange a wide range of digital assets with real-time market data."/>
        
        {/* Twitter card tags for sharing on Twitter */}
        <meta name="twitter:card" content="cetoex"/>
        <meta name="twitter:title" content={symbolParameter+"/USDT Trade | Cetoex Exchange"}/>
        <meta name="twitter:description" content="CetoEx Global Services Crypto with unlimited access #bitcoin, #Eth & 100+ #altcoin"/>
        <meta name="twitter:image" content="https://cetoex.com/favicon-banner.jpg"/>
        
      </Helmet>



            {isMobile ? (
                <>

                 <section>
                <div className="mainpage">
                    <div className="row">
                    <div className="col-xl-3 col-md-12">
                    <SoftnoteTxn/>
                    </div>
                    <Tabs selectedTabClassName="tab-design" selectedIndex={activeTab} onSelect={(index) => setActiveTab(index)}>
              <TabList   className="my-1"  id="tab-basic">
                <Tab>Chart</Tab>
                <Tab>Orderbook</Tab>
                <Tab>Trades</Tab>
              </TabList>
              <TabPanel>
                <Chart data={symbolParameter1} />
              </TabPanel>
              <TabPanel>
                <Orderbook1 data={symbolParameter} />
              </TabPanel>
              <TabPanel>
                <Trades1 data="BTC" />
              </TabPanel>
            </Tabs>
                    
                    <div className="col-xl-6 col-md-12">
                     <Buy1  data={symbolParameter}/>
                    </div>


                    <div className="col-xl-3 col-md-12">
                    <Orders1  data={symbolParameter}/>
                    </div>

                   

                   
                    </div>
                   
                </div>
            
            </section>



                </>
            )  : (

                <>
                   <section>
                <div className="mainpage">
                    <div className="row">
                    <div className="col-xl-3 col-md-12">
                    <SoftnoteTxn/>
                    </div>
                    <div className="col-xl-6 col-md-12">
                    <Chart data={symbolParameter1}/>
                    </div>
                    <div className="col-xl-3 col-md-12">
                     <Orderbook1  data={symbolParameter}/>
                    </div>

                    <div className="col-xl-3 col-md-12">
                    <Orders1  data={symbolParameter}/>
                    </div>

                    <div className="col-xl-6 col-md-12">
                     <Buy1  data={symbolParameter}/>
                    </div>

                    <div className="col-xl-3 col-md-12">
                    <Trades1  data="BTC"/>
                    </div>
                    </div>
                   
                </div>
            
            </section>

                </>
            )}
          

         
         
            
        </div>
    );
}

export default Softnote;