import React , {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Join from '../components/join/Join';
import img from '../assets/images/layout/join.png'
import kyc1 from '../assets/images/icon/kyc1.png'
import kyc2 from '../assets/images/icon/kyc2.png'
import kyc3 from '../assets/images/icon/kyc3.png'
import './kycupload.scss';
import Cookies1 from 'js-cookie';
import axios  from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImageLoader from "../components/loader/loader";


const Kycupload = () => {

  const[loader,setLoader] = useState(false);
  const [show, setShow] = useState(1);
  const[approval,setApproval]= useState({
    status:0,
    documentID:'',
    documentNum:'',
    frontPhoto:'',
    backphoto:'',
    selfi:''
  });

   
    
  useEffect(()=>{
    
    fetchMyTable()

   },[])



  async function  fetchMyTable()
  {
    const accountUserId = Cookies1.get('investorId');
  

    setLoader(true);
    var data = JSON.stringify({
      "AccountID": accountUserId
    });
    
    var config = {
      method: 'post',
      url: 'https://test.cetoex.org/api/checkKYC',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {

      setLoader(false)

      
      if(response.data.status === "success")
       {
        
        setShow(3);
       
        const APIData = response.data;
        setApproval({
          status:APIData.approval,
          documentID:APIData.documentID,
          documentNum:APIData.documentNum,
          frontPhoto:APIData.FrontPhoto,
          backphoto:APIData.BackPhoto,
          selfi:APIData.Selfi
        });

       }
    
     
     
    })
    .catch(function (error) {
      toast.error(error, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
    });




  }




    const joinGroup = ()=>{
 
      window.open('https://t.me/CetoEXExchange', '_blank');

      setShow(2)
    }
    

    const uploadDocument = ()=>{


    

      const accountUserId = Cookies1.get('investorId');
      const accountUserName = Cookies1.get('investorName');


      const documentType = document.getElementById('documentType').value;
      const documentNumber = document.getElementById('documentId').value;

      if(documentNumber === "")
      {
        document.getElementById('documenterror').innerHTML = "&nbsp;&nbsp;**&nbsp;&nbsp;Document Number is required.";
        return false;
      }
      else{
        document.getElementById('documenterror').innerHTML = "";
      }

       if(encodedImage === null || encodedImage1 === null || encodedImage2 === null)
       {
        toast.error("Upload All KYC Documents", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });

          return false;
       }

      

      setLoader(true)

      let formData = new FormData();
      formData.append('AccountID',accountUserId);
      formData.append('AccountName',accountUserName);
      formData.append('IDType',documentType);
      formData.append('IDNumber',documentNumber);
      formData.append('Image', encodedImage);
      formData.append('Front', encodedImage1);
      formData.append('Back', encodedImage2);
  

      fetch('https://test.cetoex.org/api/upload', {
        method: 'POST',
        body: formData,
    })
    .then(response => response.json())
    .then(data => {

      setLoader(false)
      if(data.success === "Uploaded Successfully")
      {
        fetchMyTable()
         setShow(3);
      }
      else{
        toast.error(data.error, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });

      }
    })
    .catch(error => {
        // Handle any errors.
    });
    

    }
    
    


      const [dataBlock] = useState(
        {
            title: 'Join our CetoEX community on telegram',
            desc : 'Our group offers a supportive and engaging environment where you can connect with fellow enthusiasts, get knowledge, and stay updated with the latest trends and developments of cetoex.',
        }
    );



    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImage1, setSelectedImage1] = useState(null);
    const [selectedImage2, setSelectedImage2] = useState(null);


    const[encodedImage,setEncodedImage] = useState(null);
    const[encodedImage1,setEncodedImage1] = useState(null);
    const[encodedImage2,setEncodedImage2] = useState(null);
    

    const handleImageChange = (e) => {
 
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        
  
        reader.onload = (e) => {
          setEncodedImage(file);
          setSelectedImage(e.target.result);

        };
  
        reader.readAsDataURL(file);
      }
    };


    const handleImageChange1 = (e) => {
 
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
  
        reader.onload = (e) => {
          setEncodedImage1(file)
          setSelectedImage1(e.target.result);
        };
  
        reader.readAsDataURL(file);
      }
    };


    const handleImageChange2 = (e) => {
 
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
  
        reader.onload = (e) => {
          setEncodedImage2(file);
          setSelectedImage2(e.target.result);
        };
  
        reader.readAsDataURL(file);
      }
    };
  


    
      
  return (



    <>


{
    loader && <ImageLoader/>
}

        {

        show === 1 && (
        <>
          <br />
        <p style={{fontSize:"18px"}}>Step 1 : <span style={{color:"#d33535"}}>Follow Cetoex Telegram Handle</span> to complete step 1 to get free rewards.</p>

        <hr />
        
        <br />

    <div className="container">
          <div className="row">
          <div className="col-xl-12 col-md-12">
              <div className="join__images">
              <img src={img} alt="" width="240px" />
              </div>
          </div>
          <div className="col-xl-12 col-md-12">
              <div className="join__content">
            
              <p className="desc">
                 Follow us on telegram for get 100 Million BABYDOGE.
              </p>
            
              </div>
          </div>

   <center> <div className="col-xl-7">
     <br/>
    <button className="btn btn-primary" style={{color:"white",fontSize:"18px",fontWeight:"bold"}}  onClick={joinGroup}>Join Telegram</button>
    </div></center>
         
          </div>
      </div>
        </>
        )
        }
  


        {
          show == 2 && (
            <>
     
     <br />
<p style={{fontSize:"18px"}}>Step 2 : <span style={{color:"#d33535"}}>Upload KYC Document</span> to complete step 2 to get free rewards.</p>

<hr />
      <ul>
       <li>Mention any specific details that must be visible in the uploaded documents (e.g., full name, date of birth, expiration date).</li>
      
     </ul>
    <div style={{display:"flex",marginTop:"10px"}}>
      
    <img src={kyc1} alt="" className="src" width="90px" style={{margin:"6px"}}/>
     <img src={kyc2} alt="" className="src"  width="90px" style={{margin:"6px"}} />
     <img src={kyc3} alt="" className="src"  width="90px"  style={{margin:"6px"}} />
      </div> 

    <div className="main" style={{border:"1px solid gray"}}>
 
   







   <div className="form-group" style={{maxWidth:"548px",width:"100%"}}>
      <label for="exampleInputEmail1" style={{float:"left",marginBottom:"2px"}}>Document Type</label>
       <select className="form-control" id="documentType">
         <option value="ID Card">ID Card</option>
         <option value="Passport">Passport</option>
         <option value="Driver License">Driver License</option>
         <option value="Residence Permit">Residence Permit</option>
       </select>
      </div>

  

   <div className="form-group" style={{maxWidth:"548px",width:"100%",marginTop:"14px"}}>
      <label for="exampleInputEmail1" style={{float:"left",marginBottom:"2px"}}>Identity Card Number</label>
      <input
          type="text"
          className="form-control"
          id="documentId"
       
      />
    <span style={{fontSize:"11px",color:"#d9534f",fontWeight:"bold"}} id="documenterror"></span>
      </div>
   


 
      
      
    

      <br/>  
<div className="wrapper">


<div className="image-upload-container">
      <input 
        type="file"
        accept="image/*"
        id="fileInput"
        onChange={handleImageChange}
       style={{display:"none"}}/>
      
    

   <label htmlFor="fileInput" className="upload-button">
      <b> <span className="fa fa-cloud-upload"></span><br/><span style={{fontSize:"12px"}}>Upload Photo</span></b>
      </label>
    </div>






    <div className="image-upload-container">
      <input 
        type="file"
        accept="image/*"
        id="fileInput1"
        onChange={handleImageChange1}
       style={{display:"none"}}/>
     
     

   <label htmlFor="fileInput1" className="upload-button">
   <b><span className="fa fa-cloud-upload"></span><br/><span style={{fontSize:"12px"}}>Document Front</span></b>
      </label>
    </div>



    <div className="image-upload-container">
      <input 
        type="file"
        accept="image/*"
        id="fileInput2"
        onChange={handleImageChange2}
       style={{display:"none"}}/>
     
      

   <label htmlFor="fileInput2" className="upload-button">
   <b> <span className="fa fa-cloud-upload"></span><br/><span style={{fontSize:"12px"}}>Document Back</span></b>
      </label>
    </div>


    

    <div style={{display:"flex",width:"100%",marginTop:"30px"}} >
      {selectedImage && (
        <div className="image-preview">
          <img src={selectedImage} alt="Selected"  style={{width:"100px",height:"60px",margin:"5px"}}/>
        </div>
      )}

      {selectedImage1 && (
        <div className="image-preview">
          <img src={selectedImage1} alt="Selected" style={{width:"100px",height:"60px",margin:"5px"}} />
        </div>
      )}

      {selectedImage2 && (
        <div className="image-preview">
          <img src={selectedImage2} alt="Selected" style={{width:"100px",height:"60px",margin:"5px"}} />
        </div>
      )}

      </div>





</div>


<center>   <Link to="#" className="btn-action" onClick={uploadDocument}>Upload Document</Link></center>
</div>




    

            </>
          )
        }



{
          show == 3 && (
            <>
     
     <br />
<p style={{fontSize:"18px"}}><span style={{color:"#0275d8"}}><b>KYC Document Uploaded</b></span> <br/> {
     approval.status == 1 ? (<>
     <p>Status : <span style={{color:"#5cb85c"}}>Success</span></p>
     </>):(
       <>
        <p>Status : <span style={{color:"#f0ad4e"}}>Pending</span></p>
       </>
     )

  }</p>

<hr />
      <ul>
       <li>Generally takes 4-5 hours for verification of your KYC. We will check your document manually.</li>
      
     </ul>
   

    <div className="main" style={{border:"1px solid gray"}}>
 
   


<center>




   <div className="form-group" style={{maxWidth:"548px",width:"100%"}}>
      <label for="exampleInputEmail1" style={{float:"left",marginBottom:"2px"}}>Document Type</label>
       <select className="form-control" id="documentType" value={approval.documentID} disabled>
         <option value="ID Card">ID Card</option>
         <option value="Passport">Passport</option>
         <option value="Driver License">Driver License</option>
         <option value="Residence Permit">Residence Permit</option>
       </select>
      </div>

  

   <div className="form-group" style={{maxWidth:"548px",width:"100%",marginTop:"14px"}}>
      <label for="exampleInputEmail1" style={{float:"left",marginBottom:"2px"}}>Identity Card Number</label>
      <input
          type="text"
          className="form-control"
          id="documentId"
          value={approval.documentNum} disabled
      />
      </div>


      
      
      </center>
     
     <br/>
      <p><b>Document Photo: </b></p>

<div style={{display:"flex",marginTop:"10px"}}>

<img src={`https://test.cetoex.org/public/images/${approval.selfi}`} alt="selfi" className="src" width="90px" style={{margin:"6px"}}/>
 <img src={`https://test.cetoex.org/public/images/${approval.frontPhoto}`} alt="Front Photo" className="src"  width="90px" style={{margin:"6px"}} />
 <img src={`https://test.cetoex.org/public/images/${approval.backphoto}`} alt="Back Photo" className="src"  width="90px"  style={{margin:"6px"}} />
  </div> 

     



</div>




    

            </>
          )
        }







    </>
  );
};

export default Kycupload;
