import React , {useState} from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import { Pagination, Scrollbar, A11y,Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation"
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './styles.scss';
// import Swiper core and required modules
import SwiperCore, {Navigation} from 'swiper';

import ContentLoader, { Facebook } from "react-content-loader";

const MyLoader = () => (
  <ContentLoader width="100%" height="127px">
    <rect x="6%" y="13%" rx="4" ry="4" width="40%" height="1em" borderRadius="5px"/>
    <rect x="75%" y="13%" rx="4" ry="4" width="15%" height="1em" borderRadius="5px"/>


    <rect x="6%" y="42%" rx="4" ry="4" width="40%" height="1em" borderRadius="5px"/>
    <rect x="75%" y="42%" rx="4" ry="4" width="15%" height="1em" borderRadius="5px"/>


    <rect x="6%" y="73%" rx="4" ry="4" width="40%" height="1em" borderRadius="5px"/>
    <rect x="75%" y="73%" rx="4" ry="4" width="15%" height="1em" borderRadius="5px"/>
  
  </ContentLoader>
);


// install Swiper modules
SwiperCore.use([Navigation]);



function Crypto02(props) {
 
      const [isLoading, setIsLoading] = useState(false);
      const [gainer,setGainer] = useState([]);
      const [loser,setLoser] = useState([]);
      const [newly,setNewly] = useState([]);
  

    const myArray = props.data;
    const sortedData = [...myArray].sort((a, b) => b.coin_pricechange - a.coin_pricechange);
    const GainerData = sortedData.slice(0, 3);
    const LoserData1 = sortedData.slice(-3);
    const LoserData = [...LoserData1].reverse();
    const NewlyData = [...myArray].reverse().slice(0, 3);
    
    useEffect(()=>{
        setGainer(GainerData);
        setLoser(LoserData);
        setNewly(NewlyData);
    });
    
  

    function scientificToDecimal(num) {
        const str = num.toString().toLowerCase();
        if (str.indexOf('e') === -1) {
          return str;
        }
        const parts = str.split('e');
        const coefficient = parseFloat(parts[0]);
        const exponent = parseInt(parts[1]);
        const decimalPart = coefficient.toFixed(4).replace('.', '');
        const exponentPart = Math.abs(exponent).toString();
        return (
            <>
            0.0<sub>{exponentPart-1}</sub>{decimalPart}
            </>
        )
      }

    const slides = Array.from({ length: 1000 }).map(
        (el, index) => `Slide ${index + 1}`
      );


    return (
        <section className="crypto" data-aos="fade-up" data-aos-duration="1000">
            <div className="container" style={{marginTop:"55px"}}>
             <h6>Today's Cryptocurrency Prices by Market Cap</h6>
             <p>The global crypto market cap is $7.2M, a 0.42% increase over the last day.</p><br/>
                <div className="row">
               
                <div className="col-md-12">
               
                    <div className="">
 
                <Swiper  
                 style={{
                    "--swiper-navigation-size": "10px",
                    "--swiper-navigation-background-color": "#3774ff",
                  }}


      modules={[Navigation, Pagination,Autoplay, Scrollbar, A11y]}
      spaceBetween={50}
      slidesPerView={3}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      breakpoints={{
        0: {
          slidesPerView: 1,
        },
        300:{
          slidesPerView:1,
        },
        500:{
          slidesPerView:2,
        },
        639: {
          slidesPerView: 2,
        },
        865:{
          slidesPerView:2
        },
        1000:{
          slidesPerView:3
        },
        1500:{
          slidesPerView:3
        },
        1700:{
          slidesPerView:3
        }
      }}
      navigation= {true}
   >
                        
                                      
                                          <SwiperSlide>
                                           {
                                              isLoading ?(
                                                <>
                                                 <MyLoader />
                                                </>
                                              ):(
                                               <>
                                                  <div className="feature-headers">
                                                         <div>
                                                             <p style={{color:"var(--onsurface)"}}>🔥 Newly Listed</p>
                                                             <p><Link to="/newly-listed" style={{fontSize:"14px",textDecoration:"underline"}}>View More</Link></p>
                      
                                                         </div>
                                                       
                                                         <table>
                                                             <tbody>
                                                                
                                                                                        {
                                                            newly && newly.length>0 ?
                                                            newly.map((idx, i) =>
                                                                <>
                                                                 <tr>
                                             <td><div style={{display:"inline"}}><img src={idx.coin_logo} style={{width:"20px",height:"20px",borderRadius:"50%"}}/> <span style={{marginTop:"15px",color:"var(--onsurface)"}}>{` ${idx.coin_name}`}</span></div></td>

                                             <td className="text-right" style={{color:"var(--onsurface)"}}>${scientificToDecimal(idx.coin_price)}</td>
                                             </tr>
                                                                </>


                                                                                        ):(
                                                                                            <MyLoader/>
                                                                                        )
                                                                }
                                                                

                                                                     


                                                             </tbody>
                                                         </table>
                                                   </div>
                                               </>
                                              )
                                           }
                                              
                                               
                                        </SwiperSlide>


                                       
                                        <SwiperSlide>
                                           {
                                              isLoading ?(
                                                <>
                                                 <MyLoader />
                                                </>
                                              ):(
                                               <>
                                                  <div className="feature-headers">
                                                         <div>
                                                             <p style={{color:"var(--onsurface)"}}>🟢 Top Gainers</p>
                                                             <p><Link to="/top-gainers" style={{fontSize:"14px",textDecoration:"underline"}}>View More</Link></p>
                                                         </div>
                                                         <table>
                                                         <tbody>
                                                                
                                                                {
                                    gainer && gainer.length>0 ?
                                    gainer.map((idx, i) =>
                                        <>
                                         <tr>
                                             <td><div style={{display:"inline"}}><img src={idx.coin_logo} style={{width:"20px",height:"20px",borderRadius:"50%"}}/> <span style={{marginTop:"15px",color:"var(--onsurface)"}}>{` ${idx.coin_name}`}</span></div></td>

                                             <td className="text-right" style={{color:"#58bd7d"}}><span className="fa fa-arrow-up" style={{fontSize:"13px"}}></span>{` ${(idx.coin_pricechange).toFixed(2)}%`}</td>
                                             </tr>
                                        </>


                                                                ):(
                                                                   
                                                                      <MyLoader/>
                                                                    
                                                                )
                                        }
                                        

                                             


                                     </tbody>
                                                         </table>
                                                   </div>
                                               </>
                                              )
                                           }
                                              
                                               
                                        </SwiperSlide>

                                        <SwiperSlide>
                                           {
                                              isLoading ?(
                                                <>
                                                 <MyLoader />
                                                </>
                                              ):(
                                               <>
                                                  <div className="feature-headers">
                                                         <div>
                                                             <p style={{color:"var(--onsurface)"}}>🔴 Top Losers</p>
                                                             <p><Link to="/top-losers" style={{fontSize:"14px",textDecoration:"underline"}}>View More</Link></p>
                                                         </div>
                                                         <table>
                                                         <tbody>
                                                                
                                                                {
                                    loser && loser.length>0 ?
                                    loser.map((idx, i) =>
                                        <>
                                         <tr>
                                             <td><div style={{display:"inline"}}><img src={idx.coin_logo} style={{width:"20px",height:"20px",borderRadius:"50%"}}/> <span style={{marginTop:"15px",color:"var(--onsurface)"}}>{` ${idx.coin_name}`}</span></div></td>

                                             <td className="text-right" style={{color:"#d33535"}}> <span className="fa fa-arrow-down" style={{fontSize:"13px"}}></span> {`${(Math.abs(idx.coin_pricechange)).toFixed(2)}%`}</td>
                                             </tr>
                                        </>


                                                                ):(
                                                                    <MyLoader/>
                                                                )
                                        }
                                        

                                             


                                     </tbody>
                                                         </table>
                                                   </div>
                                               </>
                                              )
                                           }
                                              
                                               
                                        </SwiperSlide>

                              
                   </Swiper>
                     
                    </div>
                  
                     
                </div>
                </div>
            </div>
        </section>
    );
}

export default Crypto02;