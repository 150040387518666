import React , {useState} from 'react';
import { useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import img1 from '../../assets/images/softnote/banner1.jpg'
import img2 from '../../assets/images/logo/log-footer.png'
import axios from 'axios';
import Cookies1 from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImageLoader from "../loader/loader";
import img from './images/banner.jpg';
import Modal from 'react-modal';
import './style.scss';
import { Bars } from  'react-loader-spinner';


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width:'100%',
    maxWidth:'380px',
    margin:'5px',
   
    transform: 'translate(-50%, -50%)',
  },
};


function SoftnoteTxn() {


  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen1, setIsOpen1] = React.useState(false);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [existList, setExistList] = useState({});
  const [historyList, setHistoryList] = useState({});
  const[loader,setLoader] = useState(false);
  const [login,setLogin] = useState(true);
  const [selectedOption, setSelectedOption] = useState('market');
  const[coinPrice,setCoinPrice]= useState('');
  const[currentSN,setCurrentSN]= useState('');
  const[amountSN,setAmountSN]= useState('');
  const [currentPrice,setCurrentPrice] = useState('');




  useEffect(()=>{


    fetchMyTable();

    const token = Cookies1.get('LoginToken');
    


    if(token != null)
    {
      fetchMyTable1()

     
  }   
  else{
    setLogin(false)
  }

  } , [10000]);


  function closeModal() {
    setIsOpen(false);
  }

  function closeModal1() {
    setIsOpen1(false);
  }

  function closeModal2() {
    setIsOpen2(false);
  }



  async function fetchMyTable1() {
   
    const investorID = Cookies1.get('investorId');
    const eventSource = new EventSource(`https://sse.cetoex.com/api/stream50/${investorID}`);

    // Handle messages received from the EventSource
    eventSource.onmessage = (event) => {
      const receivedData = JSON.parse(event.data);
     
       const buyFilter = receivedData.filter((item) =>
          item.softnote_status ==false &&  item.softnote_method.toLowerCase().includes("deposit")
       );

     
       setExistList(buyFilter);
       setHistoryList(receivedData);
      

   };

   return () => {
     eventSource.close(); 
   };



  }



  async function fetchMyTable() {
  

    const accountUserId = Cookies1.get('investorId');
    var data = '';
  
  var config = {
    method: 'get',
    url: `https://test.cetoex.org/api/buyDetails?investorId=${accountUserId}&APIKEY=494093&Coin=BTC`,
    headers: { },
    data : data
  };
  
  axios(config)
  .then(function (response) {
    setCoinPrice(response.data.last_price);
    setCurrentPrice(response.data.last_price);
  })
  .catch(function (error) {
    console.log(error);
  });
  
    
       
  }
  


  const makeDeposit = async (e) => {
    e.preventDefault();

    const accountUserId = Cookies1.get('investorId');
    const passcode = document.getElementById('softnote_secret').value;
    const softnote_serial = document.getElementById('softnote_serial').value;

    
    
    setLoader(true);
   
    var data = JSON.stringify({
      "InvestorID": accountUserId,
      "SerialNum": softnote_serial,
      "Passcode": passcode
    });
    
    var config = {
      method: 'post',
      url: 'https://test.cetoex.org/api/SNdeposit',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
        
         if(response.data.status === "success")
         {
          fetchMyTable1()


          toast.success(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });

            setIsOpen(false);
         }
         else{
          toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
         }

         setLoader(false)
    })
    .catch(function (error) {
      console.log(error);
    });


    
  }


  const timestamp = (timestamp)=>{

    let timestampString = timestamp.toString();
    let splitDate = timestampString.substring(0, 10);
    let splitTime = timestampString.substring(11, 19);
  
     return splitTime+" "+splitDate;

    //   let unix_timestamp = new Date(timestamp).valueOf();
     
    //   unix_timestamp = unix_timestamp.toString();
    //   unix_timestamp = unix_timestamp.slice(0, -3);
    //  return unix_timestamp = parseInt(unix_timestamp);

    }

    const sellSoftnote =  (softnote_id) => (e) => 
    {
      
      e.preventDefault(); 

      const accountUserId = Cookies1.get('investorId');
    


      setLoader(true);
      var data = JSON.stringify({
        "InvestorID": accountUserId,
        "SoftnoteID":softnote_id,
        "orderType":selectedOption,
        "sellPrice":currentPrice,
      });
      
      var config = {
        method: 'post',
        url: 'https://test.cetoex.org/api/SNsell',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {

       if(response.data.status === "success")
        {

          setIsOpen2(false);


          fetchMyTable1()

          toast.success(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
 

        }
        else{

          toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
        }

        setLoader(false);


      })
      .catch(function (error) {
        console.log(error);
      });
      


    }

    const withdrawSoftnote = (softnote_id) => (e) => 
    {
  
      e.preventDefault(); 

      const accountUserId = Cookies1.get('investorId');

      setLoader(true);
      var data = JSON.stringify({
        "InvestorID": accountUserId,
        "softnoteID": softnote_id
      });
      
      var config = {
        method: 'post',
        url: 'https://test.cetoex.org/api/SNwithdraw',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
          if(response.data.status === "success")
          {

            fetchMyTable1()
            toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
              });
  
              setIsOpen1(false);

          }
          else{
            toast.error(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
              });
          }

          setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });


     

    }




const depositModal = ()=>{

  setIsOpen(true);
}

const depositModal1 = ()=>{
  setIsOpen1(true);
}


function openSell(softnote,amount)
{

  setCurrentSN(softnote);
  setAmountSN(amount);

  setIsOpen2(true);
} 

  
  const divStyle = {
    height: '522px',
    backgroundImage: img1,
  };
  
  const depositButton = {
    border:'2px solid #079981',
    padding:'8px 20px 8px 20px',
     borderRadius: '10px',
     backgroundColor:'#079981',
     color:'white',
  };


  const withdrawButton = {
    border:'2px solid #d33535',
    padding:'8px 20px 8px 20px',
    borderRadius: '10px',
    backgroundColor:'#d33535',
    color:'white',
  };



  return (
    <>
  
                                    <Modal
                                            isOpen={modalIsOpen}
                                            onRequestClose={closeModal}
                                            style={customStyles}
                                            contentLabel="Example Modal"
                                        >
                                    
                                        <form>
                                         <center> 
                                           <div className="flex"> 
                                           <img src={img2} alt="logo" width="150px" />
                                           <span style={{float:"right",fontSize:"20px"}} className="fa fa-times" onClick={closeModal}></span>
                                         </div>
                                         </center>
                                        
                                           <p style={{color:"black",fontSize:"18px",marginTop:"20px"}}><b>BTCsn Deposit</b></p>
                                        
                                       
                                        <p style={{padding:"5px 5px 15px 5px",fontSize:"13px"}}>Deposit only Softnote with correct secret digit. Get Deposit instantly into your wallet.</p>
                                       
                                        
                                        <div className="form-group">
                                        <label for="exampleInputEmail1" style={{fontSize:"13px",color:"black"}}>Softnote Serial</label>
                                        <input type="text" className="form-control form-control-sm" id="softnote_serial" placeholder="Ex. 0000100000820547" />
                                        </div>
                                       
                                        <div className="form-group" style={{marginTop:"10px"}}>
                                        <label for="exampleInputEmail1" style={{fontSize:"13px",color:"black"}}>Secret Passcode</label>
                                        <input type="text" className="form-control form-control-sm" id="softnote_secret" placeholder="Enter Secret Passcode"/>
                                        </div>

                                      
                                        <br/>
                                        {
                                          login?(
                                            <>
                                             <center> <button className="btn btn-primary" style={{color:"white"}}  onClick={makeDeposit}>Make Deposit</button></center>
                                            </>
                                          ):(
                                            <>
                                              <center> <button className="btn btn-primary" style={{color:"white"}}>Login For Deposit?</button></center>
                                         
                                            </>
                                          )
                                        }

                                     
                                        </form>
                                        </Modal>


                                        <Modal
                                            isOpen={modalIsOpen2}
                                            onRequestClose={closeModal2}
                                            style={customStyles}
                                            contentLabel="Example Modal"
                                        >
                                    
                                        <form>
                                         <center> 
                                           <div className="flex"> 
                                           <img src={img2} alt="logo" width="150px" />
                                           <span style={{float:"right",fontSize:"20px"}} className="fa fa-times" onClick={closeModal2}></span>
                                         </div>
                                         </center>
                                        
                                           <p style={{color:"black",fontSize:"18px",marginTop:"20px"}}><b>BTCsn Sell </b></p>
                                        
                                       
                                        <p style={{padding:"5px 5px 15px 5px",fontSize:"13px"}}>When a perfect matching buy order matches. Than your order will be processed in limit order.</p>
                                       
                                         <div className="form-group">
                                        <label for="exampleInputEmail1" style={{fontSize:"13px",color:"black"}}>Softnote ID</label>
                                        <input type="text" className="form-control form-control-sm" value={currentSN} disabled />
                                       
                                        </div>


                                        <div className="form-group"  style={{marginTop:"10px"}}>
                                        <label for="exampleInputEmail1" style={{fontSize:"13px",color:"black"}}>Sell Type</label>
                                        <select className="form-control form-control-sm" id="orderType"   onChange={(e)=>{
                                          
                                           if(e.target.value === "market")
                                           {
                                            setCurrentPrice(coinPrice)
                                           }
                                           setSelectedOption(e.target.value)
                                        }
                                       } >
                                          <option value="market">Market</option>
                                          <option value="limit">Limit</option>
                                        </select>
                                       
                                        </div>
                                       
                                        {selectedOption === 'limit' && (
                                        <div className="form-group" style={{marginTop:"10px"}}>
                                        <label for="exampleInputEmail1" style={{fontSize:"13px",color:"black"}}>BTC Market Price</label>
                                        <input type="text" className="form-control form-control-sm" placeholder="Enter Sell Price" onChange={(e)=>setCurrentPrice(e.target.value)} value={currentPrice}  id="softnote_secret"/>
                                        </div>
                                        )}

                                        {selectedOption === 'market' && (
                                        <div className="form-group" style={{marginTop:"10px"}}>
                                        <label for="exampleInputEmail1" style={{fontSize:"13px",color:"black"}}>BTC Market Price</label>
                                        <input type="text" className="form-control form-control-sm"  value={currentPrice} disabled/>
                                        </div>
                                        )}     


                                          <div className="form-group"  style={{marginTop:"10px"}}>
                                        <label for="exampleInputEmail1" style={{fontSize:"13px",color:"black"}}>Denomination</label>
                                        <input type="text" className="form-control form-control-sm" value={`$${(currentPrice * amountSN).toFixed(3)}`} disabled />
                                       
                                        </div>


                                      
                                        <br/>
                                        {
                                          login?(
                                            <>
                                             <center> <button className="btn btn-danger" style={{color:"white"}}  onClick={sellSoftnote(currentSN)}>Sell Softnote</button></center>
                                            </>
                                          ):(
                                            <>
                                              <center> <button className="btn btn-primary" style={{color:"white"}}>Login For Deposit?</button></center>
                                         
                                            </>
                                          )
                                        }

                                     
                                        </form>
                                        </Modal>



                                        <Modal
                                            isOpen={modalIsOpen1}
                                            onRequestClose={closeModal1}
                                            style={customStyles}
                                            contentLabel="Example Modal"
                                        >
                                    
                                        <form>
                                        <center> 
                                           <div className="flex"> 
                                           <img src={img2} alt="logo" width="150px" />
                                           <span style={{float:"right",fontSize:"20px"}} className="fa fa-times" onClick={closeModal1}></span>
                                         </div>
                                         </center>
                                        
                                           <p style={{color:"black",fontSize:"18px",marginTop:"20px"}}><b>BTCsn Withdraw</b></p>
                                        
                                       
                                        <p style={{padding:"5px 5px 15px 5px",fontSize:"13px"}}>Note : Withdrawal will be processed upto 3-4 hour. Softnote passcode will be sent on your registered email.</p>
                                       
                                        <div className="orders-ul">

                                    {
                                          existList && existList.length>0 ?
                                          existList.map((idx, i) =>
                                              
                                          <div className="container122">
                                          <div className="crypto-card">
                                            <div className="left-section">
                                            <p style={{fontSize:"10px",marginLeft:"5px"}}>SOFTNOTE</p>
                                              <img src={img1} alt="Crypto Logo 1" />
                                              </div>

                                            <div className="center-section" >
                                            <p style={{fontSize:"13px",fontWeight:"bold"}}>BTCsn / USDT</p>
                                            <p style={{fontWeight:"normal"}}>Serial : <b>#{idx.softnote_id}</b></p>
                                            <p> Amount : <b>BTC {idx.softnote_amount}</b></p>
                                            
                                            
                                            </div>

                                            <div className="right-section">
                                            <p className="crypto-volume">{timestamp(idx.softnote_timestamp)}</p>
                                              <button  style={{fontSize:"12px"}} onClick={withdrawSoftnote(idx.softnote_id)}><span className="fa fa-arrow-up"></span> Withdraw</button>
                                            </div>
                                          </div>
                                        
                                        </div>
                                          )
                                          : existList && existList.length === 0 ? (
                                            <div style={{ justifyContent: 'center', alignItems: 'center', paddingTop: '92px' }}>
                                            <center> <p className="fa fa-file" style={{fontSize:"35px",color:"#5cb85b",paddingBottom:"10px"}}></p>
                                              <h6  className="no-datatext">Softnote Unavailable</h6></center>
                                            </div>
                                          )
                                          :(
                                           
                                            <>
                                            {
                                               login?(
                                                <>
                                                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '92px' }}>
                                      <Bars
                                    height = "40"
                                    width = "40"
                                    radius = "9"
                                    color = '#3375fe'
                                    ariaLabel = 'three-dots-loading'  
                                    marginLeft='200'   
                                    wrapperStyle
                                    wrapperClass
                                    />
                                    </div>
                                                </>
                                              ):(
                                                <>
                                                <div style={{ justifyContent: 'center', alignItems: 'center', paddingTop: '72px'}}>
                                            <center> <p className="fa fa-sign-in" style={{fontSize:"35px",color:"#3375fe",paddingBottom:"10px"}}></p>
                                             <h6  className="no-datatext">Login To Withdraw <br/> <a href="/login" style={{fontSize:"10px",color:"#3375fe",textDecoration:"underline"}}>Login Account?</a></h6>
                                            
                                             </center>
                                           </div>
                                               </>
                                              )
                                            }
                                        

                                            </>
                                          ) 
                                          }


                                        </div>
                                     
                                        </form>
                                        </Modal>





                                       

                                        <ToastContainer
position="bottom-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="dark"
/>


     
  
{
    loader && <ImageLoader/>
}

      
    <div className="trade_pair" style={divStyle}>
    <div style={{margin:"30px"}} className="row">
      <div className="col-6">
      <button  style={depositButton} onClick={depositModal}><span className="fa fa-arrow-down"></span> <b>Deposit</b></button>
      </div>

      <div className="col-6">
      <button style={withdrawButton} onClick={depositModal1}><span className="fa fa-arrow-up"></span> <b>Withdraw</b></button>
       </div>
       
       
        </div> 
       




        <div className="flat-tabs">
  <div className="table-main">   
  <Tabs defaultIndex={0} selectedTabClassName="tab-design">
    <TabList  className="my-0"  id="tab-basic">
      <Tab>
        Available Softnote
      </Tab>
      <Tab>
       Deposit / Withdraw

      </Tab>
    
    </TabList>

    <TabPanel>
    <div>
        
        
          <div className="orders-ul">

          {
                existList && existList.length>0 ?
                existList.map((idx, i) =>
                    
                <div className="container122">
                <div className="crypto-card">
                  <div className="left-section">
                   <p style={{fontSize:"10px",marginLeft:"5px"}}>SOFTNOTE</p>
                    <img src={img1} alt="Crypto Logo 1" />
                     </div>

                  <div className="center-section" >
                   <p style={{fontSize:"13px",fontWeight:"bold"}}>BTCsn / USDT</p>
                   <p style={{fontWeight:"normal"}}>Serial : <b>#{idx.softnote_id}</b></p>
                   <p> Amount : <b>BTC {idx.softnote_amount}</b></p>
                   
                   
                  </div>

                  <div className="right-section">
                  <p className="crypto-volume">{timestamp(idx.softnote_timestamp)}</p>
                    <button  style={{fontSize:"12px"}} onClick={()=>openSell(idx.softnote_id,idx.softnote_amount)}><span className="fa fa-arrow-up"></span> Sell Softnote</button>
                  </div>
                </div>
               
              </div>
                )
                : existList && existList.length === 0 ? (
                  <div style={{ justifyContent: 'center', alignItems: 'center', paddingTop: '92px' }}>
                   <center> <p className="fa fa-file" style={{fontSize:"35px",color:"#5cb85b",paddingBottom:"10px"}}></p>
                    <h6  className="no-datatext">Softnote Unavailable</h6></center>
                  </div>
                )
                :(
                  <>
                  {
                    login?(
                      <>
                                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '92px' }}>
                            <Bars
                    height = "40"
                    width = "40"
                    radius = "9"
                    color = '#3375fe'
                    ariaLabel = 'three-dots-loading'  
                    marginLeft='200'   
                    wrapperStyle
                    wrapperClass
                  />
                  </div>
                      </>
                    ):(
                      <>
                      
                                                <div style={{ justifyContent: 'center', alignItems: 'center', paddingTop: '112px'}}>
                                            <center> <p className="fa fa-sign-in" style={{fontSize:"35px",color:"#3375fe",paddingBottom:"10px"}}></p>
                                             <h6  className="no-datatext">Login To Softnote <br/> <a href="/login" style={{fontSize:"10px",color:"#3375fe",textDecoration:"underline"}}>Login Account?</a></h6>
                                            
                                             </center>
                                           </div>
                      </>
                    )
                  }
                

                  </>
                ) 
                 }


              </div>
        </div>
    </TabPanel>
    <TabPanel>
    <div>
        
        
          <div className="orders-ul">


          {
                historyList && historyList.length>0 ?
                historyList.map((idx, i) =>
                    
                <div className="container122">
                <div className="crypto-card">
                  <div className="left-section">
                   <p style={{fontSize:"10px",marginLeft:"5px"}}>SOFTNOTE</p>
                    <img src={img1} alt="Crypto Logo 1" />
                     </div>

                  <div className="center-section" >
                   <p style={{fontSize:"13px",fontWeight:"bold"}}>BTCsn / USDT</p>
                   <p style={{fontWeight:"normal"}}>Serial : <b>#{idx.softnote_id}</b></p>
                   <p> Amount : <b>BTC {idx.softnote_amount}</b></p>
                   
                   
                  </div>

                  <div className="right-section">
                  <p className="crypto-volume">{timestamp(idx.softnote_timestamp)}</p>
                    
                   { idx.softnote_method == "deposit" ? (
                     <>
                       <button  style={{backgroundColor:"#5cb85b"}}><span className="fa fa-check"></span> Deposit</button>
                     </>
                   ): idx.softnote_status == false && idx.softnote_method == "withdraw" ? (
                     <>
                       <button style={{backgroundColor:"#f0ad4e"}}><span className="fa fa-history"></span> Withdraw Pending</button>
                     </>
                   ):(
                     <>
                     <button><span className="fa fa-check"></span> Withdraw Done</button>
                     </>
                   )
                    
                   }
                    
                    
                  


                  </div>
                </div>
               
              </div>
                )
                : historyList && historyList.length === 0 ? (
                  <div style={{ justifyContent: 'center', alignItems: 'center', paddingTop: '92px' }}>
                   <center> <p className="fa fa-file" style={{fontSize:"35px",color:"#5cb85b",paddingBottom:"10px"}}></p>
                    <h6  className="no-datatext">History Unavailable</h6></center>
                  </div>
                )
                :(
                  <>
                  {
                    login?(
                      <>
                                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '92px' }}>
                            <Bars
                    height = "40"
                    width = "40"
                    radius = "9"
                    color = '#3375fe'
                    ariaLabel = 'three-dots-loading'  
                    marginLeft='200'   
                    wrapperStyle
                    wrapperClass
                  />
                  </div>
                      </>
                    ):(
                      <>
                      
                                                <div style={{ justifyContent: 'center', alignItems: 'center', paddingTop: '112px'}}>
                                            <center> <p className="fa fa-sign-in" style={{fontSize:"35px",color:"#3375fe",paddingBottom:"10px"}}></p>
                                             <h6  className="no-datatext">Login To See History <br/> <a href="/login" style={{fontSize:"10px",color:"#3375fe",textDecoration:"underline"}}>Login Account?</a></h6>
                                            
                                             </center>
                                           </div>
                      </>
                    )
                  }
                

                  </>
                ) 
                 }



           

              </div>
        </div>
    </TabPanel>
    </Tabs>
                   
 
</div>
  
                       </div>



    </div>
      
         
        
                      
        </>
  );
}

export default SoftnoteTxn;
