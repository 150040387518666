import React from 'react';
import Banner02 from '../components/banner/Banner02';
import Crypto02 from '../components/crypto/Crypto02';
import Services from '../components/services/Services';
import About02 from '../components/about/About02';
import Blog from '../components/blog/Blog';
import Coinlist01 from '../components/coinlist/Coinlist01';
import dataServices from '../assets/fake-data/data-services';
import Join from '../components/join/Join';


function HomeTwo(props) {

    return (
        <div className='home-2'>
            
            <Banner02 />

            <Crypto02 />

            <Services data={dataServices} />

            <Coinlist01/>

            <About02/>
           
            {/* <Work01 data={dataWork} /> */}
              <br/><br/>
            <Blog/>
            <Join />
        </div>
    );
}

export default HomeTwo;