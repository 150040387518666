import React, { useState,useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './orders.scss';
import { Bars } from  'react-loader-spinner'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImageLoader from "../loader/loader";
import Cookies1 from 'js-cookie';


function Orderbook() {
  
   const [buyOrder,setBuyOrder] = useState({}); 
   const [sellOrder,setSellOrder] = useState({}); 
   const [completedOrder,setCompletedOrder] = useState({}); 
   const[loader,setLoader] = useState(false);
   const [login,setLogin] = useState(true);
  
  
 

   useEffect(() => {
    const token = Cookies1.get('LoginToken');
    const investorID = Cookies1.get('investorId');
  
    if (token != null) {
      const socket = new WebSocket(`wss://websocket.cetoex.com:8080/orders?coinName=${investorID}`);
  
      socket.onopen = () => {
        console.log('WebSocket connection established.');
      };
  
      socket.onmessage = (event) => {
        const receivedData = JSON.parse(event.data);
  
        const buyFilter = receivedData.filter(
          (item) =>
            item.distributor_reason.toLowerCase().includes("buy") &&
            item.distributor_status === 0
        );
  
        const sellFilter = receivedData.filter(
          (item) =>
            item.distributor_reason.toLowerCase().includes("sell") &&
            item.distributor_status === 0
        );
  
        const completedFilter = receivedData.filter(
          (item) => item.distributor_status === 1
        );
  
        setBuyOrder(buyFilter);
        setSellOrder(sellFilter);
        setCompletedOrder(completedFilter);
      };
  
      socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
  
      socket.onclose = () => {
        console.log('WebSocket connection closed.');
      };
  
      // Cleanup function
      return () => {
        socket.close();
      };
    } else {
      setLogin(false);
    }
  }, []);
  



   function buyCancel(tranasactionHash)
   {
   
    setLoader(true)
    var data = JSON.stringify({
      "transation_id": tranasactionHash
    });
    
    var config = {
      method: 'post',
      url: 'https://test.cetoex.org/api/buyCancel',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
     if(response.data.status === "Success"){
      toast.success(response.data.message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
     }
     else{

      toast.error(response.data.status, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });

     }
     setLoader(false)
    })
    .catch(function (error) {
      console.log(error);
    });
    
   }

   const sellCancel = (tranasactionHash)=>{

    setLoader(true)
    var data = JSON.stringify({
      "transation_id": tranasactionHash
    });
    
    var config = {
      method: 'post',
      url: 'https://test.cetoex.org/api/sellCancel',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
     if(response.data.status === "Success"){
      toast.success(response.data.message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
     }
     else{

      toast.error(response.data.status, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });

     }
     setLoader(false)
     
    })
    .catch(function (error) {
      console.log(error);
    });
   }


  
   function capitalizeFirstLetter(str) {
    if (str.length === 0) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }



    function scientificToDecimal(num) {
        const str = num.toString().toLowerCase();
        if (str.indexOf('e') === -1) {
          return str;
        }
        const parts = str.split('e');
        const coefficient = parseFloat(parts[0]);
        const exponent = parseInt(parts[1]);
        const decimalPart = coefficient.toFixed(4).replace('.', '');
        const exponentPart = Math.abs(exponent).toString();
        return (
            <>
            0.0<sub>{exponentPart-1}</sub>{decimalPart}
            </>
        )
      }


      function formatNumber(number) {
  
        if(number < 1)
        {
          return number.toFixed(6)
        
        }
        else{
          const suffixes = ['', 'K', 'M', ' Billion', ' Trillion', ' Quadrillion'];
          const magnitude = Math.floor(Math.log10(Math.abs(number)) / 3);
        
          if (magnitude >= suffixes.length) {
            return number.toString(); // Return as-is if the number is too large for available suffixes
          }
        
          const scaledNumber = number / Math.pow(10, magnitude * 3);
          const formattedNumber = scaledNumber.toFixed(2);
        
          return formattedNumber + suffixes[magnitude];
        }

      
      }

const timestamp = (timestamp)=>{

  let timestampString = timestamp.toString();
  let splitDate = timestampString.substring(0, 10);
  let splitTime = timestampString.substring(11, 19);

  const [hours, minutes] = splitTime.split(":").slice(0, 2);

   return `${hours}:${minutes} ${splitDate}`

  }


  const cryptoPrice = (symbol,amount,type,price)=>{
   
    const splitArray = type.split(" ");
    if (splitArray.includes("Buy")) {
      return(
        <>
        <span style={{color:"#5cb85b",fontSize:"9px"}}>{`${symbol} ${formatNumber(amount / price)}`}</span>
        </>
      )
    }
    else{
      return(
        <>
        <span style={{color:"#d33535",fontSize:"9px"}}>{`${symbol} ${formatNumber(amount / price)}`}</span>
        </>
      )
    }

  }


  return (
    <>


<ToastContainer
position="bottom-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="dark"
/>


{
    loader && <ImageLoader/>
}

     <div className="trade_pair" style={{height:"374px",overflow:"hidden"}}><center>
                
                       </center>
                       <div className="flat-tabs">
  <div className="table-main">   
  <Tabs defaultIndex={0} selectedTabClassName="tab-design">
    <TabList  className="my-0"  id="tab-basic">
      <Tab>
        Buy Orders
      </Tab>
      <Tab>
       Sell Orders

      </Tab>
      <Tab>
       Completed

      </Tab>
    </TabList>

    <TabPanel>
    <div>
  <ul className="orders-ul">
  {
                buyOrder && buyOrder.length>0 ?
                buyOrder.map((idx, i) =>
                <div className="container122">
                <div className="crypto-card">
                  <div className="left-section">
                   <p style={{fontSize:"10px",marginLeft:"5px"}}>{capitalizeFirstLetter(idx.distributor_order)} / Buy</p>
                    <img src={idx.coin_logo} alt="Crypto Logo 1" />
                     </div>

                  <div className="center-section" >
                   <p style={{fontSize:"13px",fontWeight:"bold"}}>{idx.distributor_symbol} / USDT</p>
                   <p style={{fontWeight:"normal"}}>Price : <b>{scientificToDecimal(idx.distributor_price)}</b></p>
                   <p> Amount : <b>{cryptoPrice(idx.distributor_symbol,idx.distributor_amount,idx.distributor_reason,idx.distributor_price)}</b></p>
                   
                   
                  </div>

                  <div className="right-section">
                  <p className="crypto-volume">{timestamp(idx.distributor_date)}</p>
                    <button onClick={() =>buyCancel(idx.serial_number)} ><span className="fa fa-times"></span> Cancel</button>
                  </div>
                </div>
               
              </div>
              
              
                ): buyOrder && buyOrder.length === 0 ? (
                  <div style={{ justifyContent: 'center', alignItems: 'center', paddingTop: '112px' }}>
                   <center> <p className="fa fa-book" style={{fontSize:"35px",color:"#3375fe",paddingBottom:"10px"}}></p>
                    <h6 className="no-datatext">No Buy Orders</h6></center>
                  </div>
                ) :(
                  <>
                  {
                    login ?(
                      <>
                       <>
                   <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '72px' }}>
             <Bars
    height = "150"
    width = "40"
    radius = "9"
    color = '#3375fe'
    ariaLabel = 'three-dots-loading'  
    marginLeft='200'   
    wrapperStyle
    wrapperClass
  />
  </div>
                  </>
                      </>
                    ):(
                      <>
                       <div style={{ justifyContent: 'center', alignItems: 'center', paddingTop: '112px'}}>
                   <center> <p className="fa fa-sign-in" style={{fontSize:"35px",color:"#3375fe",paddingBottom:"10px"}}></p>
                    <h6  className="no-datatext">Login To Trade <br/> <a href="/login" style={{fontSize:"10px",color:"#3375fe",textDecoration:"underline"}}>Login Account?</a></h6>
                   
                    </center>
                  </div>
                      </>
                    )
                  }
                  </>
                 
                )
      }
      </ul>
      </div>
 
    </TabPanel>
    <TabPanel>
    <div>
  <ul className="orders-ul">
  {
                sellOrder && sellOrder.length>0 ?
                sellOrder.map((idx, i) =>
                <div className="container122">
                <div className="crypto-card">
                  <div className="left-section">
                   <p style={{fontSize:"10px",marginLeft:"5px"}}>{capitalizeFirstLetter(idx.distributor_order)}/Sell</p>
                    <img src={idx.coin_logo} alt="Crypto Logo 1" />
                     </div>

                  <div className="center-section" >
                   <p style={{fontSize:"13px",fontWeight:"bold"}}>{idx.distributor_symbol} / USDT</p>
                   <p style={{fontWeight:"normal"}}>Price : <b>{scientificToDecimal(idx.distributor_price)}</b></p>
                   <p> Amount : <b>{cryptoPrice(idx.distributor_symbol,idx.distributor_amount,idx.distributor_reason,idx.distributor_price)}</b></p>
                   
                   
                  </div>

                  <div className="right-section">
                  <p className="crypto-volume">{timestamp(idx.distributor_date)}</p>
                    <button onClick={() =>sellCancel(idx.serial_number)} ><span className="fa fa-times"></span> Cancel</button>
                  </div>
                </div>
               
              </div>
              
                )
                : sellOrder && sellOrder.length === 0 ? (
                  <div style={{ justifyContent: 'center', alignItems: 'center', paddingTop: '112px' }}>
                   <center> <p className="fa fa-book" style={{fontSize:"35px",color:"#3375fe",paddingBottom:"10px"}}></p>
                    <h6  className="no-datatext">No Sell Orders</h6></center>
                  </div>
                ) 
                :(
                  <>
                   {
                    login ?(
                      <>
                       <>
                   <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '72px' }}>
             <Bars
    height = "150"
    width = "40"
    radius = "9"
    color = '#3375fe'
    ariaLabel = 'three-dots-loading'  
    marginLeft='200'   
    wrapperStyle
    wrapperClass
  />
  </div>
                  </>
                      </>
                    ):(
                      <>
                       <div style={{ justifyContent: 'center', alignItems: 'center', paddingTop: '112px'}}>
                   <center> <p className="fa fa-sign-in" style={{fontSize:"35px",color:"#3375fe",paddingBottom:"10px"}}></p>
                    <h6  className="no-datatext">Login To Trade <br/> <a href="/login" style={{fontSize:"10px",color:"#3375fe",textDecoration:"underline"}}>Login Account?</a></h6>
                   
                    </center>
                  </div>
                      </>
                    )
                  }
                 </>
                )
      }
      </ul>
      </div>
 
    </TabPanel>
    <TabPanel>
  
  <div>
  <ul className="orders-ul">
  {
                completedOrder && completedOrder.length>0 ?
                completedOrder.map((idx, i) =>
                <div className="container122">
                <div className="crypto-card">
                  <div className="left-section">
                   <p style={{fontSize:"10px",marginLeft:"5px"}}>{capitalizeFirstLetter(idx.distributor_order)}/Buy</p>
                    <img src={idx.coin_logo} alt="Crypto Logo 1" />
                     </div>

                  <div className="center-section" >
                   <p style={{fontSize:"13px",fontWeight:"bold"}}>{idx.distributor_symbol} / USDT</p>
                   <p style={{fontWeight:"normal"}}>Price : <b>{scientificToDecimal(idx.distributor_price)}</b></p>
                   <p> Amount : <b>{cryptoPrice(idx.distributor_symbol,idx.distributor_amount,idx.distributor_reason,idx.distributor_price)}</b></p>
                   
                   
                  </div>

                  <div className="right-section">
                  <p className="crypto-volume">{timestamp(idx.distributor_date)}</p>
                    <button  style={{backgroundColor:"#5cb85b"}}><span className="fa fa-check"></span> Success</button>
                  </div>
                </div>
               
              </div>
                ): completedOrder && completedOrder.length === 0 ? (
                  <div style={{ justifyContent: 'center', alignItems: 'center', paddingTop: '112px' }}>
                   <center> <p className="fa fa-book" style={{fontSize:"35px",color:"#3375fe",paddingBottom:"10px"}}></p>
                    <h6  className="no-datatext">No Success Orders</h6></center>
                  </div>
                ) :(
                  <>
                  {
                    login ?(
                      <>
                       <>
                   <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '72px' }}>
             <Bars
    height = "150"
    width = "40"
    radius = "9"
    color = '#3375fe'
    ariaLabel = 'three-dots-loading'  
    marginLeft='200'   
    wrapperStyle
    wrapperClass
  />
  </div>
                  </>
                      </>
                    ):(
                      <>
                       <div style={{ justifyContent: 'center', alignItems: 'center', paddingTop: '112px'}}>
                   <center> <p className="fa fa-sign-in" style={{fontSize:"35px",color:"#3375fe",paddingBottom:"10px"}}></p>
                    <h6  className="no-datatext">Login To Trade <br/> <a href="/login" style={{fontSize:"10px",color:"#3375fe",textDecoration:"underline"}}>Login Account?</a></h6>
                   
                    </center>
                  </div>
                      </>
                    )
                  }
                 </>
                )
      }
      </ul>
      </div>
 
    </TabPanel>
    </Tabs>
                   
 
</div>
  
                       </div>
                     </div>
    </>
  );
}

export default Orderbook;