import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import MarketChart from './marketChart';
import { Bars } from  'react-loader-spinner'


function MarketPagation(props) {

  const data = props.data;
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentItems = data.slice(firstIndex, lastIndex);
  const index = 0;

  

function scientificToDecimal(num) {
  const str = num.toString().toLowerCase();
  if (str.indexOf('e') === -1) {
    return str;
  }
  const parts = str.split('e');
  const coefficient = parseFloat(parts[0]);
  const exponent = parseInt(parts[1]);
  const decimalPart = coefficient.toFixed(4).replace('.', '');
  const exponentPart = Math.abs(exponent).toString();
  return (
      <>
      0.0<sub>{exponentPart-1}</sub>{decimalPart}
      </>
  )
}


  const priceColor = (price)=>{

      if(price === 0)
      {
          return(
              <>
              <td>0.00%</td>     
              </>
          )
      }

      const numberAsString = price.toString();
     if(numberAsString.substring(0, 1) === "-")
     {
      return(
          <>
          <td className="down">{price}%</td>     
          </>
      )
     }
     else{
      return(
          <>
          <td className="up">{price}%</td>     
          </>
      )
     }
  }


  function formatNumber(number) {
    if(number === 0)
    {
    return "0.00";
    }
    
    const suffixes = ['', 'K', 'M', 'B', 'T','Q'];
      const suffixIndex = Math.floor(Math.log10(number) / 3);
    
      if (suffixIndex === 0) {
        return number.toFixed(2);
      }
    
      const shortNumber = (number / Math.pow(1000, suffixIndex)).toFixed(2);
      return shortNumber + suffixes[suffixIndex];
    }




  return (
    <div className="content-inner">
    

                                                    <table className="table market-table">
        <thead>
                                              <tr>
                                                   
                                                   
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Price</th>
                                                    <th scope="col">24h %</th>
                                                    <th scope="col">Volume (24h)</th>
                                                    <th scope="col">Volume (USDT)</th>
                                                    <th scope="col">Chart</th>
                                                    <th scope="col"></th>
                                                </tr>
        </thead>
        <tbody>
          {
           currentItems && currentItems.length>0 ?
          currentItems.map((idx,index) => (

           <tr key={idx.serial_number}>
          
           <td>
               <Link to="#">
                 <div style={{display:"flex"}}>
               <img src={idx.coin_logo} alt="coinLogo" width="28px" height="28px" style={{borderRadius:"50%",marginTop:"5px",marginRight:"5px"}}/>
               <div style={{marginLeft:"2px"}}> <p>{idx.coin_name}</p>
               <p style={{fontSize:"12px",fontWeight:"normal",color:"gray"}}>{idx.coin_symbol}</p></div>
               </div>
               </Link>
           </td>
           <td className="boild">${scientificToDecimal(idx.coin_price)}</td>
           {priceColor(idx.coin_pricechange)}
           <td className="boild">{idx.coin_symbol+" "+formatNumber(idx.coin_baseVolume)}</td>
           <td className="boild">${formatNumber((idx.coin_baseVolume * idx.coin_price))}</td>
           <td className="boild"><MarketChart  prop1={idx.crpyto_chart_prices} prop2={idx.coin_pricechange} /></td>
           <td><Link to={`../trade/${idx.coin_symbol}USDT`} className="btn"><p>Trade</p></Link></td>
       </tr>
          )):(
            <>
                   
            <td colSpan="7">
        <center>   <div style={{marginLeft:"50%",marginTop:"35px",marginBottom:"35px"}}>
       <Bars
height = "50"
width = "50"
radius = "9"
color = '#3375fe'
ariaLabel = 'three-dots-loading'  
marginLeft='200'   
wrapperStyle
wrapperClass
/>
</div></center>
</td>

        </>
          )}
        </tbody>
      </table>

      {/* Pagination */}
      <div>
        {data.length > itemsPerPage && (
          <Pagination
            totalItems={data.length}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
}



function Pagination({ totalItems, itemsPerPage, currentPage, onPageChange }) {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePreviousPage = () => {
    onPageChange(currentPage - 1);
  };

  const handleNextPage = () => {
    onPageChange(currentPage + 1);
  };

  return (
    <ul className="pagination-button">
      <li>
        <button  className="btn btn-primary" onClick={handlePreviousPage} disabled={currentPage === 1}>
          Back
        </button>
      </li>
      {/* Render the current page number or any other desired information */}
      <li style={{margin:"6px",border:"1px solid #0b6efd",borderRadius:"3px",fontWeight:"bold"}}>
        Page {currentPage}
      </li>
      <li>
        <button  className="btn btn-primary" onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </li>
    </ul>
  );
}
export default MarketPagation;
