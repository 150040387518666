import React , {useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Cookies1 from 'js-cookie';
import 'react-tabs/style/react-tabs.css';
import { useEffect } from 'react';
import Sale01 from '../components/sale/Sale01';
import PageTitle from '../components/pagetitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from "jquery";
import Modal from 'react-modal';
import img2 from '../assets/images/logo/log-footer.png'
import './withdrawal.scss';
import { Bars } from  'react-loader-spinner'
import ImageLoader from "../components/loader/loader";
import { Helmet } from 'react-helmet';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      width:'100%',
      maxWidth:'380px',
      margin:'5px',
      backgroundColor:'#fff',
      transform: 'translate(-50%, -50%)',
    },
  };

function Withdrawal(props) {

    const [WithdrawalList, setWithdrawalList] = useState([]);
    const [mail, setMail] = useState('fetching..');
    const[loader,setLoader] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [withdrawDetail, setWithdrawDetail] = useState({
      address:'',
      amount:'',
      transactionID:''
    });
    const [show, setShow] = useState(1);
    const[withdrawLoad,setWithdrawLoad] = useState(true);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [withdraw,setWithdraw] = useState({
        coin:'',
        minimum:'',
        withdrawCharge:'',
        availableBal:'',
        chain:'',
        networkFeeBase:''
    });

    const navigate = useNavigate();

    useEffect(() => {

      const token = Cookies1.get('LoginToken');
     
      if(token != null)
      {
       fetchMyTable()
      } 
      else{
        navigate('/login');
      }    


        
      }, [])
  
  
      const filteredData = WithdrawalList.filter((item) =>
      item.withdrawaSymbol.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.withdrawName.toLowerCase().includes(searchQuery.toLowerCase())
    );



    const handleSearchChange = (event) => {
      const realVal = event.target.value;
     setSearchQuery(realVal.toUpperCase());
   };


    
  
   async function fetchMyTable() {

    const accountUserId =   Cookies1.get('investorId');
  

      var data = JSON.stringify({
       "investorId": accountUserId
     });
     
     var config = {
       method: 'post',
       url: 'https://test.cetoex.org/api/withdrawal',
       headers: { 
         'Content-Type': 'application/json'
       },
       data : data
     };
     
     axios(config)
     .then(function (response) {
      setWithdrawLoad(false)
        setWithdrawalList(response.data);
     })
     .catch(function (error) {
       console.log(error);
     });
  
    }


    function closeModal() {
        setIsOpen(false);
      }
  

    const makeWithdrawal = async(coinId)=>{
 

      const networkType = document.getElementById(coinId).value;
      const InvestorID =  Cookies1.get('investorId');

      var data = JSON.stringify({
        "investorID": InvestorID,
        "CoinName": coinId,
        "Network": networkType
      });
      
      setLoader(true)
      var config = {
        method: 'post',
        url: 'https://test.cetoex.org/api/getwithdrawal',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
        setWithdraw({coin:coinId,minimum:response.data.minimumWithdraw,withdrawCharge:response.data.networkFee,availableBal:response.data.totalBal,chain:response.data.networkName,networkFeeBase:response.data.networkBase});
        setIsOpen(true);
        setLoader(false)
      })
      .catch(function (error) {
        console.log(error);
      });
    }


    function moveCurser(first,second)
   {
       
    var text = document.getElementById(first).value;
    let text_lenght = text.length;
    if (text_lenght >= 1) {
        
        $(second).focus();
    }
   }


   const withdrawCoin  = (e)=>{

    e.preventDefault();
    const withdrawAddress = document.getElementById('withdrawAddress').value;
    const withdawChain = withdraw.chain;
    const withdrawAmount = document.getElementById('withdraw_amount').value;
    const InvestorID =  Cookies1.get('investorId');
    const InvestorName =  Cookies1.get('investorName');
    const investorMail =  Cookies1.get('investorMail');
    
     

    setLoader(true)
    var data = JSON.stringify({
      "investorID": InvestorID,
      "investorName": InvestorName,
      "investorMail": investorMail,
      "withdrawAddress": withdrawAddress,
      "Network": withdawChain,
      "amount": withdrawAmount,
      "coin": withdraw.coin,
      "networkBase": withdraw.networkFeeBase,
      "networkFees": withdraw.withdrawCharge
    });
    
    var config = {
      method: 'post',
      url: 'https://test.cetoex.org/api/approvewithdrawal',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      if(response.data.status === "success")
      {

        setWithdrawDetail({address:withdrawAddress,amount:withdrawAmount,transactionID:response.data.tranactionID});
          
        setMail(response.data.message)

        toast.success(response.data.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
        setShow(2);
      }
      else{

          toast.error(response.data.status, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
              
      }

      setLoader(false)
     
    })
    .catch(function (error) {
      toast.error(error, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
    });
    


   }


   const verifyOTP = ()=>{

    setLoader(true)

    const otp = document.getElementById('input1').value+document.getElementById('input2').value+document.getElementById('input3').value+document.getElementById('input4').value+document.getElementById('input5').value+document.getElementById('input6').value;

    var data = JSON.stringify({
      "transactionID": withdrawDetail.transactionID,
      "otp": otp
    });
    
    var config = {
      method: 'post',
      url: 'https://test.cetoex.org/api/otpVerfiWithdraw',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      if(response.data.status === "success")
      {

        toast.success("Withdraw Successfully.", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
        setShow(3);

        fetchMyTable()        
      }
      else{

          toast.error(response.data.status, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
              
      }
      setLoader(false)
    })
    .catch(function (error) {
      toast.error(error, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
    });
    


   }


   const resendOTP = ()=>{

     }


     const updateWithdraw = ()=>{
       const withdrawAmount = document.getElementById('withdraw_amount').value;
       if(withdrawAmount === "")
       {
        document.getElementById('mainWithdraw').innerHTML = "0.00";
       }
       else{
        document.getElementById('mainWithdraw').innerHTML = withdrawAmount;
       }
        
     }

  
    const CryptoTab = (title)=>
    {
       if(title === "Withdrawal History")
       {
        navigate('/withdrawal-statement');
       }
    }

    const [dataCryptoTab] = useState([
        {
            id: 1,
            title: 'Crypto Withdrawal',
        },
        {
            id: 2,
            title: 'Withdrawal History',
        },
        
    ])
    return (
        <>

<Helmet>
        <title>Secure Crypto Withdrawals | Fast and Reliable | Cetoex</title>
        <meta name="description" content="Easily initiate crypto withdrawals on Cetoex. Our platform offers secure and swift transactions, ensuring your assets are transferred with the utmost reliability and convenience."></meta>
        <meta name="keywords" content="crypto market, crypto deposit history, cetoex, exchange, top ranking coin, top volume, newly listed, top gainers, top losers, cetoex exchange, spot, trading, crypto"></meta>
        <meta name="author" content="Cetoex"></meta>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
        
        {/* Open Graph (OG) tags for sharing on social media */}
        <meta property="og:title" content="Secure Crypto Withdrawals | Fast and Reliable | Cetoex"></meta>
        <meta property="og:description" content="Easily initiate crypto withdrawals on Cetoex. Our platform offers secure and swift transactions, ensuring your assets are transferred with the utmost reliability and convenience."></meta>
        
        {/* Twitter card tags for sharing on Twitter */}
        <meta name="twitter:card" content="cetoex"></meta>
        <meta name="twitter:title" content="Secure Crypto Withdrawals | Fast and Reliable | Cetoex"></meta>
        <meta name="twitter:description" content="Easily initiate crypto withdrawals on Cetoex. Our platform offers secure and swift transactions, ensuring your assets are transferred with the utmost reliability and convenience."></meta>
        
      </Helmet>
      
        <div>
            <PageTitle heading='Withdrawal Crypto' title='Withdrawal Crypto' />

            <ToastContainer
position="bottom-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="dark"
/>



                                   <Modal
                                            isOpen={modalIsOpen}
                                            onRequestClose={closeModal}
                                            style={customStyles}
                                            contentLabel="Example Modal"
                                        >
                                    
                                    {

                                    show === 1 && (
                                      <>
                                      <span className="fa fa-times" style={{float:"right",fontSize:"20px"}} onClick={closeModal}></span>
                                        <form>
                                        
                                        <p class="withdraw-header"><b>{withdraw.coin} Withdraw</b></p>
                                          
                                        
                                        <div className="form-group">
                                        <label style={{fontSize:"14px",color:"black"}}>Withdraw Address</label>
                                        <input id="withdrawAddress" type="text"  className="form-control"  style={{height:"30px",fontSize:"13px"}} placeholder="Long Press To Paste" />
                                        <span style={{color:"#d9534f",fontSize:"10px",marginTop:"13px"}} id="withdrawerror"></span>
                                       
                                        </div>

                                        <div className="form-group">
                                        <label style={{fontSize:"14px",color:"black",marginTop:"10px"}}>Network</label>
                                        <input id="withdrawNetwork" type="text"  className="form-control"  style={{height:"30px",fontSize:"13px"}} value={withdraw.chain}  readOnly/>
                                        </div>

                                        <div className="form-group">
                                        <label style={{fontSize:"14px",color:"black",marginTop:"10px"}}>Available Balance</label>
                                        <input id="availableBal" type="text"  className="form-control"  style={{height:"30px",fontSize:"13px"}} value={withdraw.availableBal+" "+withdraw.coin} readOnly />
                                        </div>

                                        <div className="form-group">
                                        <label style={{fontSize:"14px",color:"black",marginTop:"10px"}}>Withdraw Amount</label>
                                        <input  type="number"  className="form-control" id="withdraw_amount"  style={{height:"30px",fontSize:"13px"}} onChange={updateWithdraw} />
                                        <span style={{color:"#3574ff",fontSize:"10px",marginTop:"13px"}} id="amounterror">Minimum Withdrawal {withdraw.coin+" "+withdraw.minimum}</span>
                                        </div>
                  
                                        <p style={{marginTop:"15px",color:"black",fontSize:"13px"}}>Withdrawal Tips</p>
                                           <div className="row">
                                               <div className="col-6">
                                                   <p className="withdraw-tips">Approval Time</p>
                                                 </div>
                                                 <div className="col-6">
                                                   <p className="withdraw-tips rightside">Under 4 hours</p>
                                                 </div>
                                                
                                                 <div className="col-6">
                                                   <p className="withdraw-tips">Fee returns account</p>
                                                 </div>
                                                 <div className="col-6">
                                                   <p className="withdraw-tips rightside">Withdrawal Account</p>
                                                 </div>
                                            </div>


                                            <hr/>
                                              <div className="row">
                                                   <div className="col-6">
                                                       <p className="receive-amount">Receive Amount</p>
                                                       <p className="main-amount"><span id="mainWithdraw">0.00</span> {withdraw.coin}</p>
                                                       <p className="receive-amount">Network Fee {withdraw.withdrawCharge+" "+withdraw.networkFeeBase}</p>
                                                   </div>
                                                   <div className="col-6">
                                                       <button  className="btn btn-withdraw" onClick={withdrawCoin}><b>Withdraw {withdraw.coin}</b></button>
                                                   </div>
                                               </div>
                                       

                                        </form>
                                        </>
                                    )}


                                    {

                                    show === 2 && (
                                      <>
                                      <span className="fa fa-times" style={{float:"right",fontSize:"20px"}} onClick={closeModal}></span>
                                     
                                        <form action="javascript: void(0)" className="otp-form" name="otp-form">
                                           <p class="withdraw-header"><b>Verify Payment</b><span style={{fontSize:"14px"}}> OTP</span></p>
                                           <div className="form-group">
                                        <label style={{fontSize:"14px",color:"black"}}>Withdraw Address</label>
                                        <input id="InputPassword" type="text"  className="form-control"  style={{height:"30px",fontSize:"13px"}} value={withdrawDetail.address}  readOnly/>
                                        </div>

                                        <div className="form-group">
                                        <label style={{fontSize:"14px",color:"black",marginTop:"10px"}}>Network</label>
                                        <input id="InputPassword" type="text"  className="form-control"  style={{height:"30px",fontSize:"13px"}} value={withdraw.chain}  readOnly/>
                                        </div>

                                        <div className="form-group">
                                        <label style={{fontSize:"14px",color:"black",marginTop:"10px"}}>Withdraw Amount</label>
                                        <input id="InputPassword" type="text"  className="form-control"  style={{height:"30px",fontSize:"13px"}} value={withdrawDetail.amount+" "+withdraw.coin} readOnly />
                                        </div>
                                        
                                        <hr/>
                                        <div className="title11">
                                        
                                        <p>{mail}</p>
                                        </div>
                                        <div className="otp-input-fields">
                                          <input type="number" id="input1" className="otp__digit otp__field__1 otp_box" onKeyUp={(e)=>moveCurser('input1','#input2')} maxLength="1"/>
                                          <input type="number" id="input2" className="otp__digit otp__field__2 otp_box"  onKeyUp={(e)=>moveCurser('input2','#input3')} maxLength="1"/>
                                          <input type="number" id="input3" className="otp__digit otp__field__3 otp_box"  onKeyUp={(e)=>moveCurser('input3','#input4')} maxLength="1"/>
                                          <input type="number" id="input4" className="otp__digit otp__field__4 otp_box"  onKeyUp={(e)=>moveCurser('input4','#input5')} maxLength="1"/>
                                          <input type="number" id="input5" className="otp__digit otp__field__5 otp_box"  onKeyUp={(e)=>moveCurser('input5','#input6')} maxLength="1"/>
                                          <input type="number" id="input6" className="otp__digit otp__field__6 otp_box" maxLength="1"/>
                                          
                                        </div>
                                      <center>  <p><b>Enter 6 Digit OTP.<a href  style={{color:"#3673ff",fontSize:"14px"}} onClick={resendOTP}>&nbsp;&nbsp;Resend OTP?</a></b></p>
                                        
                                      <br/>
                                        <a type="submit" href className="btn-action" style={{width:"80%"}} onClick={verifyOTP}>Verify OTP</a>
                                        </center>
                                      </form>
                                      </>
                                    )

                                    }


                                
                                    {

                                            show === 3 && (
                                                <form>

                                       
                                                   
                                                     <center> <p className="fa fa-check-circle withdraw-icon"></p></center>
                                                      <p className="withdraw-header1"><b>{withdrawDetail.amount} {withdraw.coin}</b></p>
                                                      <p className="withdraw-text">Withdraw Placed Successful</p>

                                                    <div className="withdraw-main-container">
                                                        <p>Hi <b>{}</b>,</p>
                                                        <p>Your transaction can take time to be successful credited upto 4 hours!</p>
                                                     </div>

                                                     <div className="withdraw-payment">
                                                         <p>Payment Statement</p>
                                                         <p>Amount: {withdrawDetail.amount} {withdraw.coin}</p>
                                                         <p>Network: {withdraw.chain}</p>
                                                         <p>Withdrawal date: 2023/06/26</p>
                                                         <p>Withdrawal Address: {withdrawDetail.address}</p>
                                                         <hr/>

                                                         
                                                    <p>World's Most Trusted Exchange</p>
                                                    </div>

                                                  


                                                    </form>
                                            )
                                    }
                                        </Modal>

            <section className="wallet sell buy-crypto flat-tabs">
                <div className="container">
                    <div className="row">
                    <Tabs>
                            <TabList>

                                {
                                    dataCryptoTab.map(idx => (
                                        <Tab key={idx.id} onClick={()=>CryptoTab(idx.title)}>{idx.title}</Tab>
                                    ))
                                }

                            </TabList>

                            <TabPanel>
                                <div className="content-inner">
                                   

                                 

                                    <div className="coin-list-wallet"  style={{height:"700px",overflowY:"auto"}}>
                                   <div className="row">
                                        <div className="col-lg-6 col-md-12 col-sm-12">
                                        <h6> Coin Withdraw</h6>
                                        <p>Find promising coins and great opportunities!</p><br/>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12">
                                        <div className="field2" id="searchform">
                                            <input type="text"   placeholder="Type symbol, name.." onChange={handleSearchChange} value={searchQuery}/>
                                                <button type="button" id="search">
                                                <span className="fa fa-search"></span> Search
                                                </button>
                                          </div>
                                        </div>
                                     </div><br/>
                                    <table className="table">
                                        <thead>
                                        <tr>
                                           
                                            <th className="left" scope="col">Name</th>
                                            <th scope="col">Blockchain</th>
                                            <th scope="col">Balance</th>
                                            <th scope="col">Withdrawal</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {
                filteredData && filteredData.length>0 ?
                filteredData.map((idx, i) =>
                    
                <tr>
                
                <td className="asset">
                <span className="icon-btc">
                  <img src={idx.WithdrawLogo} style={{width:"29px",borderRadius:"50%"}} alt="coin logo"/>
                    
                    </span>
                <p>
                    <span className="boild">{idx.withdrawaSymbol}</span>
                    <span className="unit">{idx.withdrawName}</span>
                </p>
                </td>
               <td>
                   <select id={idx.withdrawaSymbol}>
                   {idx.BEP? <option value="BEP20">Binance Chain</option>: null }
                   {idx.ERC? <option value="ERC20">Etherum Chain</option>: null }
                   {idx.TRC? <option value="TRC20">Tron Chain</option>: null }
                   {idx.BASE? <option value="BASE">Base Blockchain</option>: null }
                   {idx.MATIC? <option value="MATIC">Polygon Network</option>: null }
                   {idx.SOL? <option value="SOL">Solana Network</option>: null }
                   {idx.TON? <option value="TON">TON Network</option>: null }
                   {idx.OWN? <option value="Mainnet">Mainnet</option>: null }
                     
                   </select>
               </td>
                <td>
                <span className="boild">{idx.withdrawaSymbol+" "+idx.Balance}</span>
                </td>

                <td className="color-success">
                <button to="#" className="btn" style={{backgroundColor:"#3673ff",color:"white",fontSize:"10px"}} onClick={()=>makeWithdrawal(idx.withdrawaSymbol)}>Withdrawal {idx.withdrawaSymbol}</button>
                </td>
            </tr>

                ):(
                  <>
                  {
                    withdrawLoad?(
                      <>
                          <tr>
                      <td colSpan="4">
                                      <center>   <div style={{marginLeft:"45%",marginTop:"10px"}}>
                                    <Bars
                      height = "40"
                      width = "40"
                      radius = "9"
                      color = '#3375fe'
                      ariaLabel = 'three-dots-loading'  
                      marginLeft='200'   
                      wrapperStyle
                      wrapperClass
                    />
                    </div></center>
                    </td>
                    </tr>
                      </>
                    ):(
                      <>
                        <tr>
                        <td colSpan="4">
                    <center>   <div >
                    <div style={{ justifyContent: 'center', alignItems: 'center', paddingTop: '132px' }}>
                 <center> <p className="fa fa-bar-chart" style={{fontSize:"35px",color:"#3375fe",paddingBottom:"10px"}}></p>
                                <h6  className="no-datatext">Asset Not Found</h6></center>
                              </div>
                </div></center>
                </td>
                </tr>
                      </>
                    )
                  }
                
                  </>
              )
                 }
                 


                                            
                                      
                                       
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                            </TabPanel>
                          

                    </Tabs> 

                    </div>
                </div>
            </section>

            <Sale01 />
            {
    loader && <ImageLoader/>
}
        </div>
        </>
    );
}

export default Withdrawal;