import React , {useState} from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss' ;
import axios from 'axios';
import { Bars } from  'react-loader-spinner'
import {Link} from 'react-router-dom';


function Blog() {

  
    const [newsList, setNewsList] = useState([]);

    const [dataBlock] = useState(
        {
            heading: 'Crypto News',
            desc : 'It inform you about up-to-date, breaking crypto news about the latest Bitcoin, Ethereum, Blockchain, NFTs, Metaverse, Burn & Mint and Altcoin trends and happenings.',
        }
    );
    const [isOpen, setOpen] = useState(false);


    
    useEffect(() => {
        fetchMyTable()
      }, [])
  
  
  
   async function fetchMyTable() {
      const accountUserId = localStorage.getItem('userId');
          const response =  await axios.get(`https://test.cetoex.org/api/news-section`);
          console.log(response)
          setNewsList(response.data)
    }
  
  

    return (
        <section className="blog">
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <div className="block-text center">
                    <h3 className="heading">{dataBlock.heading}</h3>
                    <p className="desc">
                        {dataBlock.desc}
                    </p>
                    </div>
                </div>


{
                newsList && newsList.length>0 ?
                newsList.map((idx, i) =>(
                  <div key={idx.id} className="col-md-4">
                        <a href={idx.news_link} target="_blank"> <div className="blog-box">
                        <div className="box-image">
                            <img src={idx.news_img} alt="cetoex_news"  style={{width:"100%",height:"210px"}}/>
                            
                        </div>

                        <div className="box-content" style={{border:"1px solid var(--blogcolor)",padding:"15px",backgroundColor:"var(--blogcolor)",borderRadius:"7px",boxShadow:"0px 4px 8px rgba(0, 0, 0, 0.2)"}}>
                            <p className="category btn-action">Crypto News</p>
                            <p className="title"
                            >{idx.news_title}</p>
                            
                        </div>
                        </div></a>
                    </div>
                )
                    
              
                ):(
                    <>
                   <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '100px' }}>
               <Bars
      height = "40"
      width = "40"
      radius = "9"
      color = '#3375fe'
      ariaLabel = 'three-dots-loading'  
      marginLeft='200'   
      wrapperStyle
      wrapperClass
    />
    </div>
  
                    </>
                  ) 
                 }
                
                </div>
            </div>
            
        </section>
    );
}

export default Blog;