import React , {useState} from 'react';
import { useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Sell from '../Sell/sell';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies1 from 'js-cookie';
import ImageLoader from "../loader/loader";
import img1 from '../../assets/images/softnote/banner1.jpg'
import './style.scss';

import { useMediaQuery } from 'react-responsive';


function Buy1(prop1) {
  
  const [userList, setUserList] = useState({
    balance:0,
    price:0
  });
 


  const[coinPrice,setCoinPrice]= useState('');
  const[loader,setLoader] = useState(false);
  const [login,setLogin] = useState(true);
  const isMobile = useMediaQuery({ maxWidth: 667 });
  const [usdtAmount,setUsdtAmount] = useState(0);

  const [currentPrice,setCurrentPrice] = useState('');
  


  useEffect(() => {
    const token = Cookies1.get('LoginToken');
     
    if(token != null)
    {
      fetchMyTable()
    }
    else{
      setLogin(false)
    }
   
   
  }, [])

  

async function fetchMyTable() {
  

  const accountUserId = Cookies1.get('investorId');
  var data = '';

var config = {
  method: 'get',
  url: `https://test.cetoex.org/api/buyDetails?investorId=${accountUserId}&APIKEY=494093&Coin=BTC`,
  headers: { },
  data : data
};

axios(config)
.then(function (response) {
  setUserList({balance:JSON.stringify(response.data.Balance),price:JSON.stringify(response.data.last_price)});
  setCoinPrice(response.data.last_price);
  setCurrentPrice(response.data.last_price);
})
.catch(function (error) {
  console.log(error);
});

  
     
}






    const buyCoin =  async ()=>{
      const accountUserId = Cookies1.get('investorId');
      const investVal = document.getElementById('coin_amount').value;
      const coin_count = document.getElementById('coin_count').value;
      const orderType = document.getElementById('orderType').value;

      setLoader(true)


      var data = JSON.stringify({
        "InvestorID": accountUserId,
        "SoftnoteAmount": investVal,
        "orderType":orderType,
        "price":currentPrice,
      });
      
      var config = {
        method: 'post',
        url: 'https://test.cetoex.org/api/SNbuy',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
       
        if(response.data.status === "success")
        {

         document.getElementById('coin_amount').value = "";
         document.getElementById('coin_count').value = "";
         setUsdtAmount(0);

         fetchMyTable();

          toast.success(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });


        }
        else{

          toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
        }

        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
      
     

    }




     const updateUSDT = ()=>{
     const coinAmount =  document.getElementById('coin_amount').value;
     document.getElementById('coin_count').value = (coinAmount * currentPrice).toFixed(3)
     setUsdtAmount((coinAmount * currentPrice).toFixed(3));
     
      }

      const updateamount = ()=>{
        const coinAmount =  document.getElementById('coin_count').value;
        setUsdtAmount(coinAmount);
        document.getElementById('coin_amount').value = (coinAmount / currentPrice).toFixed(5)
      }




    
      

  return (
    <>

      
  
{
    loader && <ImageLoader/>
}

     
       
     <div className="row" style={{marginTop:"10px"}}>
       <div className="col-12" >


         
                       <div className="flat-tabs">
                      
  <div className="table-main"  style={{border:"1px solid #eee"}}>   
  <Tabs defaultIndex={0} selectedTabClassName="tab-design2">
    <TabList  className="my-0"  id="tab-basic">
      <Tab>
       Buy BTCsn
     </Tab>
     
   
    </TabList>

    <TabPanel>
  
    <ToastContainer
position="bottom-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="dark"
/>
   <div className="row">
    <div className="col-lg-7 col-md-12">
    <form className="form-buy">
   
      

    <div className="col-12" style={{textAlign:"left",fontWeight:"500",marginBottom:"3px",fontSize:"15px"}}>
     Abvl Balance : {userList.balance} USDT
     </div>


     <div className="form-group"  style={{marginTop:"10px",marginBottom:"13px"}}>
        <select className="form-control form-control-sm" id="orderType" onChange={(e)=>{
               if(e.target.value === "market")
               {
                document.getElementById('current_price').disabled = true;
                setCurrentPrice(coinPrice);

               }
               else{
                document.getElementById('current_price').disabled = false;
               }
        }} >
         <option style={{textAlign:"center"}} value="market">Market</option>
         <option  style={{textAlign:"center"}}  value="limit">Limit</option>
         </select>
                                       
      </div>                                


 
  <div className="input-container">
    <i className="fa fa-minus icon" />
    <input
      className="input-field forminput"
      type="text"
      placeholder="BTCsn Price"
      value={currentPrice}
     
      style={{ fontWeight: "bold" }} id="current_price"


      onChange={(e)=>setCurrentPrice(e.target.value)}  disabled/>


      
    <i className="fa fa-plus icon" />
  </div>


  <div className="input-container">
    <i className="fa fa-minus icon" />
    <input
      className="input-field forminput"
      type="text"
      placeholder="Enter BTCsn Value"
      id="coin_amount"


       onKeyUp={updateUSDT}


      style={{ fontWeight: "bold" }}
    />
    <i className="fa fa-plus icon" />
  </div>
   


  <div className="input-container">
    <i className="fa fa-minus icon" />
    <input
      className="input-field forminput"
      type="text"
      placeholder="Total USDT"
      id="coin_count"
      
      onKeyUp={updateamount}
     
      style={{ fontWeight: "bold" }}
    />  


    <i className="fa fa-plus icon" />
  </div>


  
  <p  className="fs-12">Trading Fee (0.5%) : ${(usdtAmount * 0.5/100).toFixed(3)}</p>
  <p  className="fs-13" style={{color:"#5cb85b"}}>Total Amount : <b>${(parseFloat(usdtAmount) + parseFloat(usdtAmount * 0.5/100)).toFixed(3)}</b></p>
  


<div>


 {
   login ? (
   <>
   <button  type="button"  className="btn12" onClick={buyCoin}>
    BUY SOFTNOTE
  </button>
   </>
   ):(
     <>
     <a  href="/login" type="button" style={{backgroundColor:"transparent",width:"100%",padding:"5px",fontWeight:"bold",border:"1.5px solid #5cb85b",color:"#5cb85b",textAlign:"center"}}>
   Login To Buy <span className="fa fa-sign-in"></span>
  </a>
     </>
   )
   
 }
 </div>
  
</form>
</div>

{isMobile ? (
  <>
  
  </>
):(
  <>
   <div className="col-5" style={{borderLeft:"1px solid #def3ea"}}> 
     <img src={img1} alt=""  style={{width:"100%",height:"330px"}}/>
    </div>
  </>
)}
    
</div>



    </TabPanel>


 
    
    </Tabs>
                   
 </div>
</div>
  
                       </div>

                
                     </div>

                  
    </>
  );
}

export default Buy1;
