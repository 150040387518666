import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import './styles.scss';
import logo from '../../assets/images/logo/logo.png';
import logodark from '../../assets/images/logo/logo-dark.png';
import avt from '../../assets/images/user.jpg';
import DarkMode from './DarkMode';
import Cookies1 from 'js-cookie';
import ReloadableLink from '../../pages/Reload';
import menus from '../../pages/menu';

const Header = () => {
    const [scroll, setScroll] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [token, setToken] = useState({ loginToken: '', loginName: '' });
    const [activeIndex, setActiveIndex] = useState(null);

    useEffect(() => {
        const handleScroll = () => {
            setScroll(window.scrollY > 300);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        const loginToken = Cookies1.get('LoginToken');
        const loginName = Cookies1.get('investorName');
        setToken({ loginToken, loginName });
    }, []);

    const handleMenuActive = () => {
        setMenuActive(!menuActive);
    };

    const handleDropdown = (index) => {
        setActiveIndex(index);
    };

    const loginButton = () => (
        <div className="wallet">
            <ReloadableLink to="/login">Login</ReloadableLink>
        </div>
    );

    const user = ()=>{
        return(
            <>
            
                      <div className="wallet">
                        <ReloadableLink to="/user-profile"> {token.loginName} </ReloadableLink>
                        </div>

                          
                        <Dropdown className='user'>
                                <Dropdown.Toggle >
                                    <img src={avt} alt="cetoex" />  
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                <Dropdown.Item to="/user-profile">
                                    <a className="dropdown-item" href="/user-profile"><i className="bx bx-user font-size-16 align-middle me-1"></i>
                                    <span>Profile</span></a>
                                </Dropdown.Item>
                                <Dropdown.Item href="/wallet">
                                    <a className="dropdown-item" href="/wallet"><i
                                        className="bx bx-wallet font-size-16 align-middle me-1"
                                    ></i>
                                    <span>My Wallet</span></a>
                                </Dropdown.Item>
                                <Dropdown.Item href="/user-profile">
                                    <a className="dropdown-item d-block" href="/user-profile"
                                    ><i
                                        className="bx bx-link font-size-16 align-middle me-1"
                                    ></i>
                                    <span>Referral Code</span></a>
                                </Dropdown.Item>
                                <Dropdown.Item href="/logout">
                                    <a className="dropdown-item text-danger" href="/logout"
                                    ><i
                                        className="bx bx-power-off font-size-16 align-middle me-1 text-danger"
                                    ></i>
                                    <span>Logout</span></a>
                                </Dropdown.Item>
                                
                                </Dropdown.Menu>
                            </Dropdown>

                        
            </>

            
        );
    }

    return (
        <header id="header_main" className={`header ${scroll ? 'is-fixed' : ''}`}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="header__body d-flex justify-content-between">
                            <div className="header__left">
                                <div className="logo">
                                    <a href="/" className="light">
                                        <img src={logo} alt="cetoex" style={{ width: "180px" }} />
                                    </a>
                                    <a href="/" className="dark">
                                        <img src={logodark} alt="cetoex" style={{ width: "180px" }} />
                                    </a>
                                </div>
                                <div className="left__main">
                                    <nav id="main-nav" className={`main-nav ${menuActive ? 'active' : ''}`} style={{ backgroundColor: "var(--navcolor)" }}>
                                        <ul id="menu-primary-menu" className="menu">
                                            {menus.map((data, idx) => (
                                                <li key={idx} onClick={() => handleDropdown(idx)}
                                                    className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === idx ? 'active' : ''}`}>
                                                    <a href={data.links}>{data.name}</a>
                                                    {data.namesub &&
                                                        <ul className="sub-menu" style={{ backgroundColor: "var(--navcolor)" }}>
                                                            {data.namesub.map((submenu) => (
                                                                <li key={submenu.id} className="menu-item">
                                                                    <a href={submenu.links}>{submenu.sub}</a>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    }
                                                </li>
                                            ))}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="header__right">
                                <Dropdown>
                                    <Dropdown.Toggle>
                                        About Us
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item as={Link} to="/faq">
                                            FAQ
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/about-cetoex">
                                            About CetoEX
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/privacy">
                                            Privacy & Policy
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/fee-schedule">
                                            Fee Schedule
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown>
                                    <Dropdown.Toggle>
                                        Crypto News
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="https://news.cetoex.com/top-news/">
                                            Top News
                                        </Dropdown.Item>
                                        <Dropdown.Item href="https://news.cetoex.com/category/cetoex-special/">
                                            CetoEX Special
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <DarkMode />
                                <div className={`mobile-button ${menuActive ? 'active' : ''}`} onClick={handleMenuActive}>
                                    <span></span>
                                </div>
                                {token.loginToken ? user() : loginButton()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
