import React , { useState ,useEffect } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';

import img from '../../assets/images/logo/log-footer.png'
import img1 from '../../assets/images/logo/logo-footer-dark.png'
import ReloadableLink from '../../pages/Reload';


function Footer(props) {

    const [productLink] = useState([
        {
            title: 'Launchpad',
            path: '/launchpad'
        },
        {
            title: 'Airdrop',
            path: '/airdrop'
        },
        {
            title: 'Staking',
            path: '/staking'
        },
        {
            title: 'Voting',
            path: '#'
        },
        {
            title: 'Offical Verification',
            path: '/offical-authenticity'
        },
        {
            title: 'Privacy & Policy',
            path: '/privacy'
        },
    ]);
    const [servicesLink] = useState([
        
        {
            title: 'Markets',
            path: '/market'
        },
        {
            title: 'FAQ',
            path: '/faq'
        },
        {
            title: 'List Your Coin',
            path: '/coin-listing'
        },
        {
            title: 'Fee Schedule',
            path: '/fee-schedule'
        },
        {
            title: 'Referral Program',
            path: '/referral-program'
        },
        {
            title: 'API Documentation',
            path: 'https://docs.cetoex.com'
        },
    ]);


    const [listSocial] = useState([
       
        {
            icon: 'fa-brands fa-twitter',
            path: 'https://twitter.com/Cetoex'
        },
        {
           
            icon: 'fa-solid fa-paper-plane',
            path: 'https://t.me/CetoEXExchange'
        },
        {
            icon: 'fa-brands fa-instagram',
            path: 'https://www.instagram.com/cetoex/'
        },
        {
            icon: 'fa-brands fa-youtube',
            path: 'https://www.youtube.com/@Cetoex'
        },
        {
            icon: 'fa-brands fa-facebook',
            path: 'https://www.facebook.com/cetoex'
        },
        {
            icon: 'fa-brands fa-github',
            path: 'https://github.com/cetoex'
        },
    ])
    

    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
  
    useEffect(() => {
      const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      window.addEventListener("scroll", toggleVisibility);
  
      return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (

        <footer className="footer style-2">
            <div className="container">
                <div className="footer__main">
                <div className="row">
                    <div className="col-xl-4 col-md-6">
                    <div className="info">
                        <a href="/" className="logo light">
                        <img src={img} alt="" width="210px" />
                        </a>
                        <a href="/" className="logo dark" >
                        <img src={img1} alt=""  width="210px" style={{marginLeft:"2px"}}/>
                        </a>
                        <h6>Let's talk! 🤙</h6>
                        <ul className="list">
                        <li><p>Live Chat</p></li>
                        <li><p>support@cetoex.com</p></li>
                        <li>
                            <p>
                            Dubai , United Arab Emirates
                            </p>
                        </li>
                        </ul>
                    </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                    <div className="widget">
                        <div className="widget-link">
                        <h6 className="title">PRODUCTS</h6>
                        <ul>
                            {
                                productLink.map((data,idx) => (
                                    <li key={idx}><a href={data.path}>{data.title}</a></li>
                                ))
                            }
                        </ul>
                        </div>
                        <div className="widget-link s2">
                        <h6 className="title">SERVICES</h6>
                        <ul>
                            {
                                servicesLink.map((data,idx) => (
                                    <li key={idx}><a href={data.path}>{data.title}</a></li>
                                ))
                            }
                        </ul>
                        </div>
                    </div>
                    </div>
                    <div className="col-xl-4 col-md-12">
                    <div className="footer-contact">
                        <h5>Newsletters</h5>
                        <p>
                        Subscribe our newsletter to get crypto updates from Cetoex.
                        </p>
                        <form action="#">
                        <input
                            type="email"
                            placeholder="Enter your email"
                            required=""
                         style={{height:"47px"}}/>
                        <button type="submit" className="btn-action">Submit</button>
                        </form>
                        <ul className="list-social">
                            {
                                listSocial.map((data,idx) => (
                                    <li key={idx}>
                                        <a href={data.path}><span className={data.icon}></span></a>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="footer__bottom">
                <p>
                    ©2023 CetoEX Exchange. All rights reserved.
                   
                </p>
                </div>
            </div>

            {
                isVisible && 
                <Link onClick={scrollToTop}  to='#' id="scroll-top"></Link>
            }
        </footer>
    );
}

export default Footer;