import React , {useState} from 'react';
import { useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Bars } from  'react-loader-spinner';
import Modal from 'react-modal';
import Cookies1 from 'js-cookie';
import img2 from '../../assets/images/logo/log-footer.png'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImageLoader from "../loader/loader";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width:'100%',
    maxWidth:'380px',
    margin:'5px',
   
    transform: 'translate(-50%, -50%)',
  },
};

function Orderbook1(Props) {
  
  const[state,setState] = useState('BTC');
  const [buyList, setBuyList] = useState({});
  const [sellList, setSellList] = useState({});
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [login,setLogin] = useState(true);
  const[loader,setLoader] = useState(false);
  const [rowData, setRowData] = useState({ balance:0, price:0 });
  const [buyData, setBuyData] = useState({ 
    serial:'fetching..', 
    amount:'fetching..', 
    denomination:'fetching..', 
    charges:'fetching..', 
    total:'fetching..', 
  });
 

    useEffect(() => {
       
      const token = Cookies1.get('LoginToken');

   

            if(token === undefined)
              {
                setLogin(false)
              }


      const eventSource = new EventSource('https://sse.cetoex.com/api/stream40');

      // Handle messages received from the EventSource
      eventSource.onmessage = (event) => {
        const receivedData = JSON.parse(event.data);
       
         const buyFilter = receivedData.filter((item) =>
            item.order_type.toLowerCase().includes("buy") && 
            item.softnote_status ==false
         );
 
         const sellFilter = receivedData.filter((item) =>
         item.order_type.toLowerCase().includes("sell")  && 
         item.softnote_status == false
          );
 
        
 
          setBuyList(buyFilter);
          setSellList(sellFilter);
        
  
     };

     return () => {
       eventSource.close(); 
     };

    }, [10000])



    function closeModal() {
      setIsOpen(false);
    }



    async function fetchMyTable() {
  

      const accountUserId = Cookies1.get('investorId');
      var data = '';
    
    var config = {
      method: 'get',
      url: `https://test.cetoex.org/api/buyDetails?investorId=${accountUserId}&APIKEY=494093&Coin=BTC`,
      headers: { },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      setRowData({balance:JSON.stringify(response.data.Balance),price:JSON.stringify(response.data.last_price)});
    
     
    })
    .catch(function (error) {
      console.log(error);
    });
    
      
         
    }






    const openModal = (amount,id,price) =>{

      setLoader(true)

     

      fetchMyTable();

      setBuyData({ 
        serial:id, 
        amount:amount, 
        denomination: (amount * price).toFixed(3), 
        charges: (amount * price * 0.5/100).toFixed(3), 
        total: ((amount * price) + amount * price * 0.5/100).toFixed(3), 
      });
      setLoader(false)

      setIsOpen(true);
    }

    const timestamp = (timestamp)=>{

      let timestampString = timestamp.toString();
      let splitDate = timestampString.substring(0, 10);
      let splitTime = timestampString.substring(11, 19);
    
       return splitTime;
  
      //   let unix_timestamp = new Date(timestamp).valueOf();
       
      //   unix_timestamp = unix_timestamp.toString();
      //   unix_timestamp = unix_timestamp.slice(0, -3);
      //  return unix_timestamp = parseInt(unix_timestamp);
  
      }


      const buysoftnote = (e)=>{
        e.preventDefault();
     
        setLoader(true)

        const accountUserId = Cookies1.get('investorId');

        var data = JSON.stringify({
          "InvestorID": accountUserId,
          "SoftnoteID": buyData.serial
        });
        
        var config = {
          method: 'post',
          url: 'https://test.cetoex.org/api/directbuy',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        
        axios(config)
        .then(function (response) {

          if(response.data.status === "success")
          {
           toast.success(response.data.message, {
             position: "bottom-center",
             autoClose: 5000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
             theme: "dark",
             });
 
             setIsOpen(false);
          }
          else{
           toast.error(response.data.message, {
             position: "bottom-center",
             autoClose: 5000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
             theme: "dark",
             });
          }
 
          setLoader(false)
        })
        .catch(function (error) {
          console.log(error);
        });



      }


    function scientificToDecimal(num) {
      const str = num.toString().toLowerCase();
      if (str.indexOf('e') === -1) {
        return str;
      }
      const parts = str.split('e');
      const coefficient = parseFloat(parts[0]);
      const exponent = parseInt(parts[1]);
      const decimalPart = coefficient.toFixed(4).replace('.', '');
      const exponentPart = Math.abs(exponent).toString();
      return (
          <>
          0.0<sub>{exponentPart-1}</sub>{decimalPart}
          </>
      )
    }
  
 

  return (
    <>


                                      <Modal
                                            isOpen={modalIsOpen}
                                            onRequestClose={closeModal}
                                            style={customStyles}
                                            contentLabel="Example Modal"
                                        >
                                    
                                        <form>
                                         <center> 
                                           <div className="flex"> 
                                           <img src={img2} alt="logo" width="150px" />
                                           <span style={{float:"right",fontSize:"20px"}} className="fa fa-times" onClick={closeModal}></span>
                                         </div>
                                         </center>
                                        
                                           <p style={{color:"black",fontSize:"18px",marginTop:"20px"}}><b>BTCsn Direct Buy</b></p>
                                        
                                       
                                        <p style={{padding:"5px 5px 15px 5px",fontSize:"13px"}}>Here you can buy direct softnote without any hassle.</p>
                                       
                                        
                                        <div className="form-group">
                                        <label for="exampleInputEmail1" style={{fontSize:"13px",color:"black"}}>USDT Balance</label>
                                        <input type="text" className="form-control form-control-sm" id="softnote_serial" disabled placeholder="Fetching.." value={`$${rowData.balance}`}/>
                                        </div>
                                       
                                        <div className="form-group" style={{marginTop:"10px"}}>
                                        <label for="exampleInputEmail1" style={{fontSize:"13px",color:"black"}}>Softnote Serial</label>
                                        <input type="text" className="form-control form-control-sm" id="softnote_secret"  disabled placeholder="Fetching.." value={buyData.serial} />
                                        </div>

                                        <div className="form-group" style={{marginTop:"10px"}}>
                                        <label for="exampleInputEmail1" style={{fontSize:"13px",color:"black"}}>Softnote Amount</label>
                                        <input type="text" className="form-control form-control-sm" id="softnote_secret"  disabled placeholder="Fetching.." value={buyData.amount}/>
                                        </div>

                                        <div className="form-group" style={{marginTop:"10px"}}>
                                        <label for="exampleInputEmail1" style={{fontSize:"13px",color:"black"}}>Denomination</label>
                                        <input type="text" className="form-control form-control-sm" id="softnote_secret"  disabled placeholder="Fetching.." value={`$${buyData.denomination}`}/>
                                        </div>

                                        <div className="form-group" style={{marginTop:"10px"}}>
                                        <p style={{fontSize:"10px"}}>Trading Charges (0.5%) : ${buyData.charges}</p>
                                        <p style={{fontSize:"12px",color:"#5cb85c"}}>Total USDT : ${buyData.total}</p>
                                        </div>


                                          <hr />
                                      
                                      
                                        {
                                          login?(
                                            <>
                                             <center> <button className="btn btn-primary" style={{color:"white"}} onClick={buysoftnote}>Buy Softnote</button></center>
                                            </>
                                          ):(
                                            <>
                                              <center> <button className="btn btn-danger" style={{color:"white"}}>Login For Buy?</button></center>
                                         
                                            </>
                                          )
                                        }

                                     
                                        </form>
                                        </Modal>



                                        <ToastContainer
position="bottom-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="dark"
/>


     
  
{
    loader && <ImageLoader/>
}







     <div className="trade_pair"><center>
                  
                       </center>
                       <div className="flat-tabs">
                       <div className="orderbook">Softnote Orderbook</div>
  <div className="table-main">   
  <Tabs defaultIndex={0} >
    <TabList>
      <Tab>
      <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  fill="none"
  className="css-3kwgah"
  style={{ width: 25 }}
>
  <path d="M4 4h7v7H4V4z" fill="#5cb85b" />
  <path d="M4 13h7v7H4v-7z" fill="#d33535" />
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M13 4h7v4h-7V4zm0 6h7v4h-7v-4zm7 6h-7v4h7v-4z"
    fill="grey"
  />
</svg>


      </Tab>
      <Tab>
      <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  fill="none"
  className="css-3kwgah"
  style={{ width: 25 }}
>
  <path d="M4 4h7v16H4V4z" fill="#5cb85b" />
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M13 4h7v4h-7V4zm0 6h7v4h-7v-4zm7 6h-7v4h7v-4z"
    fill="grey"
  />
</svg>

     
      </Tab>
      <Tab>
           
      <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  fill="none"
  className="css-3kwgah"
  style={{ width: 25 }}
>
  <path d="M4 4h7v16H4V4z" fill="#d33535" />
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M13 4h7v4h-7V4zm0 6h7v4h-7v-4zm7 6h-7v4h7v-4z"
    fill="grey"
  />
</svg>

    

      </Tab>
    </TabList>

    <TabPanel>


    <div className="table-main">   
       
       <table class="table-scroll1" style={{height:"220px",overflow:"hidden"}}>
        <thead>
           <tr>
          
           <th>Bid Time</th>
           <th>Bid Price</th>
           <th>Amount</th>
        
           </tr>
         </thead>
         <tbody class="body-half-screen">
           
         {
                buyList && buyList.length>0 ?
                buyList.map((idx, i) =>
                    
                <tr>
           
             <td style={{color:"var(--tablemain)"}}><p  style={{marginLeft:"5px"}}>{timestamp(idx.buy_timestamp)}</p></td>
           
            <td style={{color:"var(--tablemain)"}}>${idx.coin_price}</td>
            <td className="up">{idx.softnote_amount} BTCsn</td>
           
              </tr>
                )
                : buyList && buyList.length === 0 ? (
                  <div style={{ justifyContent: 'center', alignItems: 'center', paddingTop: '62px' }}>
                   <center> <p className="fa fa-file" style={{fontSize:"35px",color:"#5cb85b",paddingBottom:"10px"}}></p>
                    <h6  className="no-datatext">Data Unavailable</h6></center>
                  </div>
                )
                :(
                  <>
                 <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '72px' }}>
             <Bars
    height = "40"
    width = "40"
    radius = "9"
    color = '#d33535'
    ariaLabel = 'three-dots-loading'  
    marginLeft='200'   
    wrapperStyle
    wrapperClass
  />
  </div>

                  </>
                ) 
                 }
                 

          
         </tbody>
       </table>
        
       </div>




    <div className="table-main">   
       
       <table class="table-scroll1" style={{height:"210px",overflow:"hidden"}}>
        <thead>
           <tr>
          
           <th>Ask Time</th>
           <th>Ask Price</th>
           <th>Ask Amount</th>
           <th>Direct Buy</th>
        
           </tr>
         </thead>
         <tbody class="body-half-screen">
           
         {
                sellList && sellList.length>0 ?
                sellList.map((idx, i) =>
                    
                <tr>
           
           <td style={{color:"var(--tablemain)"}}><p  style={{marginLeft:"5px"}}>{timestamp(idx.buy_timestamp)}</p></td>
           
             <td style={{color:"var(--tablemain)"}}>${idx.coin_price}</td>
            <td className="down">{idx.softnote_amount} BTCsn</td>
            <td><button className="btn btn-success" style={{fontSize:"9px",color:"white",padding:"3px"}} onClick={(e)=>openModal(idx.softnote_amount,idx.softnote_id,idx.coin_price)} >Buy Now </button></td>
           
              </tr>
                ): sellList && sellList.length === 0 ? (
                  <div style={{ justifyContent: 'center', alignItems: 'center', paddingTop: '62px' }}>
                   <center> <p className="fa fa-file" style={{fontSize:"35px",color:"#d33535",paddingBottom:"10px"}}></p>
                    <h6  className="no-datatext">Data Unavailable</h6></center>
                  </div>
                ) :(
                  <>
                 <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '72px' }}>
             <Bars
    height = "40"
    width = "40"
    radius = "9"
    color = '#5cb85b'
    ariaLabel = 'three-dots-loading'  
    marginLeft='200'   
    wrapperStyle
    wrapperClass
  />
  </div>

                  </>
                ) 
                 }
                 

          
         </tbody>
       </table>
        
       </div>


       
    </TabPanel>
    <TabPanel>
    <div className="table-main">   
       
       <table class="table-scroll" style={{height:"430px",overflow:"hidden"}}>
        <thead>
           <tr>
          
           <th>Bid Time</th>
           <th>Bid Price</th>
           <th>Amount</th>
        
           </tr>
         </thead>
         <tbody class="body-half-screen">
           
         {
                buyList && buyList.length>0 ?
                buyList.map((idx, i) =>
                    
                <tr>
           
             <td style={{color:"var(--tablemain)"}}><p  style={{marginLeft:"5px"}}>{timestamp(idx.buy_timestamp)}</p></td>
           
             <td style={{color:"var(--tablemain)"}}>${idx.coin_price}</td>
            <td className="up">{idx.softnote_amount} BTCsn</td>
           
              </tr>
                ): buyList && buyList.length === 0 ? (
                  <div style={{ justifyContent: 'center', alignItems: 'center', paddingTop: '162px' }}>
                   <center> <p className="fa fa-file" style={{fontSize:"35px",color:"#5cb85b",paddingBottom:"10px"}}></p>
                    <h6  className="no-datatext">Data Unavailable</h6></center>
                  </div>
                ):(
                  <>
                 <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '180px' }}>
             <Bars
    height = "40"
    width = "40"
    radius = "9"
    color = '#5cb85b'
    ariaLabel = 'three-dots-loading'  
    marginLeft='200'   
    wrapperStyle
    wrapperClass
  />
  </div>

                  </>
                ) 
                 }
                 

          
         </tbody>
       </table>
        
       </div>

    </TabPanel>
    <TabPanel>
       
       
    <div className="table-main">   
       
       <table class="table-scroll"  style={{height:"430px",overflow:"hidden"}}>
        <thead>
           <tr>
           
           <th>Softnote<sub>(Serial)</sub></th>
           <th>Ask Price</th>
           <th>Ask Amount</th>
          
        
           </tr>
         </thead>
         <tbody class="body-half-screen">
           
         {
                sellList && sellList.length>0 ?
                sellList.map((idx, i) =>
                    
                <tr>
           
             <td style={{color:"var(--tablemain)"}}><p  style={{marginLeft:"5px"}}>#{idx.softnote_id}</p></td>
           
            <td style={{color:"var(--tablemain)"}}>${idx.coin_price}</td>
            <td  className="down">{idx.softnote_amount} BTCsn</td>
           
              </tr>
                ): buyList && buyList.length === 0 ? (
                  <div style={{ justifyContent: 'center', alignItems: 'center', paddingTop: '162px' }}>
                   <center> <p className="fa fa-file" style={{fontSize:"35px",color:"#d33535",paddingBottom:"10px"}}></p>
                    <h6  className="no-datatext">Data Unavailable</h6></center>
                  </div>
                ):(
                  <>
                 <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '180px' }}>
             <Bars
    height = "40"
    width = "40"
    radius = "9"
    color = '#d33535'
    ariaLabel = 'three-dots-loading'  
    marginLeft='200'   
    wrapperStyle
    wrapperClass
  />
  </div>

                  </>
                )  
                 }
                 

          
         </tbody>
       </table>
        
       </div>
    </TabPanel>
    </Tabs>
                   
 
</div>
  
                       </div>
                     </div>
    </>
  );
}

export default Orderbook1;
