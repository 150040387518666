import React, { useEffect, useState } from 'react';
import "./style.scss";

import ContentLoader, { Facebook } from "react-content-loader";

const MyLoader = () => (
    <ContentLoader  height={110} width={500}  foregroundColor="#cae9ea">
      <rect x="2%" y="6" rx="50" ry="50" width="41" height="41" />

      <rect x="15%" y="11" rx="1" ry="4" width="60" height="14" />
      <rect x="15%" y="33" rx="1" ry="3" width="40" height="8" />
      <rect x="15%" y="42" rx="1" ry="3" width="39" height="1.5" />

      <rect x="33%" y="0" rx="1" ry="3" width="1.5" height="56" />


      <rect x="38%" y="11" rx="1" ry="3" width="100" height="17" />
      <rect x="38%" y="33" rx="1" ry="3" width="90" height="8" />

      <rect x="0%" y="55" rx="1" ry="3" width="500" height="1.5" />

      <rect x="5%" y="68" rx="1" ry="3" width="80" height="15" />
      <rect x="6%" y="88" rx="1" ry="3" width="65" height="10" />

      <rect x="25%" y="65" rx="1" ry="3" width="80" height="15" />
      <rect x="26%" y="85" rx="1" ry="3" width="65" height="10" />


      <rect x="45%" y="65" rx="1" ry="3" width="80" height="15" />
      <rect x="46%" y="85" rx="1" ry="3" width="65" height="10" />
     
    
    </ContentLoader>
  );
  
 


export default function ChartHeaderMobile(props1) {

    const [isLoading, setIsLoading] = useState(true);
    const [chartHeader,setChartHeader] = useState({
        logo:'',
        pair:'',
        price:'',
        name:'',
        change:'',
        high:'',
        low:'',
        volume:''
    });

    useEffect(() => {
      
      const socket = new WebSocket(`wss://websocket.cetoex.com:8080/chartHeader?coinName=${props1.data}`);

      socket.onopen = () => {
        console.log('WebSocket connection established.');
      };
  
      socket.onmessage = (event) => {
        const receivedData = JSON.parse(event.data);
        setIsLoading(false);

        document.title = `${receivedData[0]['coin_price']} ${receivedData[0]['coin_symbol']}/USDT | CetoEX Trade`;
        setChartHeader({logo:receivedData[0]['coin_logo'],pair:receivedData[0]['coin_symbol'],price:receivedData[0]['coin_price'],name:receivedData[0]['coin_name'],change:receivedData[0]['coin_pricechange'],high:receivedData[0]['coin_high'],low:receivedData[0]['coin_low'],volume:receivedData[0]['coin_baseVolume']});

      
      };
  
      socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
  
      socket.onclose = () => {
        console.log('WebSocket connection closed.');
      };
  
      // Cleanup function
      return () => {
        socket.close();
      };
  
  }, [props1.data])


   const mainPrice = (price,change)=>
    {
        if(change === 0)
        {
            return(
                <>
                <h6 className="main_price">${scientificToDecimal(price)}<p style={{fontSize:"10px",color:"#999"}}>{chartHeader.name} Price</p></h6>
   
                </>
            )
        }
    
        const numberAsString = change.toString();
       if(numberAsString.substring(0, 1) === "-")
       {
        return(
            <>
              <h6 className="down main_price">${scientificToDecimal(price)}<p style={{fontSize:"10px",color:"#999"}}>{chartHeader.name} Price</p></h6>
   
            </>
        )
       }
       else{
        return(
            <>
             <h6 className="up main_price">${scientificToDecimal(price)}<p style={{fontSize:"10px",color:"#999"}}>{chartHeader.name} Price</p></h6>
    
            </>
        )
       }

    }


    function convertTextToHTML(text) {
        const div = document.createElement('div');
        div.innerHTML = text;
        return div.innerHTML;
      }


      function formatNumber(number) {

        if(number == 0)
        {
        return number;
        }
        else{
        const suffixes = ['', 'K', 'M', ' Billion', ' Trillion', ' Quadrillion'];
        const magnitude = Math.floor(Math.log10(Math.abs(number)) / 3);
      
        if (magnitude >= suffixes.length) {
          return number.toString(); // Return as-is if the number is too large for available suffixes
        }
      
        const scaledNumber = number / Math.pow(10, magnitude * 3);
        const formattedNumber = scaledNumber.toFixed(2);
      
        return formattedNumber + suffixes[magnitude];
      }
      }

    function scientificToDecimal(num) {
        const str = num.toString().toLowerCase();
        if (str.indexOf('e') === -1) {
          return str;
        }
        const parts = str.split('e');
        const coefficient = parseFloat(parts[0]);
        const exponent = parseInt(parts[1]);
        const decimalPart = coefficient.toFixed(4).replace('.', '');
        const exponentPart = Math.abs(exponent).toString();
        return (
            <>
            0.0<sub>{exponentPart-1}</sub>{decimalPart}
            </>
        )
      }
      

    const mainChange = (change)=>{
        if(change === 0)
        {
            return(
                <>
                  <p style={{fontWeight:"400",fontSize:"14px",textDecoration:"underline"}}>{change}%</p>
                </>
            )
        }
    
        const numberAsString = change.toString();
       if(numberAsString.substring(0, 1) === "-")
       {
        return(
            <>
              <p style={{fontWeight:"400",fontSize:"14px",textDecoration:"underline"}}  className="down"><span className="fa fa-arrow-down"  style={{fontSize:"12px"}}></span>{change}%</p>
            </>
        )
       }
       else{
        return(
            <>
              <p style={{fontWeight:"400",fontSize:"14px",textDecoration:"underline"}}  className="up"><span className="fa fa-arrow-up" style={{fontSize:"12px"}}></span> {change}%</p>
            </>
        )
       }
    }


    return(
        <>




<div className="containertype11">

    {
        isLoading ? (
            <>

<div style={{height:"70%",width:"100%"}}>
<MyLoader/>
</div>
 




            </>
        ):(
            <>

            <div className="row" style={{height:"115px",marginLeft:"auto",marginRight:"auto"}} >
                      <div className="col-6"  style={{borderBottom:"1px solid #eeeeee",borderRight:"1px solid #eeeeee"}}>
                      <div className="assets">
                        <img src={chartHeader.logo} alt="coin" style={{width:"35px",height:"35px",margin:"4px",borderRadius:"50%"}}/>
                            <div className="assetbox">
                                <p style={{color:"var(--onsurface)",fontWeight:"bold",fontFamily:"Arial, sans-serif"}}>{chartHeader.pair}</p>
                              {mainChange(chartHeader.change)}
                            </div> 
                        </div>

             
                        </div>
                       

                        <div className="col-6"  style={{borderBottom:"1px solid #eeeeee"}}>
                       {mainPrice(chartHeader.price,chartHeader.change)}
                         </div>

                
                 <div className="col-4">
                     <div className="crypto-data" style={{textAlign:"left"}}> 
                    <p className="banner-up" style={{fontSize:"10px"}}>24H High</p>
                    <p className="banner-up dark" style={{fontSize:"11px"}}>${scientificToDecimal(chartHeader.high)}</p>
                    </div>
                 </div>
                
                <div className="col-4">
                <div  className="crypto-data"  style={{textAlign:"left"}}> 
                <p className="banner-up"  style={{fontSize:"10px"}}>24H Low</p>
                <p className="banner-up dark"  style={{fontSize:"11px"}}>${scientificToDecimal(chartHeader.low)}</p>
                </div>
                </div>

                

                <div className="col-4">
                <div  className="crypto-data"  style={{textAlign:"left"}}> 
                <p className="banner-up"  style={{fontSize:"10px"}}>24H Volume</p>
                <p className="banner-up dark"  style={{fontSize:"11px"}}>${formatNumber(chartHeader.volume * chartHeader.price)}</p>
                </div>
                </div>

         
                </div>


            </>
        )
    }


</div>







        </>
    )

}