
import {React , useEffect} from 'react';
import AOS from 'aos';
import { Route, Routes } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import '../src/assets/font/font-awesome.css'
import routes from './pages';
import Login from './pages/Login';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function App() {

    const location = useLocation();
  const isTradePath = location.pathname.startsWith('/trade');
 

    useEffect(() => {
        AOS.init({
          duration : 2000
        }); 
      }, []);

      
    return (
        <>
       

       <Helmet>

<title>CetoEX Exchange : Buy Sell Bitcoin, Ethereum &amp; 100+ Alt Coins</title>
<meta name="description" content="The fastest and easiest way to buy & sell crypto on CetoEX exchange in Global. Trade Cryptocurrencies in USDT on CetoEX at a Lowest trading fee in Global." data-rh="true"/>



<meta property="og:type" content="website"/>
<meta property="og:url" content="https://cetoex.com"/>
<meta property="og:title" content="CetoEX Exchange : Buy Sell Bitcoin, Ethereum &amp; 100+ Alt Coins"  data-rh="true"/>
<meta property="og:description" content="The fastest and easiest way to buy &amp; sell crypto on CetoEX exchange in Global. Trade Cryptocurrencies in USDT on CetoEX at a Lowest trading fee in Global." data-rh="true"/>
<meta property="og:image" itemprop="image" content="https://cetoex.com/favicon-banner.jpg"/>
<meta property="og:site_name" content="CetoEX Exchange"/>


  {/* Twitter card tags for sharing on Twitter */}
  <meta name="twitter:card" content="cetoex"/>
        <meta name="twitter:title" content="CetoEX Exchange : Buy Sell Bitcoin, Ethereum &amp; 100+ Alt Coins"/>
        <meta name="twitter:description" content="The fastest and easiest way to buy &amp; sell crypto on CetoEX exchange in Global. Trade Cryptocurrencies in USDT on CetoEX at a Lowest trading fee in Global."/>
        <meta name="twitter:image" itemprop="image" content="https://cetoex.com/favicon-banner.jpg"/>

        </Helmet>

            <Header />
           
            <Routes>

                {
                    routes.map((data,idx) => (
                        <Route key={idx} path={data.path} element={data.component}  />
                    ))
                }

                <Route path='*' element={<Login />} />
            </Routes>
            {
      isTradePath?(
          <>
          </>
      ):(
          <>
          <Footer />
          </>
      )
      }
           
           
        </>
    );
}

export default App;
