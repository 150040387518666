import React , {useState} from 'react';
import 'react-modal-video/scss/modal-video.scss' ;
import Sale01 from '../components/sale/Sale01';
import PageTitle from '../components/pagetitle';
import Services02 from '../components/services/Services02';
import dataServices from '../assets/fake-data/data-services';

import { Helmet } from 'react-helmet';


function Coinlisting() {

 

    return (
        <div>

                         
<Helmet>
        <title>Crypto Coin Listing | Explore New Possibilities | Cetoex</title>
        <meta name="description" content="Discover and Trade a Wide Range of Cryptocurrencies on CetoEX Exchange. Explore the Latest Coin Listings and Expand Your Investment Horizons."/>
        <meta name="keywords" content="crypto market, crypto deposit history, cetoex, exchange, top ranking coin, top volume, newly listed, top gainers, top losers, cetoex exchange, spot, trading, crypto"/>
        <meta name="author" content="Cetoex"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        
        {/* Open Graph (OG) tags for sharing on social media */}
        <meta property="og:title" content="Crypto Coin Listing | Explore New Possibilities | Cetoex"/>
        <meta property="og:description" content="Discover and Trade a Wide Range of Cryptocurrencies on CetoEX Exchange. Explore the Latest Coin Listings and Expand Your Investment Horizons."/>
        
        {/* Twitter card tags for sharing on Twitter */}
        <meta name="twitter:card" content="cetoex"/>
        <meta name="twitter:title" content="Crypto Coin Listing | Explore New Possibilities | Cetoex"/>
        <meta name="twitter:description" content="Discover and Trade a Wide Range of Cryptocurrencies on CetoEX Exchange. Explore the Latest Coin Listings and Expand Your Investment Horizons."/>
        <meta name="twitter:image" content="https://pbs.twimg.com/profile_images/1556224061248008192/KQTT5Qk3_400x400.jpg"/>
        
      </Helmet>

            <PageTitle heading='Coin Listing' title='Coin Listing' />

           
            <Services02 data={dataServices} />
            <Sale01 />
            
        </div>
    );
}

export default Coinlisting;