import React,{ useState,useEffect } from "react";
import Sale01 from '../components/sale/Sale01';
import axios from 'axios';
import ImageLoader from "../components/loader/loader";
import { Helmet } from 'react-helmet';

function PartnerAuthenticity(props) {
 
  const[loader,setLoader] = useState(false);
    
  
   const searchOffical = ()=>{

    const username = document.getElementById("username").value;

    setLoader(true)
    var data = JSON.stringify({
        "username": username
      });
      
      var config = {
        method: 'post',
        url: 'https://test.cetoex.org/api/officalVerification',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
        
        if(response.data.errorcode === "X001")
        {
            
                document.getElementById("verify_text").innerHTML = '<div style="padding:12px;border:1px dashed #0380fd;"><p> <span style="color:#0380fd;font-size:18px;font-weight:bold;"> Listing Partner is Verified.</span><br/>Partner Name : '+response.data.partner_name+' <br/>Telegram Username : '+response.data.partner_usernmae+'<br/>Join Date : '+response.data.partner_date+'</p></div>';
        }
        else{
            
                document.getElementById("verify_text").innerHTML = '<div style="padding:12px;border:1px dashed #d9534f;"><p> <span style="color:#d9534f;font-size:18px;font-weight:bold;"> Listing Partner is invalid.</span><br/>Be Aware from fraudasters.</p></div>';
       
        }
        setLoader(false)


      })
      .catch(function (error) {
        console.log(error);
      });
      
   }

   
   

    return (
        <div>

       
           
            {
    loader && <ImageLoader/>
}

            <section className="faq">

                                               
<Helmet>
        <title>Check Offical Authenticity | Cetoex</title>
        <meta name="description" content="Listing agents are not authorized to enter any contracts or accept any payments on behalf of CetoEX."/>
        <meta name="keywords" content="crypto market, crypto deposit history, cetoex, exchange, top ranking coin, top volume, newly listed, top gainers, top losers, cetoex exchange, spot, trading, crypto"/>
        <meta name="author" content="Cetoex"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        
        {/* Open Graph (OG) tags for sharing on social media */}
        <meta property="og:title" content="Check Offical Authenticity | Cetoex"/>
        <meta property="og:description" content="Listing agents are not authorized to enter any contracts or accept any payments on behalf of CetoEX."/>
        
        {/* Twitter card tags for sharing on Twitter */}
        <meta name="twitter:card" content="cetoex"/>
        <meta name="twitter:title" content="Check Offical Authenticity | Cetoex"/>
        <meta name="twitter:description" content="Listing agents are not authorized to enter any contracts or accept any payments on behalf of CetoEX."/>
       
      </Helmet>


            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <div className="block-text center">
                    <h3 className="heading">CetoEX Official Verification</h3>
                    <p className="desc fs-20">Please make sure that you are visiting <span style={{color:"#3773ff"}}>https://www.cetoex.com</span></p>
                    </div>
                </div>
                <div className="col-md-12">
                <div className="field1" id="searchform">
                   <input type="text"   placeholder="Enter Offical Telegram Username" id="username"/>
                    <button type="button" id="search" onClick={searchOffical}>
                     <span className="fa fa-search"></span>     Offical Verification
                     </button>
                </div>

<div className="field1" id="searchform">
<div id="verify_text" style={{margin:"auto"}} ></div>
</div>



<p className="officalAdvise">
To prevent fraud in the name of CetoEX, you can confirm whether the channel is an official channel or not. Including but not limited to Twitter, Facebook, Telegram, Medium, YouTube, Instagram, LinkedIn, Reddit, Phone number and WeChat account.<br/>

Please note that CetoEX works with Listing Partners to identify quality tokens and assets. However, Listing Partners are not employees or representatives of CetoEX, and are not authorized to enter any contracts or accept any payments on behalf of CetoEX.<br/>

This account may be owned and operated by an external party not affiliated with CetoEX. You should only trust our official account ending in cetoex.com to transmit legally binding agreement, information about your assets and wallet addresses, or other confidential information.
    </p>
                </div>
                </div>
            </div>
            </section>

            <Sale01 />
            
        </div>
    );
}

export default PartnerAuthenticity;