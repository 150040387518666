import React , {useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Button from '../button';
import img from '../../assets/images/layout/graphic.png'
import img1 from '../../assets/images/icon/manager.png'
 import img2 from '../../assets/images/icon/Lock.png'
  import img3 from '../../assets/images/icon/smartphone.png'

Services02.propTypes = {
    data: PropTypes.array,
};

function Services02(props) {
    const data =  [
        {
            id: 1,
            icon: img1,
            title: 'Portfolio Manager',
            text: 'Buy And Sell Popular Digital Currencies, Keep Track Of Them In The One Place.'
        },
        {
            id: 2,
            icon: img2,
            title: 'Vault protection',
            text: 'For Added Security, Store Your Funds In A Vault With Time Delayed Withdrawals.'
        },
        {
            id: 3,
            icon: img3,
            title: 'Mobile Apps',
            text: 'Stay On Top Of The Markets With The Cryptolly App For Android Or IOS.'
        },
       
    ]
    


    const [dataBlock] = useState(
        {
            heading: 'List your coin on CetoEX',
            desc : 'Reach a huge crypto audience and be all over the crypto map. Listing your cryptocurrency on our platform takes a few minutes.'
        }
    )
    return (
        <section className="services">
            <div className="container">
                <div className="row">
                <div className="col-xl-6 col-md-12">
                    <div className="services__main">
                       
                                <div  className="services-box">
                                   <Link to="/listing-form">  <div className="icon">
                                        <p className="fa fa-file-text" style={{fontSize:"30px",color:"#d9534f"}}></p>
                                    </div>
                                    <Link to="/listing-form" className="h6 title">Listing Form</Link>
                                    <p style={{color:"#666"}}>
                                    Click Me For Fill Form For Coin Lisitng.
                                    </p></Link>
                                </div> 
                                
                                <div  className="services-box">
                                <Link to="/offical-authenticity">  <div className="icon">
                                        <p className="fa fa-check-circle" style={{fontSize:"30px",color:"#5cb85c"}}></p>
                                    </div>
                                    <Link to="#" className="h6 title">CetoEX Official Verification</Link>
                                    <p style={{color:"#666"}}>
                                     Click Me For Check Authenticity Of CetoEX Official Verification.
                                    </p></Link>
                                </div>

                                
                                <div  className="services-box">
                                    
                                    <a href="https://telegram.me/AbdulRahman_786"><div className="icon">
                                    <p className="fa fa-paper-plane" style={{fontSize:"30px",color:"#0275d8"}}></p>
                                    </div>
                                    <Link to="#" className="h6 title">Telegram Contact</Link>
                                    <p style={{color:"#666"}}>
                                    Click Me For quick contact to listing manager on Telegram.
                                    </p></a>
                                </div>
                          
                    
                    </div>
                </div>
                <div className="col-xl-6 col-md-12">
                    <div className="block-text" data-aos="fade-up" data-aos-duration="1000">
                    <h3 className="heading">{dataBlock.heading}</h3>
                    <p className="desc">
                        {dataBlock.desc}
                    </p>
                    <a type="submit" className="btn-action m-2" href="https://telegram.me/AbdulRahman_786">
                         <span className="fa fa-paper-plane"></span>&nbsp;&nbsp;<b>Telegram Message For Listing</b>
                            </a>
                    <img src={img} alt="Cetoex" />
                    </div>
                    
                </div>
                </div>
            </div>
        </section>
    );
}

export default Services02;